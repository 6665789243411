import ContentAPISdkUtils from "./ContentAPISdkUtils";

export {
    ContentAPISdkUtils
};

export { type IContentAPICatalogBaseInfo } from "./models/CatalogBaseInfo";
export { type IContentAPICatalog, CatalogFactory } from "./models/Catalog";
export { type IContentAPICatalogClassification, CatalogClassificationFactory } from "./models/CatalogClassification";
export { type IContentAPICatalogProperties, CatalogPropertiesFactory } from "./models/CatalogProperties";
export { type IContentAPICatalogVersionBaseInfo } from "./models/CatalogVersionBaseInfo";
export { type IContentAPICatalogVersion, CatalogVersionFactory } from "./models/CatalogVersion";
export { type IContentAPICatalogVersionInfo, CatalogVersionInfoFactory } from "./models/CatalogVersionInfo";
export { type IContentAPIClearanceSpecs, ClearanceSpecsFactory } from "./models/ClearanceSpecs";
export { type IContentAPIClientItem, ClientItemFactory, ClientItemRefFactory } from "./models/ClientItem";
export { type IContentAPIClientItemContext, ClientItemContextFactory } from "./models/ClientItemContext";
export { type IContentAPICompleteCatalogVersion, CompleteCatalogVersionFactory } from "./models/CompleteCatalogVersion";
export { type IContentAPIConfigurationChange, type IInvalidationReason, ConfigurationChangeFactory } from "./models/ConfigurationChange";
export { type IContentAPIConfigurationState, type IContentAPIFeatureState, ConfigurationStateFactory, FeatureStateFactory } from "./models/ConfigurationState";
export { type IContentAPIDescriptions, DescriptionsFactory } from "./models/Descriptions";
export { type IContentAPIDimensionSpecs, DimensionSpecsFactory } from "./models/DimensionSpecs";
export { type IContentAPIDimensions, DimensionsFactory } from "./models/Dimensions";
export { type IContentAPIFeature, FeatureFactory } from "./models/Feature";
export { type IContentAPIFeatureClassification, FeatureClassificationFactory } from "./models/FeatureClassification";
export { type IContentAPIGroupBase } from "./models/GroupBase";
export { type IContentAPIFeatureGroup, FeatureGroupFactory } from "./models/FeatureGroup";
export { type IContentAPIFeatureOption, FeatureOptionFactory } from "./models/FeatureOption";
export { type IContentAPIFeatureOptionCharacteristics, FeatureOptionCharacteristicsFactory } from "./models/FeatureOptionCharacteristics";
export { type IContentAPIFeatureOptionClassification, FeatureOptionClassificationFactory } from "./models/FeatureOptionClassification";
export { type IContentAPIFeatureOptionGroup, FeatureOptionGroupFactory } from "./models/FeatureOptionGroup";
export { type IContentAPIFeatureSelection, FeatureSelectionFactory } from "./models/FeatureSelection";
export { type IContentAPIGeometriesInfo, GeometriesInfoFactory } from "./models/GeometriesInfo";
export { type IContentAPIImageRef, ImageRefFactory } from "./models/ImageRef";
export { type IContentAPIImages, ImagesFactory } from "./models/Images";
export { type IContentAPIInsertionOffsets, InsertionOffsetsFactory } from "./models/InsertionOffsets";
export { type IContentAPIItemBase } from "./models/ItemBase";
export { type IContentAPIItemClassification, ItemClassificationFactory } from "./models/ItemClassification";
export { type IContentAPIItemFeatureOptions, ItemFeatureOptionsFactory } from "./models/ItemFeatureOptions";
export { type IContentAPIItemFeatures, ItemFeaturesFactory } from "./models/ItemFeatures";
export { type IContentAPIItemNames, ItemNamesFactory } from "./models/ItemNames";
export { type IContentAPIItemPriceReportItemCalculationSteps, ItemPriceReportItemCalculationStepsFactory } from "./models/ItemPriceReportItemCalculationSteps";
export { type IContentAPIItemPricingReport, ItemPricingReportFactory } from "./models/ItemPricingReport";
export { type IContentAPIItemUpdateReport, ItemUpdateReportFactory } from "./models/ItemUpdateReport";
export { type IContentAPIItemUpdateReportEntry } from "./models/ItemUpdateReportEntry";
export { type IContentAPIItemReportTax, ItemReportTaxFactory } from "./models/ItemReportTax";
export { type IContentAPIItemVariant, ItemVariantFactory } from "./models/ItemVariant";
export { type IContentAPIItemVariantProposal, ItemVariantProposalFactory } from "./models/ItemVariantProposal";
export { type IContentAPINumericalRange, NumericalRangeFactory } from "./models/NumericalRange";
export { type IContentAPINumericalSpec, NumericalSpecFactory } from "./models/NumericalSpec";
export { type IContentAPIPricingReportItem, PricingReportItemFactory } from "./models/PricingReportItem";
export { type IContentAPIPricingReportItemAvailability, PricingReportItemAvailabilityFactory } from "./models/PricingReportItemAvailability";
export { type IContentAPIPricingReportItemCalculationSteps, PricingReportItemCalculationStepsFactory } from "./models/PricingReportItemCalculationSteps";
export { type IContentAPIPricingReportMessage, PricingReportMessageFactory } from "./models/PricingReportMessage";
export { type IContentAPIPricingTask, PricingTaskFactory } from "./models/PricingTask";
export { type IContentAPIPricingTaskList, PricingTaskListFactory } from "./models/PricingTaskList";
export { type IContentAPIReferenceCodes, ReferenceCodesFactory } from "./models/ReferenceCodes";
export { type IContentAPISearchItem, SearchItemFactory } from "./models/SearchItem";
export { type IContentAPISearchResult, SearchResultFactory } from "./models/SearchResult";
export { type IContentAPISearchItemsResult, SearchItemsResultFactory } from "./models/SearchItemsResult";
export { type IContentAPICommercialItemList, CommercialItemListFactory } from "./models/CommercialItemList";
export { type IContentAPIItemListCalculationStep, ItemListCalculationStepFactory } from "./models/ItemListCalculationSteps";
export { type IContentAPIItemListCalculationStepCustomFunction, ItemListCalculationStepCustomFunction } from "./models/ItemListCalculationStepsCustomFunction";
export { type IContentAPIItemListCatalog, ItemListCatalogFactory } from "./models/ItemListCatalog";
export { type IContentAPIItemListInfo, ItemListInfoFactory } from "./models/ItemListInfo";
export { type IContentAPIItemListItem, ItemListItemFactory } from "./models/ItemListItem";
export { type IContentAPIItemListItemAvailability, ItemListItemAvailabilityFactory } from "./models/ItemListItemAvailability";
export { type IContentAPIItemListItemDimensions, ItemListItemDimensionsFactory } from "./models/ItemListItemDimensions";
export { type IContentAPIItemListMessage, ItemListMessageFactory } from "./models/ItemListMessage";
export { type IContentAPIItemListProviderPrice, ItemListProviderPriceFactory } from "./models/ItemListProviderPrice";
export { type IContentAPIItemListProviderTax, ItemListProviderTaxFactory } from "./models/ItemListProviderTax";
export { type IContentAPICatalogVersionInfoAcquisitionApplication, type IContentAPICatalogVersionInfoAcquisition, type IContentAPICatalogVersionInfoSourceCatalog, type IContentAPICatalogVersionInfoSource } from "./models/CatalogVersionInfo";

const CICAPI_FILENAME: string = "cicapi.web.js";

// noinspection JSUnusedGlobalSymbols
export async function importCiCAPI(url: string): Promise<void> {
    let apiCodeUrl: string = url,
        response: Response,
        code: string;

    if (window.location.toString().includes("debug") && !apiCodeUrl.endsWith("/debug/" + CICAPI_FILENAME)) {
        apiCodeUrl = apiCodeUrl.replace(
            CICAPI_FILENAME,
            "debug/" + CICAPI_FILENAME,
        );
    }

    (response = await fetch(apiCodeUrl));
    (code = await response.text());
    new Function(code)();
}