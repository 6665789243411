export class ItemReportTaxFactory {
    static create(itemReportTax: IItemReportTax): IContentAPIItemReportTax {
        return new ItemReportTax(itemReportTax).serialize();
    }
}

export class ItemReportTax {
    protected _amount: number;
    protected _code: string;
    protected _description?: string;

    constructor(itemReportTax: IItemReportTax) {
        this._amount = itemReportTax.amount;
        this._code = itemReportTax.code;
        this._description = itemReportTax.description;
    }

    serialize(): IContentAPIItemReportTax {
        const itemReportTax: IContentAPIItemReportTax = {
            amount: this._amount,
            code: this._code
        };

        if (this._description !== undefined) {
            itemReportTax.description = this._description;
        }

        return itemReportTax;
    }
}

export interface IContentAPIItemReportTax {
    amount: number;
    code: string;
    description?: string;
}