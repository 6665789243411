
export class CatalogVersionInfoFactory {
    static create(info: V2.ICatalogVersionInfo): IContentAPICatalogVersionInfo {
        return new CatalogVersionInfo(info).serialize();
    }
}

export class CatalogVersionInfo {
    protected _source?: V2.ICatalogVersionInfoSource;
    protected _acquisition?: V2.ICatalogVersionInfoAcquisition;

    constructor(info: V2.ICatalogVersionInfo) {
        this._source = info.source;
        this._acquisition = info.acquisition;
    }

    serialize(): IContentAPICatalogVersionInfo {
        const info: IContentAPICatalogVersionInfo = {};

        if (this._source !== undefined) {
            info.source = this._source;
        }

        if (this._acquisition !== undefined) {
            info.acquisition = this._acquisition;
        }

        return info;
    }
}

export interface IContentAPICatalogVersionInfo {
    source?: IContentAPICatalogVersionInfoSource,
    acquisition?: IContentAPICatalogVersionInfoAcquisition,
}

export interface IContentAPICatalogVersionInfoSource {
    catalog?: IContentAPICatalogVersionInfoSourceCatalog;
}

export interface IContentAPICatalogVersionInfoSourceCatalog {
    name: string,
    format: string,
    version?: string,
    moreInfo?: Record<string, string>
}

export interface IContentAPICatalogVersionInfoAcquisition {
    applications: Record<string, IContentAPICatalogVersionInfoAcquisitionApplication>;
}

export interface IContentAPICatalogVersionInfoAcquisitionApplication {
    version?: string,
    moreInfo?: Record<string, string>,
    options?: Record<string, string>,
}