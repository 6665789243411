import { ClientItemFactory, IConsolidatedCatalog, IConsolidatedCatalogVersion, IConsolidatedItemVariant, IConsolidatedConfigurationChange, IConsolidatedItemVariantProposal, IConsolidatedSearchItemsResult, IConsolidatedSearchItem, IConsolidatedItemPricingReport, IConsolidatedClientItem, IConsolidatedPricingReportItem, IConsolidatedItemFeatures, IConsolidatedSearchResult, IConsolidatedFeatureGroup, IConsolidatedFeature, IConsolidatedFeatureOption, IConsolidatedFeatureOptionGroup, IConsolidatedFeatureSelection, IConsolidatedClientItemContext, IConsolidatedFeatureState, IConsolidatedConfigurationState } from "@cic/ui-toolkit/src/models";
import React from "react";
import { Config, CatalogMenuItem, catalogMenus, IFetchItemsOptions, IFetchCatalogsResults, IFetchItemResults, IFetchGroupResults, ICatalogPlus, ICatalogWithFeatures, DEFAULT_NB_PER_PAGE, IItemPlus, IFeaturePlus, IFeatureGroupPlus, NullableFeature, handlePosition, featureOptionResult, IInputValidationResult, NullableFacetValuesMap, PersistSubItems, CatalogFilterIconsize, sorting, IPricingItem } from './Interfaces';
import config from 'config';
import { strings } from 'strings';
import { menuItem } from 'headerMenus/HeaderMenus';
import { UIToolkitUtils } from "@cic/ui-toolkit";
import { v4 as uuidv4 } from 'uuid';
import PackageJSON from "../../../package.json";

export const groupsMap: Map<string, Array<IItemGroup>> = new Map();
export const companysMap: Map<string, Array<ICatalogPlus>> = new Map();
export const companyCatalogsMap: Map<string, Array<ICatalogPlus>> = new Map();
export const selectedFacetsByFeatures: Map<string, NullableFacetValuesMap> = new Map(); // FacetValuesMap by "itemId__featureId"
export const facetsByFeatures: Map<string, Array<IFacet>> = new Map(); // FacetValuesMap by "itemId__featureId"
export const persistedSubItemsByItem: Map<string, PersistSubItems> = new Map(); // FacetValuesMap by featureIds

export class Helpers {
    //==================================================
    static async fetchItems(APIHandle: ICiCAPI = CiCAPI, pageOffset: number, options: IFetchItemsOptions, allCatalogs: ICatalogPlus[]): Promise<IFetchItemResults> {
        let { nbPerPage, searchQuery, selectedCatalogs, itemList, selectedGroup, totalResults, selectedFacets, selectedDimensions, selectedSorting } = options,
            searchCatalogs: Array<ICatalogPlus> = this.getSearchCatalogsList(selectedCatalogs),
            searchCatalogIds: Array<string> = this.getSearchCatalogsIds(searchCatalogs),
            selectedGroupCode: string | undefined = selectedGroup?.id || undefined,
            hasError: boolean = false,
            errorContent: string = '',
            deactivatedCatalogVersions: Array<ICatalogVersionErrorDetail> | undefined,
            unavailableCatalogVersions: Array<ICatalogVersionErrorDetail> | undefined,
            facets: Array<IFacet> | null = [],
            baseItemTypes: Array<IFacetValue> | null = [],
            dimensionFacets: Array<IFacet> | null = [],
            catalogsFacet: Array<IFacetValue> | null = null,
            companiesFacet: Array<IFacetValue> | null = null,
            updatedCatalogFacet: Array<IFacetValue> | null = null,
            updatedCompaniesFacet: Array<IFacetValue> | null = null,
            updatedFacets: Array<IFacet> | null = null,
            updatedBaseItemTypes: Array<IFacetValue> | null = null,
            updatedDimensionFacets: Array<IFacet> | null = null,
            sorting: Array<ISearchItemSort> | undefined = selectedSorting.field !== undefined && selectedSorting.order !== undefined ? [{ field: selectedSorting.field, order: selectedSorting.order }] : undefined;

        let tempFilters: ISearchClassifications = JSON.parse(JSON.stringify(selectedFacets));


        if (tempFilters.characteristics?.catalogs && Object.entries(tempFilters.characteristics.catalogs).length > 0) {
            tempFilters.catalogs = tempFilters.characteristics.catalogs;
        }
        if (tempFilters.characteristics?.companies && Object.entries(tempFilters.characteristics.companies).length > 0) {
            tempFilters.companies = tempFilters.characteristics.companies;
        }
        if (tempFilters.characteristics && Object.entries(tempFilters.characteristics).length === 0) {
            delete tempFilters.characteristics;
        }
        if (tempFilters.characteristics?.catalogs) {
            delete tempFilters.characteristics.catalogs;
        }
        if (tempFilters.characteristics?.companies) {
            delete tempFilters.characteristics.companies;
        }
        //handle root menu click = (all items from that catalog)
        if (selectedGroupCode && searchCatalogIds.includes(selectedGroupCode)) {
            searchCatalogIds = [selectedGroupCode];
            selectedGroupCode = undefined;
        }

        searchQuery = searchQuery !== "" && searchQuery.split(' ').length === 1 ? searchQuery + '*' : searchQuery;

        try {
            if (searchCatalogIds.length === 0) {
                itemList = [];
                totalResults = 0;

            } else if (searchCatalogIds.length > 0) {

                let apiResponse = await UIToolkitUtils.getSearchResults(searchQuery ?? "", APIHandle, {
                    catalogVersionIds: searchCatalogIds.length > 0 ? searchCatalogIds : [],
                    nbPerPage: nbPerPage,
                    offset: pageOffset,
                    groupRefs: selectedGroupCode ? [selectedGroupCode] : undefined,
                    visibleOnly: true,
                    classificationFilter: tempFilters,
                    dimensionFilter: selectedDimensions,
                    sort: sorting,
                    useFilterConfig: true
                });

                if (apiResponse.code === 'OK') {
                    let newResult: Array<IItemPlus> = [];
                    let facetsCharacteristic: IFacet[] | undefined = undefined;
                    let facetsDimension: IFacet[] | undefined = undefined;

                    if (pageOffset === 0) {
                        totalResults = apiResponse?.result?.total;

                        facetsCharacteristic = apiResponse?.result?.facets?.filter(facet => !/(baseItemTypes|width|height|depth)/.test(facet.code));
                        facetsDimension = apiResponse?.result?.facets?.filter(facet => /(width|height|depth)/.test(facet.code));

                        if (selectedFacets.characteristics && Object.entries(selectedFacets.characteristics!).length > 0) {
                            updatedFacets = facetsCharacteristic || null;
                            facets = null;
                        }

                        if (selectedFacets.baseItemTypes && selectedFacets.baseItemTypes.length > 0) {
                            updatedBaseItemTypes = apiResponse?.result?.facets?.find(facet => facet.code === 'baseItemTypes')?.values || null;
                            baseItemTypes = null;
                        }
                    }

                    (apiResponse?.result?.items as []).forEach((item: IItemPlus) => {
                        if (item) {
                            newResult.push(item);
                        } else {
                            console.error('Fetching failed for this item (undefined); removing it from the result');
                        }
                    });

                    if (config.displayPricing) {  //fetch price for all those items if needed 
                        newResult = await this.getItemsPricingReport(newResult as IItemPlus[], allCatalogs, APIHandle);
                    }

                    itemList = [].concat((pageOffset === 0 ? [] : itemList as []), newResult as []);

                    if (facetsCharacteristic && (!selectedFacets.characteristics || Object.entries(selectedFacets.characteristics!).length === 0)) {
                        facets = facetsCharacteristic; //only update facets if it is a 
                    }

                    if (apiResponse?.result?.facets?.find(facet => facet.code === 'baseItemTypes') && (selectedFacets.baseItemTypes?.length === 0 || !selectedFacets.baseItemTypes)) {
                        baseItemTypes = apiResponse?.result?.facets?.find(facet => facet.code === 'baseItemTypes')?.values || null;
                    }

                    facetsDimension?.forEach((facet: IFacet) => {
                        if (selectedDimensions && Object.entries(selectedDimensions).find((dimension: any) => dimension[0] === facet.code + 's')) {
                            if (!updatedDimensionFacets) updatedDimensionFacets = [];
                            updatedDimensionFacets.push(facet);
                        } else {
                            if (!dimensionFacets) dimensionFacets = [];
                            dimensionFacets.push(facet);
                        }
                    });

                } else {
                    if (apiResponse.code === "CATALOG_VERSION_ERROR" && apiResponse.details) {
                        deactivatedCatalogVersions = apiResponse.details.deactivatedCatalogVersions;
                        unavailableCatalogVersions = apiResponse.details.unavailableCatalogVersions;
                    }

                    console.error('Fetching Items did not succeed: ', apiResponse.code);
                    errorContent = apiResponse.code === 'UNAUTHORIZED' ? strings['session.expired'] : 'Unknown error';
                    totalResults = totalResults || 0;
                    itemList = itemList || [];
                    facets = facets;
                    dimensionFacets = dimensionFacets;
                    baseItemTypes = baseItemTypes;
                    catalogsFacet = catalogsFacet;
                    companiesFacet = companiesFacet;
                    updatedFacets = null;
                    updatedBaseItemTypes = null;
                    updatedCatalogFacet = [];
                    updatedCompaniesFacet = [];
                    hasError = true;
                }
            }

        } catch (error) {
            errorContent = error === 'UNAUTHORIZED' ? strings['session.expired'] : 'Unknown error';
            console.error('Fetching Items not working', error);
            itemList = [];
            facets = facets;
            dimensionFacets = dimensionFacets;
            baseItemTypes = baseItemTypes;
            catalogsFacet = catalogsFacet;
            companiesFacet = companiesFacet;
            updatedCatalogFacet = updatedCatalogFacet;
            updatedCompaniesFacet = updatedCompaniesFacet;
            updatedFacets = null;
            updatedBaseItemTypes = null;
            totalResults = 0;
            hasError = true;
        }

        return {
            itemList: itemList,
            facets: facets,
            updatedFacets: updatedFacets,
            dimensionFacets: dimensionFacets,
            updatedDimensionFacets: updatedDimensionFacets,
            baseItemTypes: baseItemTypes,
            catalogsFacet: catalogsFacet,
            companiesFacet: companiesFacet,
            updatedCatalogFacet: updatedCatalogFacet,
            updatedCompaniesFacet: updatedCompaniesFacet,
            updatedBaseItemTypes: updatedBaseItemTypes,
            totalResults: totalResults,
            hasError: hasError,
            errorContent: errorContent,
            deactivatedCatalogVersions: deactivatedCatalogVersions,
            unavailableCatalogVersions: unavailableCatalogVersions
        } as IFetchItemResults;
    }

    //==================================================
    static async fetchCatalogs(APIHandle: ICiCAPI = CiCAPI, hiddenCatalogs: Array<string>, previousSelectedCatalogs: Array<string>, force: boolean = false): Promise<IFetchCatalogsResults> {
        let catalogs: Array<ICatalogPlus> = [],
            hasError: boolean = false,
            errorContent: string = '';

        try {
            let catalogResponse: Array<IConsolidatedCatalog> = await UIToolkitUtils.getCatalogs(APIHandle, { force });

            catalogResponse!.forEach((rootCatalog: IConsolidatedCatalog) => {
                let {
                    code,
                    name,
                    companyRefCode,
                    measurementSystem,
                    localization,
                    accessLevel,
                    accessStatus,
                    description,
                    image,
                    altImages,
                    classification,
                    externalCodes,
                    companyDisplayName,
                    pricing,
                } = rootCatalog;

                rootCatalog.catalogVersions?.map((catalogVersion: IConsolidatedCatalogVersion) => {
                    let { id, version, status, createdDate, updatedDate } = catalogVersion,
                        completeCatalog: ICatalogPlus = {
                            id,
                            code,
                            name,
                            status,
                            companyRefCode,
                            measurementSystem,
                            localization,
                            accessLevel,
                            accessStatus,
                            createdDate,
                            updatedDate,
                            description,
                            image,
                            altImages,
                            classification,
                            externalCodes,
                            version,
                            companyDisplayName,
                            pricing,
                            rootId: rootCatalog.id,
                            catalogVersions: [],
                            isSelected: this.isPreviouslySelected(id, rootCatalog.id, code, previousSelectedCatalogs, hiddenCatalogs, rootCatalog.catalogVersions),
                            isHidden: this.isCatalogHidden(code, hiddenCatalogs),
                            isVisible: true
                        };

                    catalogs.push(completeCatalog);
                })
            });

            //return only activated catalogs
            //return only non Material Catalogs
            catalogs = catalogs.filter((catalog: ICatalogPlus) => {
                return catalog.status !== 'Deactivated' &&
                    (config.showMaterialCatalog || (!config.showMaterialCatalog && catalog.classification?.mainContentType !== 'Materials')) &&
                    (!config.showOnlyActivated || (config.showOnlyActivated && catalog.status === 'Activated'));
            });

            catalogMenus.forEach((catalogMenu: CatalogMenuItem) => {
                if (catalogMenu.path !== 'all') {
                    //check for each menu if it should be visible or not.
                    const displayCatalogType: boolean = catalogs.some((catalog: ICatalogPlus) => {
                        return catalog.classification?.mainCategories?.find((mainCat: string) => catalogMenu.mainCategories.includes(mainCat));
                    });
                    catalogMenu.visible = displayCatalogType;
                }
            });

        } catch (error) {
            catalogs = [];
            hasError = true;
            errorContent = error === 'UNAUTHORIZED' ? strings['session.expired'] : error === 'NOT_FOUND' ? strings['session.network'] : 'Unknown error... ';

            console.error('fetchCatalogs did not all succeed', error);
        }

        return {
            catalogs: catalogs,
            hasError: hasError,
            errorContent: errorContent
        } as IFetchCatalogsResults
    }

    //==================================================
    static async fetchCatalogsWithRestricted(APIHandle: ICiCAPI = CiCAPI, force: boolean = false, token: string, hiddenCatalogs: Array<string>): Promise<Array<ICatalogPlus>> {
        const CATALOG_UNAVAILABLE: string = 'unavailable',
            CATALOG_RESTRICTED: string = 'restricted';

        let catalogResponse: Array<IConsolidatedCatalog>,
            rootCatalogs: Array<ICatalogPlus> = [];

        try {
            if (token.trim()) {
                await CiCAPI.setExecutionContext({ "userToken": token });
            }

            catalogResponse = await UIToolkitUtils.getCatalogs(APIHandle, { force });

            catalogResponse.forEach((catalog: IConsolidatedCatalog) => {
                if (catalog.accessStatus?.toLocaleLowerCase() !== CATALOG_UNAVAILABLE &&
                    (catalog.accessLevel?.toLocaleLowerCase() === CATALOG_RESTRICTED || catalog.catalogVersions?.length)) {
                    const rootCatalog: ICatalogPlus = catalog as ICatalogPlus;

                    rootCatalog.isSelected = false,
                        rootCatalog.isHidden = this.isCatalogHidden(catalog.code, hiddenCatalogs),
                        rootCatalog.isVisible = true
                    if (config.showMaterialCatalog || (!config.showMaterialCatalog && rootCatalog.classification?.mainContentType !== 'Materials')) {
                        rootCatalogs.push(rootCatalog);
                    }
                }
            });
            rootCatalogs.sort((a: ICatalogPlus, b: ICatalogPlus) => a.name.trim().localeCompare(b.name.trim()));
        } catch (error) {
            rootCatalogs = [];
            console.error('fetchCatalogs did not all succeed', error);
        }

        return rootCatalogs;
    }

    //==================================================
    static isPreviouslySelected(catalogId: string, catalogRootId: string, catalogCode: string, previousCatalogsIdAndRoot: Array<string>, hiddenCatalogs: Array<string>, catalogVersions: Array<IConsolidatedCatalogVersion> = []): boolean {
        if (this.isCatalogHidden(catalogCode, hiddenCatalogs)) { //was it recently hidden ?
            //console.log('[+] CB - UnSelecting hidden catalog: ' + catalogCode + ' ' + catalogRootId);
            return false;

        } else if (previousCatalogsIdAndRoot && previousCatalogsIdAndRoot.includes(catalogId + '||' + catalogRootId)) {
            return true;

        }

        //check if a previous version was selected and does not exist anymore... if so select the latest one
        const idAndRoots: string[] | undefined = previousCatalogsIdAndRoot.filter((idAndRoot: string) => idAndRoot.indexOf('||' + catalogRootId) > -1);

        if (idAndRoots && idAndRoots.length > 0) { // a version of this rootId was selected before
            let activateNewerVersion: boolean = false;

            idAndRoots.forEach((idAndRoot: string) => {
                const selectedVersionId: string = idAndRoot.split('||')[0];
                const versionExists: boolean = catalogVersions.some((version: IConsolidatedCatalogVersion) => version.id === selectedVersionId);

                if (!activateNewerVersion && !versionExists) {
                    const bestActivatedReplacement: IConsolidatedCatalogVersion | undefined = catalogVersions.filter((version: IConsolidatedCatalogVersion) => version.status === 'Activated')[0];

                    if (bestActivatedReplacement && bestActivatedReplacement.id === catalogId) {
                        activateNewerVersion = true;
                        //console.log('[+] CB - AutoSelecting newer version of: ' + catalogId + ' ' + catalogRootId + ' - previous was: ' + idAndRoot);
                    }
                }
            });
            return activateNewerVersion;

        } else {
            return false;
        }
    }

    //==================================================
    static isCatalogHidden(catalogCode: string, hiddenCatalogs: Array<string>): boolean {

        if (hiddenCatalogs.length && hiddenCatalogs.includes(catalogCode)) {
            //console.log('[+] CB - Hidding hidden catalog: ' + catalogCode );
            return true;
        } else {
            return false;
        }
    }

    //==================================================
    static async fetchGroups(APIHandle: ICiCAPI = CiCAPI, catalogs: Array<ICatalogPlus>): Promise<IFetchGroupResults> {
        const promises: Array<Promise<IItemGroup[]>> = [],
            searchCatalogs: Array<ICatalogPlus> = this.getSearchCatalogsList(catalogs),
            searchCatalogIds: Array<string> = this.getSearchCatalogsIds(searchCatalogs),
            missingGroupCatalogIds: Array<string> = [];

        let groupList: Array<IItemGroup> = [];

        try {
            //load missing catalog Menu
            searchCatalogIds.forEach(async (catId: string) => {
                if (!groupsMap.has(catId)) {
                    missingGroupCatalogIds.push(catId);
                    promises.push(UIToolkitUtils.getCatalogVersionItemGroups(catId, APIHandle, { visibleOnly: true }));
                }
            });

            const getGroupResults: Array<IItemGroup[]> = await Promise.all(promises);

            getGroupResults.forEach((groups: IItemGroup[], index: number) => {
                const rootMenu: Array<IItemGroup> = [{
                    groups,
                    id: missingGroupCatalogIds[index],
                    name: this.getCatalogName(missingGroupCatalogIds[index], catalogs),
                    sequenceOrder: undefined,
                    visible: true,
                }]

                groupsMap.set(missingGroupCatalogIds[index], rootMenu);
            });

            // now we have every group needed 
            searchCatalogIds.forEach((catId: string) => {
                const catGroups: Array<IItemGroup> = groupsMap.get(catId) || [];

                groupList = groupList.concat(catGroups);
            });
        } catch (error) {
            console.error('fetchGroups did not all succeed', error);
        }

        return {
            groupList: groupList
        } as IFetchGroupResults;
    }

    //============================================================================================
    static async fetchItemVariant(APIHandle: ICiCAPI = CiCAPI, itemId: string, configurationState?: IConsolidatedConfigurationState, options?: IOptionsGetItemVariant): Promise<IConsolidatedItemVariant | null> {
        let itemVariant: IConsolidatedItemVariant | null = null;

        try {
            let itemVariantResponse: IAPIResponseWithDetails<IConsolidatedItemVariant, Array<IConsolidatedConfigurationChange>> = await UIToolkitUtils.getItemVariantById(itemId, configurationState, APIHandle, { includeSubItems: true });

            if (itemVariantResponse.code === 'OK' && itemVariantResponse.result) {
                itemVariant = itemVariantResponse.result
            }

        } catch (error) {
            console.error('fetchItemVariant did not succeed', error);
        }

        return itemVariant;
    }

    //============================================================================================
    static async fetchItemVariantProposals(APIHandle: ICiCAPI = CiCAPI, item: IConsolidatedClientItem, options: IOptionsGetItemVariantProposals): Promise<IConsolidatedItemVariantProposal[]> {
        let itemVariantProposals: IConsolidatedItemVariantProposal[] = [];

        try {
            itemVariantProposals = await UIToolkitUtils.getItemVariantProposals(item, APIHandle, options);
        } catch (error) {
            console.error('fetchItemVariant did not succeed', error);
        }

        return itemVariantProposals;
    }

    //============================================================================================
    static getFavoritesItemsCode(options: IFetchItemsOptions): Array<string> {
        let { nbPerPage, searchQuery, selectedCatalogs, itemList, selectedGroup, totalResults } = options,
            searchCatalogs: Array<ICatalogPlus> = this.getSearchCatalogsList(selectedCatalogs),
            searchCatalogIds: Array<string> = this.getSearchCatalogsIds(searchCatalogs),
            searchCatalogsRootId: Array<string> = this.getSearchCatalogsRootId(searchCatalogs),
            selectedGroupCode: string | undefined = selectedGroup?.id || undefined,
            allFavoritesString: string | null,
            allCatalogFavorites: Array<string> = [], //using generic catalog.rootId
            allCatalogVersionFavorites: Array<string> = []; //using catalog.id

        searchCatalogsRootId.forEach((catalogRootId: string) => {
            allFavoritesString = localStorage.getItem(catalogRootId);
            if (allFavoritesString) {
                allCatalogFavorites = allCatalogFavorites.concat(JSON.parse(allFavoritesString));
            }
        });

        //handle root menu click = (all items from that catalog)
        if (selectedGroupCode && searchCatalogIds.includes(selectedGroupCode)) {
            let targetCatalog: ICatalogPlus | undefined = searchCatalogs.find((catalog: ICatalogPlus) => catalog.id === selectedGroupCode);

            if (targetCatalog) {
                searchCatalogsRootId = [targetCatalog.rootId];
                selectedGroupCode = undefined;
            }
        }

        if (selectedGroupCode) { // filter Item if any Group selected 
            allCatalogFavorites = allCatalogFavorites.filter((favorite: string) => favorite.indexOf(`|${selectedGroupCode}|`) > -1);
        }

        allCatalogFavorites.map((favorite: string) => {  //build itemId for each version Id related
            let itemId: string = favorite.split('||')[0],
                catalogId = itemId.split('.')[0];

            searchCatalogs.forEach((catalog: ICatalogPlus) => {
                if (catalog.rootId === catalogId && searchCatalogsRootId.includes(catalogId)) {
                    let itemVersionId = itemId.replace(`${catalogId}.`, `${catalog.id}.`);

                    allCatalogVersionFavorites.push(itemVersionId);
                }
            });
        });

        return allCatalogVersionFavorites;
    }

    //============================================================================================
    static async fetchFavoritesItems(APIHandle: ICiCAPI = CiCAPI, allCatalogVersionFavorites: Array<string>, pageOffset: number, options: IFetchItemsOptions, allCatalogs: Array<ICatalogPlus>): Promise<IFetchItemResults> {
        let { nbPerPage, searchQuery, selectedCatalogs, itemList, selectedGroup, totalResults } = options,
            validFavoriteItems: Array<string> = allCatalogVersionFavorites.slice(pageOffset, pageOffset + nbPerPage),
            hasError: boolean = false,
            errorContent: string = '';

        if (pageOffset > 0) {
            // removed Xs one from the start of the list
            allCatalogVersionFavorites = allCatalogVersionFavorites.splice(0, pageOffset);
        }
        if (!itemList) {
            itemList = [];
        }

        try { //Fetch each one of those items 
            let promises: Array<Promise<IAPIResponseWithDetails<IConsolidatedSearchItemsResult, ICatalogVersionErrorDetails>>> =
                validFavoriteItems.map(async (favorite: string) => {
                    let catalogId = favorite.split('.')[0],
                        itemCode = favorite.split('.')[1];

                    return UIToolkitUtils.getSearchResults('', APIHandle, { catalogVersionIds: [catalogId], codes: [itemCode], visibleOnly: true });

                });
            let apiResponse: Array<IAPIResponseWithDetails<IConsolidatedSearchItemsResult, ICatalogVersionErrorDetails>> = await Promise.all(promises);

            apiResponse.forEach((itemResult: IAPIResponseWithDetails<IConsolidatedSearchItemsResult, ICatalogVersionErrorDetails>, index: number) => {
                if (itemResult.code === 'OK' && itemResult.result?.items && itemResult.result.items.length === 1) {
                    itemList = [].concat(itemList as [], itemResult.result?.items as []);
                } else {
                    //favorite is not valid anymore?  Remove it then !  
                    let favoriteIdToRemove = validFavoriteItems[index],
                        catalogId: string | undefined = favoriteIdToRemove ? favoriteIdToRemove?.split('.')[0] : undefined,
                        itemCode: string | undefined = favoriteIdToRemove ? favoriteIdToRemove?.split('.')[1] : undefined,
                        rootCatalogId: string | undefined = allCatalogs.find((catalog: ICatalogPlus) => catalog.id === catalogId)?.rootId;

                    if (rootCatalogId && itemCode) {
                        this.removeFromFavorite(rootCatalogId, itemCode);
                    }
                }
            });

            //filter again with searchQuery if any... 
            if (searchQuery) {
                itemList = itemList.filter((item: IItemPlus) => {
                    return (item.names.main.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1 ||
                        (item.descriptions?.short || '').toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1 ||
                        (item.refCodes?.sku || '').toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1)
                });
            }
            //itemList.sort();

            return {
                itemList: itemList,
                facets: [],
                updatedFacets: null,
                dimensionFacets: [],
                updatedDimensionFacets: null,
                baseItemTypes: [],
                updatedBaseItemTypes: null,
                catalogsFacet: [],
                companiesFacet: [],
                updatedCatalogFacet: [],
                updatedCompaniesFacet: [],
                totalResults: itemList.length,
                hasError: hasError,
                errorContent: errorContent,
                deactivatedCatalogVersions: undefined,
                unavailableCatalogVersions: undefined
            } as IFetchItemResults;

        } catch (error) {
            console.error('fetchFavoritesItems did not succeed', error);
            errorContent = error === 'UNAUTHORIZED' ? strings['session.expired'] : 'Unknown error';

            return {
                itemList: [],
                totalResults: 0,
                hasError: true,
                facets: [],
                updatedFacets: null,
                baseItemTypes: [],
                updatedBaseItemTypes: null,
                catalogsFacet: [],
                companiesFacet: [],
                updatedCatalogFacet: [],
                updatedCompaniesFacet: [],
                dimensionFacets: [],
                updatedDimensionFacets: null,
                errorContent: errorContent,
                deactivatedCatalogVersions: undefined,
                unavailableCatalogVersions: undefined
            } as IFetchItemResults;
        }
    }

    //============================================================================================
    static async fetchOneItem(APIHandle: ICiCAPI = CiCAPI, catalogId: string, itemCode: string): Promise<IConsolidatedSearchItem | undefined> {
        let item: IConsolidatedSearchItem;

        try {
            let itemResult = await UIToolkitUtils.getSearchResults('', APIHandle, { catalogVersionIds: [catalogId], codes: [itemCode], visibleOnly: true });

            if (itemResult.code === 'OK' && itemResult.result?.items && itemResult.result.items.length === 1) {
                item = itemResult.result?.items[0];
                return item;
            }

        } catch (error) {
            console.error('fetchFavoritesItems did not succeed', error);

            return undefined;
        }
    }
    //============================================================================================
    static async getItemPricing(APIHandle: ICiCAPI = CiCAPI, item: IItemPlus): Promise<IConsolidatedItemPricingReport | null> {

        let response: IAPIResponseWithDetails<IConsolidatedItemPricingReport, IConsolidatedItemPricingReport>;
        if (item.selectedProposal?.configurationState) {
            const itemVariant: IConsolidatedItemVariant | null = await this.fetchItemVariant(APIHandle, item.id, item.selectedProposal?.configurationState);

            if (itemVariant) {
                const clientItem: IConsolidatedClientItem = ClientItemFactory.fromItemVariant(itemVariant);

                response = await UIToolkitUtils.getItemPricingReportV1([clientItem], APIHandle, {
                    totalOnly: true,
                    includeConfigurationState: false,
                    includeCalculationSteps: false,
                    consolidateSimilarItems: false,
                    groupByHost: false
                });
                if (response.success && (response.result?.totalPrice !== undefined && response.result?.totalPrice > 0)) {
                    const pricinfReport = response.result;
                    return pricinfReport;
                } else if (response.code === "PRICING_ERROR") {
                    return null;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else if (item) {
            const pricingItem = { 'itemId': item.id, instanceId: 'tertertert' };
            response = await UIToolkitUtils.getItemPricingReportV1([pricingItem], APIHandle, {
                totalOnly: true,
                includeConfigurationState: false,
                includeCalculationSteps: false,
                consolidateSimilarItems: false,
                groupByHost: false
            });

            if (response.success && (response.result?.totalPrice !== undefined && response.result?.totalPrice > 0)) {
                const pricinfReport = response.result;
                return pricinfReport;
            } else if (response.code === "PRICING_ERROR") {
                return null;
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    //============================================================================================
    static async getItemsPricingReport(items: Array<IItemPlus>, allCatalogs: ICatalogPlus[], APIHandle: ICiCAPI): Promise<IItemPlus[]> {
        const itemsToPrice: IPricingItem[] = [];

        items.forEach((item: IItemPlus) => {
            const catalog: ICatalogPlus | undefined = this.getCatalogInstance(item.id, allCatalogs);

            if (catalog?.pricing?.showPricing) {
                let pricingItem = {
                    itemId: item.id,
                    instanceId: uuidv4()
                }
                itemsToPrice.push(pricingItem);
            }
        });

        if (itemsToPrice.length > 0) {
            const response: IAPIResponseWithDetails<IConsolidatedItemPricingReport, IConsolidatedItemPricingReport> = await UIToolkitUtils.getItemPricingReportV1(itemsToPrice, APIHandle, {
                includeConfigurationState: false,
                consolidateSimilarItems: false,
                groupByHost: true
            });

            if (response.success && (response.result?.totalPrice !== undefined && response.result?.totalPrice > 0)) {
                const pricingReport: IConsolidatedItemPricingReport = response.result;
                const updatedItems: IItemPlus[] = this.managePricingReport(items, itemsToPrice, pricingReport);

                return updatedItems; //items with price information

            } else if (response.code === "PRICING_ERROR") {
                console.error('[CB] - Pricing Error: ', response.details);
                return items;
            } else {
                return items;
            }
        } else {
            return items;
        }
    };


    //============================================================================================
    static managePricingReport(items: IItemPlus[], itemsToPrice: IPricingItem[], itemPricingReport: IConsolidatedItemPricingReport): IItemPlus[] {
        itemsToPrice.forEach((itemToPrice: IPricingItem) => {

            let matchingReports: IConsolidatedPricingReportItem[] | undefined = itemPricingReport.reportItems?.filter((reportItem: IConsolidatedPricingReportItem) => reportItem.clientItemInstanceIds?.includes(itemToPrice.instanceId));

            if (!matchingReports) {
                console.warn('[CB] - No pricing report found for the item instanceId');
            } else if (matchingReports.length > 1) {
                console.warn('[CB] - Multiple pricing reports found for the same item instanceId');
            } else if (matchingReports.length === 1 && matchingReports[0].clientItemInstanceIds?.length! > 1) {
                console.warn('[CB] - Multiple clientItemInstanceIds found in the pricing report');
            } else if (matchingReports.length === 1) {
                // Update the item with the pricing information
                let itemToUpdate: IItemPlus | undefined = items.find((item: IItemPlus) => item.id === itemToPrice.itemId);

                if (itemToUpdate) {
                    itemToUpdate.price = matchingReports[0].price;
                    itemToUpdate.currencyCode = itemPricingReport.currencyCode;

                    if (matchingReports[0].availability) {
                        itemToUpdate.availableInfo = matchingReports[0].availability;
                    }

                } else {
                    console.warn('[CB] - Item not found in the items list; weird situation...');
                }
            }

        });

        return items;

    };

    //============================================================================================
    static getSearchCatalogsList(selectedCatalogs: Array<ICatalogPlus>): Array<ICatalogPlus> {
        let searchCatalogs: Array<ICatalogPlus> = [];

        if (selectedCatalogs.length > 0) {
            searchCatalogs = selectedCatalogs;
        }

        return searchCatalogs;
    }

    //==================================================
    static getSearchCatalogsIds(searchCatalogs: Array<ICatalogPlus>): Array<string> {
        let searchCatalogIds: Array<string> = [];

        if (searchCatalogs.length > 0) {
            searchCatalogs.map((publicCatalog: ICatalogPlus) => searchCatalogIds.push(publicCatalog.id));
        }

        return searchCatalogIds;
    }

    //==================================================
    static getSearchCatalogsRootId(searchCatalogs: Array<ICatalogPlus>): Array<string> {
        let searchCatalogsRootId: Array<string> = [];

        if (searchCatalogs.length > 0) {
            searchCatalogs.map((publicCatalog: ICatalogPlus) => {
                if (!searchCatalogsRootId.includes(publicCatalog.rootId)) { //unique RootId
                    searchCatalogsRootId.push(publicCatalog.rootId);
                }
            });
        }

        return searchCatalogsRootId;
    }

    //==================================================
    static getSearchCatalogsIdAndRoot(searchCatalogs: Array<ICatalogPlus>): Array<string> {
        let searchCatalogIds: Array<string> = [];

        if (searchCatalogs.length > 0) {
            searchCatalogs.map((publicCatalog: ICatalogPlus) => searchCatalogIds.push(publicCatalog.id + '||' + publicCatalog.rootId));
        }

        return searchCatalogIds;
    }
    //==================================================
    static getSelectedCatalogsListFromIds(selectedCatalogIds: Array<string>, allCatalogs: Array<ICatalogPlus>): Array<ICatalogPlus> {
        let selectedCatalogs: Array<ICatalogPlus> = [];

        selectedCatalogIds.forEach((catalogId: string) => {
            let catalog: ICatalogPlus | undefined = allCatalogs.find((catalog: ICatalogPlus) => {
                return catalog.id === catalogId;
            });
            if (catalog) {
                selectedCatalogs.push(catalog);
            }
        });

        return selectedCatalogs;
    }

    //==================================================
    static getGroupsCode(selectedGroup: Array<IItemGroup>): Array<string> {
        let groupsCode: Array<string> = [];

        selectedGroup.forEach((group: IItemGroup) => {
            groupsCode.push(group.id);
        });

        return groupsCode;
    }

    //==================================================
    static getCatalogName(catalogId: string, catalogs: Array<ICatalogPlus>): string {
        let itemCatalog: ICatalogPlus | undefined = catalogs.find((catalog: ICatalogPlus) => catalog.id === catalogId),
            itemCatalogName: string = itemCatalog ? itemCatalog.name : '';

        return itemCatalogName;
    }

    //===================================================================================================
    static getCatalogInstance(ItemId: string, catalogs: Array<ICatalogPlus>): ICatalogPlus | undefined {
        let catalogId: string = ItemId.split('.')[0],
            itemCatalog: ICatalogPlus | undefined = catalogs.find((catalog: ICatalogPlus) => catalog.id === catalogId);

        return itemCatalog;
    }

    //===================================================================================================
    static getMeasurementSystem(ItemId: string, catalogs: Array<ICatalogPlus>): 'Imperial' | 'Metric' {
        let measuementSystem: 'Imperial' | 'Metric' = 'Metric',
            relatedCatalog: ICatalogPlus | undefined = this.getCatalogInstance(ItemId, catalogs);

        if (relatedCatalog && relatedCatalog.measurementSystem) {
            return relatedCatalog.measurementSystem === 'Imperial' ? 'Imperial' : 'Metric';
        } else {
            return measuementSystem;
        }
    }

    //====================================================================================================
    static getCatalogInstanceFromUUID(ItemUUID: string | undefined | null, items: Array<IConsolidatedClientItem>, catalogs: Array<ICatalogPlus>): ICatalogPlus | undefined {
        if (ItemUUID) {
            let item: IConsolidatedClientItem | undefined = items.find((item: IConsolidatedClientItem) => item.instanceId === ItemUUID),
                catalogId: string | undefined = item ? item.itemId?.split('.')[0] : undefined,
                itemCatalog: ICatalogPlus | undefined = catalogs.find((catalog: ICatalogPlus) => catalog.id === catalogId);

            return itemCatalog;
        } else {
            return undefined;
        }
    }

    //====================================================================================================
    static onAllCatalogSelected(catalogs: Array<ICatalogPlus>, isSelectingAll: boolean, filterCatalog?: string): Array<ICatalogPlus> {
        let updatedCatalogList: Array<ICatalogPlus> = [],
            filteredCatalogList: Array<ICatalogPlus> = filterCatalog ? catalogs.filter((catalog: ICatalogPlus) => catalog.name.toLowerCase().includes(filterCatalog)) : catalogs;

        filteredCatalogList.forEach((catalog: ICatalogPlus) => {
            catalog.isSelected = isSelectingAll;
        });

        updatedCatalogList = filteredCatalogList

        return updatedCatalogList;
    }

    //===============================================================
    static getSelectedCatalogs(allCatalogs: Array<ICatalogPlus>): Array<ICatalogPlus> {

        let selectedCatalogs: Array<ICatalogPlus> = allCatalogs.filter((catalog: ICatalogPlus) => {
            return catalog.isSelected === true && catalog.isHidden === false;
        });

        return selectedCatalogs;
    }

    //============================================================
    static updateCatalogSelection(selectedCatalogs: Array<ICatalogPlus>, allCatalogs: Array<ICatalogPlus>): Array<ICatalogPlus> {

        allCatalogs.forEach((catalog: ICatalogPlus) => {
            if (selectedCatalogs.find((selCatalog: ICatalogPlus) => selCatalog.id === catalog.id)) {
                catalog.isSelected = true;
            } else {
                catalog.isSelected = false;
            }
        });

        return allCatalogs;
    }

    //==========================================================
    static selectOneCatalog(catalogId: string, allCatalogs: Array<ICatalogPlus>): Array<ICatalogPlus> {
        allCatalogs.forEach((catalog: ICatalogPlus) => {
            if (catalogId === catalog.id) {
                catalog.isSelected = true;
            } else {
                catalog.isSelected = false;
            }
        });
        return allCatalogs;
    }

    //==========================================================
    static selectCatalogsByType(menuItem: CatalogMenuItem, allCatalogs: Array<ICatalogPlus>, previousSelection: Array<string> | undefined): Array<ICatalogPlus> {
        allCatalogs.forEach((catalog: ICatalogPlus) => {
            const hiddenCatalogs: Array<string> = this.getUserPref('hiddenCatalogs');

            if (menuItem.path === 'all' ||
                catalog.classification?.mainCategories?.find((mainCat: string) => menuItem.mainCategories.includes(mainCat))) {

                if (!this.isCatalogHidden(catalog.code, hiddenCatalogs)) {
                    catalog.isHidden = false; //unless really hidden

                    if (!previousSelection || previousSelection.includes(catalog.id)) {
                        catalog.isSelected = true;
                    } else {
                        catalog.isSelected = false;
                    }
                }
            } else {
                catalog.isSelected = false;
                catalog.isHidden = true;
            }
        });
        return allCatalogs;
    }

    //==========================================================
    static filterVisibleCatalogs(allCatalogs: Array<ICatalogPlus>): Array<string> {
        const hiddenCatalogs: Array<string> = this.getUserPref('hiddenCatalogs');
        const visibleCatalogIds: Array<string> = [];

        allCatalogs.forEach((catalog: ICatalogPlus) => {
            if (!this.isCatalogHidden(catalog.code, hiddenCatalogs)) {
                visibleCatalogIds.push(catalog.id);
            }
        });
        return visibleCatalogIds;
    }

    //==================================================
    static findOptimalNbPerPage(size: DOMRect | undefined, thumbnailSize: number): number {
        if (size) {
            let nbrWidth = Math.floor(size.width / (thumbnailSize) || 110),
                nbrHeight = Math.floor((size.height - 150) / (thumbnailSize) || 110),
                nbPerPage = Math.max(DEFAULT_NB_PER_PAGE, nbrWidth * nbrHeight);
            nbPerPage = Math.min(nbPerPage, 100); //not more than 100
            nbPerPage = nbPerPage % 2 === 0 ? nbPerPage : nbPerPage + 1;

            return nbPerPage;
        } else {
            return DEFAULT_NB_PER_PAGE;
        }
    }

    //==================================================
    static convertGroupTreeToFlatList(groups: Array<IItemGroup> | [] | undefined, flatGroupList: Array<IItemGroup> | undefined): Array<IItemGroup> {
        if (!flatGroupList) {
            flatGroupList = [];
        }

        if (groups) {
            groups.forEach((group: IItemGroup) => {
                if (group.visible) {
                    flatGroupList?.push(group);
                    if (group.groups) {
                        flatGroupList = this.convertGroupTreeToFlatList(group.groups, flatGroupList);
                    }
                }
            });
        }

        return flatGroupList;
    }

    //======================================================================
    static addToFavorite(item: IItemPlus) {
        let catalogId: string = item.catalog.id,
            itemWithoutId: string = item.id.split('.')[1],
            catalogFavorites: Array<string> = [],

            findGroupRefs = (item: IItemPlus) => {
                let groupRefs: string = '';
                item.groupRefs?.forEach((group: string, index: number) => {
                    groupRefs = groupRefs + group + '|';
                });
                return groupRefs;
            },
            addingFavorite: string = `${catalogId}.${itemWithoutId}||${findGroupRefs(item)}`;

        if (localStorage.getItem(catalogId)) {
            let catalogFavoritesString: string | null = localStorage.getItem(catalogId);
            catalogFavorites = catalogFavoritesString !== null ? JSON.parse(catalogFavoritesString) : null;
        }

        if (!catalogFavorites.includes(addingFavorite)) {
            catalogFavorites.push(addingFavorite);
        }

        localStorage.setItem(catalogId, JSON.stringify(catalogFavorites));
    }

    //======================================================================
    static removeFromFavorite(itemRootCatalogId: string, itemCode: string | undefined) {
        let catalogFavorites: Array<string> = [];

        if (localStorage.getItem(itemRootCatalogId)) {
            let catalogFavoritesString: string | null = localStorage.getItem(itemRootCatalogId);
            catalogFavorites = catalogFavoritesString !== null ? JSON.parse(catalogFavoritesString) : null;

            let favoriteToRemove: string | undefined = catalogFavorites.find((favorite: string) => favorite.indexOf(itemRootCatalogId + '.' + itemCode) > -1);

            if (favoriteToRemove) {
                let index: number = catalogFavorites.indexOf(favoriteToRemove);
                if (index > -1) {
                    catalogFavorites.splice(index, 1);
                    localStorage.setItem(itemRootCatalogId, JSON.stringify(catalogFavorites));
                    console.warn('Removing not found favorite: ' + favoriteToRemove);
                }
            }
        }
    }

    //======================================================================
    static isItemFavorited(item: IItemPlus): boolean {
        if (item?.catalogId) {
            let catalogId: string = item.catalog.id,
                itemWithoutId: string = item.id.split('.')[1],
                catalogFavorites: Array<string>;

            if (localStorage.getItem(catalogId)) {
                let catalogFavoritesString: string | null = localStorage.getItem(catalogId);
                catalogFavorites = catalogFavoritesString !== null ? JSON.parse(catalogFavoritesString) : null;

                if (catalogFavorites.find((favorite: string) => favorite.split('||')[0] === catalogId + '.' + itemWithoutId)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    //================================================================================
    static saveUserPref(configName: Config, value: any) {
        localStorage.setItem(configName, value);
    }

    //====================================
    static getUserPref(configName: Config): any {
        let storageValue: string | null = localStorage.getItem(configName);

        try {
            if (storageValue) {
                let configValue: string | null = JSON.parse(storageValue); //will throw an exeption if it is nt a JSON !

                return configValue;
            } else {
                return config[configName];
            }

        } catch (error) {
            return storageValue;
        }
    }

    //==================================================================================
    static reduce(numerator: number, denominator: number) {
        let findGCD = function (a: number, b: number): number {
            return b ? findGCD(b, a % b) : a;
        },
            gdc = Math.abs(findGCD(numerator, denominator)); //keep the positive value for this denominator

        return [numerator / gdc, denominator / gdc];
    }

    //=====================================================================
    static formatFraction(inputValue: number, supFraction?: boolean): string {
        if (inputValue % 1 === 0) {
            return inputValue.toString(); //
        } else {
            let entier: number = Math.floor(inputValue),
                decimal: number = parseFloat(`0.${inputValue.toString().split('.')[1]}`), //convert to string to avoid floating point i.e. 0.1 + 0.2 
                decimalNumerator: string | undefined = decimal.toString().split('.')[1],
                decimalLength: number = decimalNumerator ? decimalNumerator.length : 0,
                decimalDenominateur: number = Math.pow(10, decimalLength),
                fraction: Array<number> = this.reduce(parseInt(decimalNumerator), decimalDenominateur),
                formatValue: string = (entier > 0 ? entier + ' ' : ' ') + (supFraction && entier > 0 ? '<sup>' + fraction[0] + '/' + fraction[1] : fraction[0] + '/' + fraction[1]);

            const validDenominator: number[] = [2, 3, 4, 5, 8, 10, 16, 32, 64];

            if (!validDenominator.includes(fraction[1])) { //avoiding weird fraction... 
                if (entier > 0) {
                    formatValue = inputValue.toFixed(2);
                } else {
                    formatValue = inputValue.toFixed(2);
                }

            }
            return formatValue;
        }
    }

    //=====================================================================
    static fractionToNumber(inputValue: string): number {
        let matches = inputValue.toString().match(/(-?[0-9]+)\/([0-9]+)/),
            outputValue: number = 0;

        if (matches) {
            let fraction: number = Number(matches[1]) / Number(matches[2]);
            let entier: number = inputValue.toString().split(' ').length === 2 ? Number(inputValue.toString().split(' ')[0]) : 0;
            let numericalValue: number = fraction !== Infinity ? entier + fraction : 0;

            outputValue = numericalValue;
        } else {
            outputValue = Number(inputValue); //it was already a valid number
        }
        //outputValue = this.convertBackToOriginal(outputValue, measureUnit, measurementSystem);

        return outputValue;
    }

    //====================================================================
    static convertMeasurementValues(value: number | string | undefined, measureUnit: 'Imperial' | 'Metric', measurementSystem: 'Imperial' | 'Metric' | undefined): number | string {
        if (value === 'Mixed values') {
            return value;
        }

        if (value == undefined) {
            return '';
        }

        let newValue: number | string = this.convertToUserSystem(value as number, measureUnit, measurementSystem);

        if (measureUnit === 'Imperial' && measurementSystem !== 'Metric') {  //if converting to Imperial, keep decimal !
            if (typeof newValue === 'string') newValue = parseFloat(newValue);

            newValue = this.formatFraction(newValue as number);
        } else if (measurementSystem === 'Imperial' && measureUnit !== 'Imperial') {

        }

        return newValue;
    }

    //====================================================================
    static convertBackToOriginal(outputValue: number, measureUnit: 'Metric' | 'Imperial', measurementSystem: 'Imperial' | 'Metric' | undefined): number {
        if (measurementSystem !== undefined && measurementSystem !== measureUnit) {
            let conversionFactor = 1;
            switch (measureUnit) {
                case 'Metric':
                    //measureUnit = 'Metric';
                    //convert imperial to metric
                    conversionFactor = (2032 / 80);
                    break;
                case 'Imperial':
                    //measureUnit = 'Imperial';
                    //convert metric to imperial
                    conversionFactor = (80 / 2032);
                    break;
            }
            outputValue = Math.round(outputValue as number * conversionFactor * 100) / 100;

            if (measureUnit == 'Metric') {
                outputValue = parseFloat(Math.round(outputValue).toFixed(0)); //no fraction of mm
            }
        }

        return outputValue;
    }

    //====================================================================
    static convertToUserSystem(outputValue: number, measureUnit: 'Metric' | 'Imperial', measurementSystem: 'Imperial' | 'Metric' | undefined): number {
        if (measurementSystem !== undefined && measurementSystem !== measureUnit) {
            let conversionFactor = 1;
            switch (measurementSystem) {
                case 'Metric':
                    //measureUnit = 'Metric';
                    //convert imperial to metric
                    conversionFactor = (2032 / 80);
                    break;
                case 'Imperial':
                    //measureUnit = 'Imperial';
                    //convert metric to imperial
                    conversionFactor = (80 / 2032);
                    break;
            }

            outputValue = Math.round(outputValue as number * conversionFactor * 100) / 100;
        }

        return outputValue;
    }

    //====================================================================
    static buildOutputDimensionValues(item: IItemPlus, measureUnit: 'Imperial' | 'Metric', measurementSystem: 'Imperial' | 'Metric' | undefined,
        sorting?: sorting, selectedDimensions?: IDimensionFilters, withLabel?: boolean): Array<JSX.Element> {
        let variantName: string = '',
            buffer = [],
            index: number = Math.random() * 100;

        if (item.variantProposals?.length) {
            variantName = item.variantProposals[0].names.main.split(':')[0];
        }
        let width: string | number | JSX.Element = parseFloat(item?.selectedProposal?.dimensions?.width?.toString() || item.dimensions?.width?.toString() || ''),
            height: string | number | JSX.Element = parseFloat(item?.selectedProposal?.dimensions?.height?.toString() || item.dimensions?.height?.toString() || ''),
            depth: string | number | JSX.Element = parseFloat(item?.selectedProposal?.dimensions?.depth?.toString() || item.dimensions?.depth?.toString() || '');

        let widthStyling: string = sorting?.field === 'width' ? 'strong' : '',
            heightStyling: string = sorting?.field === 'height' ? 'strong' : '',
            depthStyling: string = sorting?.field === 'depth' ? 'strong' : '';

        if (sorting?.type === 'dimension') {
            let minWidth: number = item.dimensionSpecs?.width && item.dimensionSpecs?.width.range && item.dimensionSpecs.width.range.min ? parseFloat(item.dimensionSpecs.width.range.min.toString()) : width;
            let maxWidth: number = item.dimensionSpecs?.width && item.dimensionSpecs?.width.range && item.dimensionSpecs.width.range.max ? parseFloat(item.dimensionSpecs.width.range.max.toString()) : width;
            let minHeight: number = item.dimensionSpecs?.height && item.dimensionSpecs?.height.range && item.dimensionSpecs.height.range.min ? parseFloat(item.dimensionSpecs.height.range.min.toString()) : height;
            let maxHeight: number = item.dimensionSpecs?.height && item.dimensionSpecs?.height.range && item.dimensionSpecs.height.range.max ? parseFloat(item.dimensionSpecs.height.range.max.toString()) : height;
            let minDepth: number = item.dimensionSpecs?.depth && item.dimensionSpecs?.depth.range && item.dimensionSpecs.depth.range.min ? parseFloat(item.dimensionSpecs.depth.range.min.toString()) : depth;
            let maxDepth: number = item.dimensionSpecs?.depth && item.dimensionSpecs?.depth.range && item.dimensionSpecs.depth.range.max ? parseFloat(item.dimensionSpecs.depth.range.max.toString()) : depth;

            //width
            if (sorting?.field === 'width' && sorting.order === 'asc') {
                if (selectedDimensions?.widths && selectedDimensions?.widths.length > 0) {
                    width = selectedDimensions?.widths[0].min || minWidth;
                } else {
                    width = minWidth;
                }
            } else if (sorting?.field === 'width' && sorting.order === 'desc') {
                if (selectedDimensions?.widths && selectedDimensions?.widths.length > 0) {
                    width = selectedDimensions?.widths[0].max || maxWidth;
                } else {
                    width = maxWidth;
                }
            }
            //height
            if (sorting?.field === 'height' && sorting.order === 'asc') {
                if (selectedDimensions?.heights && selectedDimensions?.heights.length > 0) {
                    height = selectedDimensions?.heights[0].min || minHeight;
                } else {
                    height = minHeight;
                }
            } else if (sorting?.field === 'height' && sorting.order === 'desc') {
                if (selectedDimensions?.heights && selectedDimensions?.heights.length > 0) {
                    height = selectedDimensions?.heights[0].max || maxHeight;
                } else {
                    height = maxHeight;
                }
            }
            //depth
            if (sorting?.field === 'depth' && sorting.order === 'asc') {
                if (selectedDimensions?.depths && selectedDimensions?.depths.length > 0) {
                    depth = selectedDimensions?.depths[0].min || minDepth;
                } else {
                    depth = minDepth;
                }
            } else if (sorting?.field === 'depth' && sorting.order === 'desc') {
                if (selectedDimensions?.depths && selectedDimensions?.depths.length > 0) {
                    depth = selectedDimensions?.depths[0].max || maxDepth;
                } else {
                    depth = maxDepth;
                }
            }
        }

        //====
        width = this.convertToUserSystem(width, measureUnit, measurementSystem);
        height = this.convertToUserSystem(height, measureUnit, measurementSystem);
        depth = this.convertToUserSystem(depth, measureUnit, measurementSystem);

        if (measureUnit === 'Imperial' && measurementSystem !== 'Metric') {  //if converting to Imperial, keep decimal !
            width = this.formatFraction(width, true);
            height = this.formatFraction(height, true);
            depth = this.formatFraction(depth, true);

            if (width.indexOf('<sup>') > -1) {
                let tempwidth: JSX.Element = <span>{width.split('<sup>')[0]} <sup>{width.split('<sup>')[1]}</sup></span>;
                width = tempwidth;
            }
            if (height.indexOf('<sup>') > -1) {
                let tempHeight: JSX.Element = <span>{height.split('<sup>')[0]} <sup>{height.split('<sup>')[1]}</sup></span>;
                height = tempHeight;
            }
            if (depth.indexOf('<sup>') > -1) {
                let tempDepth: JSX.Element = <span>{depth.split('<sup>')[0]} <sup>{depth.split('<sup>')[1]}</sup></span>;
                depth = tempDepth;
            }
        } else if (measurementSystem === 'Imperial' && measureUnit !== 'Imperial') {
            width = width % 1 != 0 ? width.toFixed(1) : width;
            height = height % 1 != 0 ? height.toFixed(1) : height;
            depth = depth % 1 != 0 ? depth.toFixed(1) : depth;
        } else if (measureUnit === 'Metric' && measurementSystem !== 'Imperial') {
            width = width % 1 != 0 ? width.toFixed(1) : width;
            height = height % 1 != 0 ? height.toFixed(1) : height;
            depth = depth % 1 != 0 ? depth.toFixed(1) : depth;
        } else {
            width = width % 1 != 0 ? width.toFixed(1) : width;
            height = height % 1 != 0 ? height.toFixed(1) : height;
            depth = depth % 1 != 0 ? depth.toFixed(1) : depth;
        }

        let elWidth: JSX.Element = variantName === 'width' ? (<span key={'width' + index + item.id} className={`item-info__normal ${widthStyling}`}>{withLabel ? strings['itemCard.dimensions.width'] : ''} {width}</span>) : (<span key={'width' + index + item.id} className={`item-info__normal ${widthStyling}`}>{withLabel ? strings['itemCard.dimensions.width'] : ''} {width}</span>),
            elHeight: JSX.Element = variantName === 'height' ? (<span key={'height' + index + item.id} className={`item-info__normal ${heightStyling}`}>{withLabel ? strings['itemCard.dimensions.height'] : ''} {height}</span>) : (<span key={'height' + index + item.id} className={`item-info__normal ${heightStyling}`}>{withLabel ? strings['itemCard.dimensions.height'] : ''} {height}</span>),
            elDepth: JSX.Element = variantName === 'depth' ? (<span key={'depth' + index + item.id} className={`item-info__normal ${depthStyling}`}>{withLabel ? strings['itemCard.dimensions.depth'] : ''} {depth}</span>) : (<span key={'depth' + index + item.id} className={`item-info__normal ${depthStyling}`}>{withLabel ? strings['itemCard.dimensions.depth'] : ''} {depth}</span>),
            elPlus1: JSX.Element = <span key={'x1-' + index + item.id} className='bold'>&nbsp;x&nbsp;</span>,
            elPlus2: JSX.Element = <span key={'x2-' + index + item.id} className='bold'>&nbsp;x&nbsp;</span>,
            elEmpty1: JSX.Element = <span key={'x1-' + index + item.id} className='bold'>&nbsp;&nbsp;&nbsp;</span>,
            elEmpty2: JSX.Element = <span key={'x2-' + index + item.id} className='bold'>&nbsp;&nbsp;&nbsp;</span>;

        buffer.push(elWidth);
        buffer.push(withLabel ? elEmpty1 : elPlus1);
        buffer.push(elHeight);
        buffer.push(withLabel ? elEmpty2 : elPlus2);
        buffer.push(elDepth);

        return buffer;
    }

    //=======================================================================
    static setLocale(APIHandle: ICiCAPI = CiCAPI): string {
        let availbleLocale: Array<string> = ['en-US', 'fr-FR', 'es-ES'],
            actualLocale = CiCAPI.getExecutionContext().locale;

        if (!actualLocale) actualLocale = 'en-us';

        this.saveUserPref('locale', actualLocale);

        return actualLocale;
    }

    //=======================================================================
    static getLocale() {
        let locale = 'en-us',
            actualLocale: string | undefined = CiCAPI.getExecutionContext().locale?.toLocaleLowerCase();

        return actualLocale || locale;
    }

    //=========================================================================
    static sortCatalogByCompany(catalogList: Array<ICatalogPlus>) {
        companysMap.clear();
        catalogList.sort((a, b) => (a.companyDisplayName > b.companyDisplayName ? 1 : -1)).forEach((catalog: ICatalogPlus) => {
            if (!companysMap.has(catalog.companyDisplayName)) {
                let relatedCatalogs: Array<ICatalogPlus> = catalogList.filter((cat: ICatalogPlus) => cat.companyDisplayName === catalog.companyDisplayName);

                relatedCatalogs = relatedCatalogs.sort((a: ICatalogPlus, b: ICatalogPlus) => {
                    return (a.name > b.name || a.name === b.name && a.version > b.version ? 1 : -1);
                });

                companysMap.set(catalog.companyDisplayName, relatedCatalogs);
            }
        });
    }

    //=========================================================================
    static sortCatalogByName(catalogList: Array<ICatalogPlus>) {
        return catalogList.sort((a: ICatalogPlus, b: ICatalogPlus) => {
            return (a.name > b.name ||
                a.name === b.name && parseInt(a.version.split('.')[0]) < parseInt(b.version.split('.')[0]) ||
                a.name === b.name && parseInt(a.version.split('.')[0]) === parseInt(b.version.split('.')[0]) && parseInt(a.version.split('.')[1]) < parseInt(b.version.split('.')[1]) ||
                a.name === b.name && parseInt(a.version.split('.')[0]) === parseInt(b.version.split('.')[0]) && parseInt(a.version.split('.')[1]) === parseInt(b.version.split('.')[1]) && parseInt(a.version.split('.')[2]) < parseInt(b.version.split('.')[2]) ? 1 : -1);
        });
    }

    //==========================================================================
    static isAllSelected(catalogs: Array<ICatalogPlus>, selectedCatalogs: Array<ICatalogPlus>): boolean {
        let notHiddenCatalog: Array<ICatalogPlus> = catalogs.filter((catalog: ICatalogPlus) => !catalog.isHidden),
            allSelected: boolean = notHiddenCatalog.every((catalog: ICatalogPlus) => {
                return selectedCatalogs.find((selectedCatalog: ICatalogPlus) => selectedCatalog.id === catalog.id);
            });

        return allSelected;
    }

    //===========================================================================
    static findGroup(code: string, groups: Array<IItemGroup>): IItemGroup | undefined {
        let foundGroup = groups.find((group: IItemGroup) => {
            return group.id === code;
        });

        return foundGroup;
    }

    //==========================================================================================
    static findParentGroup(code: string, index: number, allCodes: Array<string>): Array<string> {
        let parentCode: Array<string> = [];

        for (let i = index - 1; i >= 0; i--) {
            parentCode.push(allCodes[i]);
        }

        return parentCode;
    }

    //===========================================================
    static buildImageContent(imageURL: string, thumbnailSize: number | null, containerClassName: string, baseUrl: string, dimStyle?: React.CSSProperties, noImageAlt?: string): JSX.Element {
        let retried: boolean = false,
            imageContent,
            altImagePath: string = baseUrl + 'assets/images/',
            missingImage: string = '/assets/images/no-catalog-thumbnail.png';

        if (imageURL?.trim() !== "" && navigator.onLine) {
            imageContent = (<img width={thumbnailSize || '100%'} height={thumbnailSize || '100%'} src={imageURL} draggable="false"
                onError={e => {
                    if (!retried) {
                        e.currentTarget.src = baseUrl + missingImage;
                        retried = true;
                    }
                }}
            />);
        } else if (noImageAlt) {
            imageContent = (<img width={thumbnailSize || '100%'} height={thumbnailSize || '100%'} src={altImagePath + noImageAlt} draggable="false"
                onError={e => {
                    if (!retried) {
                        e.currentTarget.src = baseUrl + missingImage;
                        retried = true;
                    }
                }}
            />);
        } else {
            imageContent = (<div className={containerClassName} style={dimStyle || {}}> {noImageAlt ? noImageAlt : strings['item.image.notAvailable']} </div>);
        }

        return imageContent;
    }


    //===============================================================
    static loadCompanyCatalogsMap(allCatalogs: Array<ICatalogPlus>) {
        allCatalogs.forEach((catalog: ICatalogPlus) => {
            if (!companyCatalogsMap.has(catalog.companyDisplayName)) {
                companyCatalogsMap.set(catalog.companyDisplayName, [catalog]);

            } else {
                let actualCatalogs: Array<ICatalogPlus> | undefined = companyCatalogsMap.get(catalog.companyDisplayName);

                if (actualCatalogs) {
                    actualCatalogs.push(catalog);
                }
                companyCatalogsMap.set(catalog.companyDisplayName, actualCatalogs || []);
            }
        });
    }

    //============================================================================
    static extractManufacturerListFromCatalogs(catalogs: Array<ICatalogPlus>): Array<string> {
        let manufacturerList: Array<string> = [];

        catalogs.forEach((catalog: ICatalogPlus) => {
            if (!manufacturerList.includes(catalog.companyDisplayName)) {
                manufacturerList.push(catalog.companyDisplayName)
            }
        });

        manufacturerList.sort();
        manufacturerList.unshift(strings['catalog.type.all.manufacturers']);

        return manufacturerList;
    }

    //======================================================================================
    static filterAllCatalogs(allCatalogs: Array<ICatalogPlus>, filterCatalog: string, catalogStatusFilter: string, manufacturerFilter: string, prefHiddenCatalogs: Array<string>, catalogTypeFilter: string): Array<ICatalogPlus> {
        //reset all to visible first
        allCatalogs.forEach((catalog: ICatalogPlus) => {
            catalog.isVisible = true;
        });
        //now filterOut bad results
        allCatalogs.forEach((catalog: ICatalogPlus) => {
            if (filterCatalog) {
                if (!catalog.name.toLowerCase().includes(filterCatalog) &&
                    !catalog.companyRefCode.toLowerCase().includes(filterCatalog) &&
                    !catalog.companyDisplayName.toLowerCase().includes(filterCatalog)) {
                    catalog.isVisible = false;
                }
            }
            if (catalogStatusFilter !== 'all' && catalog.isVisible === true) {
                if (catalogStatusFilter === 'hidden') {
                    if (!prefHiddenCatalogs.includes(catalog.code)) {
                        catalog.isVisible = false;
                    }

                } else if (catalogStatusFilter === 'visible') {
                    if (catalog.accessStatus === 'RequestRequired' ||
                        catalog.accessStatus === 'Pending' ||
                        catalog.accessStatus === 'Denied' ||
                        catalog.accessStatus === 'Requested' ||
                        catalog.accessStatus === 'Error' ||
                        prefHiddenCatalogs.includes(catalog.code)) {
                        catalog.isVisible = false;
                    }
                } else if (catalogStatusFilter === 'lock') {
                    if (catalog.accessStatus !== 'RequestRequired' &&
                        catalog.accessStatus !== 'Requested' &&
                        catalog.accessStatus !== 'Error') {
                        catalog.isVisible = false;
                    }
                } else if (catalogStatusFilter === 'pending') {
                    if (catalog.accessStatus !== 'Pending' &&
                        catalog.accessStatus !== 'Requested') {
                        catalog.isVisible = false;
                    }
                } else if (catalogStatusFilter === 'denied') {
                    if (catalog.accessStatus !== 'Denied') {
                        catalog.isVisible = false;
                    }
                }
            }
            if (manufacturerFilter !== strings['catalog.type.all.manufacturers'] && catalog.isVisible === true) {
                if (catalog.companyDisplayName !== manufacturerFilter) {
                    catalog.isVisible = false;
                }
            }
            if (catalogTypeFilter !== 'all' && catalog.isVisible === true) {
                if (catalogTypeFilter === 'kitchen' && !catalog?.classification?.mainCategories?.includes('cabinetry')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'appliances' && !catalog?.classification?.mainCategories?.includes('appliances')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'bedroom' && !catalog?.classification?.mainCategories?.includes('architectural items')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'bathroom' && !catalog?.classification?.mainCategories?.includes('sinks') && !catalog?.classification?.mainCategories?.includes('faucets')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'decorate' && !catalog?.classification?.mainCategories?.includes('deco/accessories')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'lighting' && !catalog?.classification?.mainCategories?.includes('lighting')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'office' && !catalog?.classification?.mainCategories?.includes('office')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'outdoor' && !catalog?.classification?.mainCategories?.includes('outdoor products')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'architectural' && !catalog?.classification?.mainCategories?.includes('architectural items')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'storage' && !catalog?.classification?.mainCategories?.includes('storage')) {
                    catalog.isVisible = false;
                } else if (catalogTypeFilter === 'materials' && !catalog?.classification?.mainCategories?.includes('materials')) {
                    catalog.isVisible = false;
                }
            }
        });

        return allCatalogs;
    }

    //===========================================================================
    static capitalizeText(text: string): string {

        let outputText: string = text.toLowerCase();
        outputText = outputText.charAt(0).toUpperCase() + outputText.slice(1);

        return outputText;
    }

    //==================================================================================================================
    static async updateFeatureList(APIHandle: ICiCAPI = CiCAPI, itemContextList: Array<IConsolidatedClientItem> | [] | undefined, combinationType: CombinationType): Promise<ICatalogWithFeatures[]> {
        const apiOptions: V2.IOptionsGetItemFeatures = { combinationType: combinationType };

        let allFeaturesByCatalogsLists: Array<IConsolidatedItemFeatures | undefined>,
            uniqueCatalogMap: Map<string, Array<IConsolidatedClientItem>> = new Map(),
            promises: Array<Promise<IConsolidatedItemFeatures | undefined>>,
            allFeatures: Array<ICatalogWithFeatures> = [],
            tempCatalogWithFeatures: ICatalogWithFeatures;

        try {
            itemContextList?.forEach((itemVariant: IConsolidatedClientItem) => {
                if (itemVariant.instanceExtras?.selected) { //&& itemIsFromSelectedCatalog(itemVariant, catalogSelected)
                    const catalogId: string = itemVariant.itemId?.split('.')[0] || 'unknown';

                    if (uniqueCatalogMap.get(catalogId) !== undefined) {
                        let items: Array<IConsolidatedClientItem> = uniqueCatalogMap.get(catalogId) || [];
                        items.push(itemVariant);
                        uniqueCatalogMap.set(catalogId, items);

                    } else {
                        uniqueCatalogMap.set(catalogId, [itemVariant]);
                    }
                }
            });


            promises = Array.from(uniqueCatalogMap.values()).map((items: Array<IConsolidatedClientItem>) => {
                return UIToolkitUtils.getItemFeatures(items, APIHandle, apiOptions);
            });
            allFeaturesByCatalogsLists = await Promise.all(promises);
            allFeaturesByCatalogsLists.forEach((featureResponse: IConsolidatedItemFeatures | undefined, index: number) => {

                if (featureResponse?.catalog) {
                    const catVersionId: string | undefined = Array.from(uniqueCatalogMap.keys())[index];
                    tempCatalogWithFeatures = featureResponse?.catalog;
                    tempCatalogWithFeatures.versionId = catVersionId || '';
                    tempCatalogWithFeatures.features = [];
                    tempCatalogWithFeatures.featureGroups = [];
                }
                if (featureResponse?.features) {
                    featureResponse?.features?.forEach((feature: IFeaturePlus) => {

                        if (feature.visible) {
                            tempCatalogWithFeatures.features?.push(feature);
                        }
                    });
                }
                if (featureResponse?.featureGroups) {
                    featureResponse?.featureGroups?.forEach((feature: IFeaturePlus | IFeatureGroupPlus) => {
                        const updatedFeature = this.updateFeatureGroupList(feature);

                        if (updatedFeature) {
                            tempCatalogWithFeatures.featureGroups?.push(updatedFeature);
                        }
                    });
                }



                allFeatures.push(tempCatalogWithFeatures);
            });

        } catch (error) {
            console.error('updateFeatureList did not succeed', error);
        }

        return allFeatures;
    }

    //==============================================================================================================
    static updateFeatureGroupList(feature: IFeaturePlus | IFeatureGroupPlus): IFeaturePlus | IFeatureGroupPlus | undefined {

        let updatedFeature: IFeaturePlus | IFeatureGroupPlus | undefined = { ...feature };

        if (this.isFeatureGroup(feature)) {
            if ((feature as IFeatureGroupPlus).features?.length) {
                (feature as IFeatureGroupPlus).features?.forEach((featureInt: IFeaturePlus, index: number) => {

                    if (featureInt.visible) {
                        if (!(updatedFeature as IFeatureGroupPlus).features?.find((feature: IFeaturePlus) => feature.id === featureInt.id)) {
                            (updatedFeature as IFeatureGroupPlus).features?.push(featureInt);
                        }
                    } else {
                        (feature as IFeatureGroupPlus).features?.splice(index, 1);
                    }
                });
            }
            if ((feature as IFeatureGroupPlus).featureGroups?.length) {
                (feature as IFeatureGroupPlus).featureGroups?.forEach((featureInt: IFeatureGroupPlus) => {
                    let featureGroupUpdated: IFeaturePlus | IFeatureGroupPlus | undefined = this.updateFeatureGroupList(featureInt);

                    if (featureGroupUpdated) {
                        if (!(updatedFeature as IFeatureGroupPlus).featureGroups?.find((feature: IFeatureGroupPlus) => feature.id === featureInt.id && feature.itemUUID === featureInt.itemUUID)) {
                            (updatedFeature as IFeatureGroupPlus).featureGroups?.push(featureGroupUpdated);
                        }
                    }
                });
            }
        } else if (!(feature as IFeaturePlus).visible) {
            updatedFeature = undefined;
        }

        return updatedFeature;
    }

    //===============================================================================
    static async requestCatalogAccess(APIHandle: ICiCAPI = CiCAPI, catalog: ICatalogPlus): Promise<RequestAccessStatus | string | undefined> {
        let response: RequestAccessStatus | string | undefined;

        try {
            let requestResponse: IAPIResponse<RequestAccessStatus> = await UIToolkitUtils.requestCatalogAccess(catalog.id, APIHandle);

            if (requestResponse.code === 'OK') {
                response = 'Requested';
                //response = requestResponse.result;
            } else {
                //manage error message
                //response = requestResponse.message;
                response = 'Error';
            }

        } catch (error) {
            console.error('requestCatalogAccess did not succeed', error);
        }

        return response;
    }

    //==================================================================================================================
    static async fetchFeatureOptions(APIHandle: ICiCAPI = CiCAPI,
        itemContextList: Array<IConsolidatedClientItem> | undefined,
        feature: IFeaturePlus,
        filters: ISearchClassifications,
        combinationType: CombinationType,
        offset: number,
        searchText: string | undefined,
        nbPerPage: number,
        allCatalogs?: Array<string>
    ): Promise<IFeaturePlus> {

        let uniqueOptionsMap: Map<string, IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup> = new Map();
        let tempFilters: ISearchClassifications = JSON.parse(JSON.stringify(filters));

        tempFilters.catalogs = undefined;
        if (tempFilters.characteristics?.baseItemTypes && Object.entries(tempFilters.characteristics.baseItemTypes).length > 0) {
            tempFilters.baseItemTypes = tempFilters.characteristics.baseItemTypes;
        }
        if (tempFilters.characteristics?.catalogs && Object.entries(tempFilters.characteristics.catalogs).length > 0) {
            tempFilters.catalogs = tempFilters.characteristics.catalogs;
        }
        if (tempFilters.characteristics?.companies && Object.entries(tempFilters.characteristics.companies).length > 0) {
            tempFilters.companies = tempFilters.characteristics.companies;
        }
        if (tempFilters.characteristics?.baseItemTypes) {
            delete tempFilters.characteristics.baseItemTypes;
        }
        if (tempFilters.characteristics?.catalogs) {
            delete tempFilters.characteristics.catalogs;
        }
        if (tempFilters.characteristics?.companies) {
            delete tempFilters.characteristics.companies;
        }
        //if (tempFilters.characteristics && Object.entries(tempFilters.characteristics).length === 0) {
        //    delete tempFilters.characteristics;
        //}
        try {
            if (itemContextList?.length) {
                const theFilters: V2.IOptionsGetItemFeatureOptions = {
                    filter: {
                        catalogVersionIds: allCatalogs,
                        nbPerPage: nbPerPage,
                        offset: offset,
                        classificationFilter: tempFilters,
                        visibleOnly: true,
                        useFilterConfig: true,
                        search: searchText
                    },
                    combinationType: combinationType
                }

                let getOptionsResults = await UIToolkitUtils.getItemFeatureOptions(itemContextList, feature.id, APIHandle, theFilters)

                getOptionsResults?.options?.forEach(option => uniqueOptionsMap.set(option.id, option));
                getOptionsResults?.optionGroups?.forEach(option => uniqueOptionsMap.set(option.id, option));

                if (offset! > 0 && feature.options) {
                    feature.options = feature.options.concat(...Array.from(uniqueOptionsMap.values()) as []);
                    if (feature.searchResult && getOptionsResults) {
                        feature.searchResult.offset = offset || 1;
                    }
                } else {
                    feature.options = [].concat(...Array.from(uniqueOptionsMap.values()) as []);
                    if (feature.searchResult && getOptionsResults) {
                        feature.searchResult.total = getOptionsResults && (getOptionsResults.searchResult as IConsolidatedSearchResult)?.total || 0;
                        feature.searchResult.nbPerPage = nbPerPage || 25;
                        feature.searchResult.offset = 0;

                        let characteristicFacets = getOptionsResults?.searchResult?.facets?.filter(facet => !/(width|height|depth)/.test(facet.code));
                        feature.searchResult.facets = characteristicFacets; //only update facets if it is a new facet


                    } else if (getOptionsResults?.searchResult) {
                        feature.searchResult = getOptionsResults?.searchResult;
                    }
                }
            }

        } catch (error) {
            console.error('fetchFeatureOptions did not succeed', error);
        }

        return feature;
    }

    //==================================================================================================================
    static mergeItemsConfigurationState(itemContextList: Array<IConsolidatedClientItem> | [] | undefined): Array<IConsolidatedFeatureState> | [] {
        let configurationState: Array<IConsolidatedFeatureState> = [];

        itemContextList?.forEach((itemVariant: IConsolidatedClientItem) => {
            if (itemVariant.instanceExtras?.selected) {
                let uniqueConfigurationMap: Map<string, IConsolidatedFeatureState> = new Map();

                if (itemVariant.configurationState) {
                    itemVariant.configurationState.forEach((featureState: IConsolidatedFeatureState) => {
                        uniqueConfigurationMap.set(featureState.featureId + ':' + featureState.optionId, featureState);
                    });
                    configurationState = configurationState.concat(...Array.from(uniqueConfigurationMap.values()) as []);
                }
            }
        });

        return configurationState;
    }

    //===============================================================================================
    static findDefaultSelectedCatalog(itemContextList: Array<IConsolidatedItemVariant> | [] | undefined, allCatalogs: Array<ICatalogPlus>): Array<ICatalogPlus> | undefined {
        let itemsCatalog: Array<ICatalogPlus> = [];

        itemContextList?.forEach((itemVariant: IConsolidatedItemVariant) => {
            if (itemVariant.selected) {
                let catalogId: string = itemVariant.catalogId,
                    itemCatalog: ICatalogPlus | undefined = allCatalogs.find((catalog: ICatalogPlus) => catalog.id === catalogId);

                if (itemCatalog && !itemsCatalog.includes(itemCatalog)) {
                    itemsCatalog.push(itemCatalog);
                }
            }
        });

        return itemsCatalog || undefined;
    }

    //==================================================================================================================
    static itemIsFromSelectedCatalog(item: IConsolidatedClientItem, selectedCatalog: ICatalogPlus | undefined): boolean {
        return !selectedCatalog || item.itemId?.split('.')[0] === selectedCatalog.id;
    }

    //==================================================================================================================
    static findSelectedItems(itemContextList: Array<IConsolidatedClientItem> | [], catalogSelected?: ICatalogPlus): Array<IConsolidatedClientItem> | [] {
        let selectedItemVariants: Array<IConsolidatedClientItem> = [];

        itemContextList?.forEach((itemVariant: IConsolidatedClientItem) => {
            if (itemVariant.instanceExtras?.selected) {
                if (catalogSelected) {
                    if (this.itemIsFromSelectedCatalog(itemVariant, catalogSelected)) {
                        selectedItemVariants.push(itemVariant);
                    }
                } else {
                    selectedItemVariants.push(itemVariant);
                }
            }
        });

        return selectedItemVariants;
    }

    //==================================================================================================================
    static findSelectedClientItems(clientItemList: Array<IConsolidatedClientItem>): Array<IConsolidatedClientItem> | [] {
        let selectedClientItems: Array<IConsolidatedClientItem> = [];

        clientItemList?.forEach((clientItem: IConsolidatedClientItem) => {
            if (clientItem.instanceExtras?.selected) {
                selectedClientItems.push(clientItem);
            }
        });

        return selectedClientItems;
    }

    //==================================================================================================================
    static assertActiveFeature(activeFeature: IFeaturePlus | null, features: Array<IFeaturePlus | IFeatureGroupPlus>): IFeaturePlus | null {
        let featureToAssert: IFeaturePlus | IFeatureGroupPlus | void | null = null;

        if (activeFeature) {
            featureToAssert = this.findFeatureInTree(features, activeFeature);

            if (featureToAssert) {
                (featureToAssert as IFeaturePlus).options = activeFeature.options;
            } else {
                featureToAssert = null;
            }
        }

        if (!featureToAssert && features.length > 0) { //selecting first feature if, none yet selected
            featureToAssert = this.findFirstFeatureInTree(features[0]);
        }

        return featureToAssert as IFeaturePlus | null;
    }

    //==================================================================================================
    static findFeatureInTree(features: Array<IFeaturePlus | IFeatureGroupPlus>, featureToFind: IFeaturePlus): IFeaturePlus | undefined {
        let foundFeature: IFeaturePlus | undefined = undefined;

        for (let i: number = 0; i < features.length; i++) {
            if (this.isFeatureGroup(features[i])) {
                if ((features[i] as IFeatureGroupPlus).features?.find((intFeatureToFind: IFeaturePlus) => intFeatureToFind.id === featureToFind.id)) {
                    foundFeature = (features[i] as IFeatureGroupPlus).features?.find((intFeatureToFind: IFeaturePlus) => intFeatureToFind.id === featureToFind.id);
                    if (foundFeature) {
                        break;
                    }
                } else if ((features[i] as IFeatureGroupPlus).featureGroups?.length) {
                    foundFeature = this.findFeatureInTree((features[i] as IFeatureGroupPlus).featureGroups || [], featureToFind);
                    if (foundFeature) {
                        break;
                    }
                }
            } else if (features[i].id === featureToFind.id) {
                foundFeature = (features[i] as IFeaturePlus);
                if (foundFeature) {
                    break;
                }
            }
        }

        return foundFeature;
    }

    //===============================================================================
    static findFirstFeatureInTree(feature: IFeaturePlus | IFeatureGroupPlus): IFeaturePlus {

        if (this.isFeatureGroup(feature)) {
            if ((feature as IFeatureGroupPlus).features?.length) {
                return (feature as IFeatureGroupPlus).features![0];

            } else {
                return this.findFirstFeatureInTree((feature as IFeatureGroupPlus).featureGroups![0]);
            }
        } else {
            return feature as IFeaturePlus;
        }
    }

    //==================================================================================================
    static findOptionInTree(options: Array<IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup>, optionToFind: IConsolidatedFeatureOption): IConsolidatedFeatureOption | undefined {
        let foundOption: IConsolidatedFeatureOption | undefined = undefined;

        for (let i: number = 0; i < options.length; i++) {
            if (this.isOptionGroup(options[i])) {
                if ((options[i] as IConsolidatedFeatureOptionGroup).options?.find((intOptionToFind: IConsolidatedFeatureOption) => intOptionToFind.id === optionToFind.id)) {
                    foundOption = (options[i] as IConsolidatedFeatureOptionGroup).options?.find((intOptionToFind: IConsolidatedFeatureOption) => intOptionToFind.id === optionToFind.id);
                    break;
                } else if ((options[i] as IConsolidatedFeatureOptionGroup).optionGroups?.length) {
                    foundOption = this.findOptionInTree((options[i] as IFeatureGroupPlus).featureGroups || [], optionToFind);
                    break;
                }
            } else {
                if (options[i].id === optionToFind.id) {
                    foundOption = (options[i] as IConsolidatedFeatureOption);
                    break;
                }
            }
        }

        return foundOption;
    }

    //==================================================================================================
    static findOptionCountInTree(options: Array<IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup>, actualCount: number): number {

        for (let i: number = 0; i < options.length; i++) {
            if (this.isOptionGroup(options[i])) {
                if ((options[i] as IConsolidatedFeatureOptionGroup).options?.length ?? 0 > 0) {
                    actualCount = actualCount + ((options[i] as IConsolidatedFeatureOptionGroup).options?.length ?? 0);
                } else if ((options[i] as IConsolidatedFeatureOptionGroup).optionGroups?.length) {
                    actualCount = actualCount + this.findOptionCountInTree((options[i] as IFeatureGroupPlus).featureGroups || [], actualCount);
                }
            } else {
                if (options[i].id) {
                    actualCount = actualCount + 1;
                }
            }
        }

        return actualCount;
    }
    //==================================================================================================================
    static isOptionSelected(feature: IFeaturePlus | undefined, filteredItems: IConsolidatedClientItem[], option: IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup, toggleOnlyFeature: boolean): boolean {
        if (toggleOnlyFeature && (option as IConsolidatedFeatureOption).id.split('.')[1] === '_IS_INCLUDED') {
            return (option as IConsolidatedFeatureOption).value === 1 ? true : false;

        } else if (feature?.selectedOptions && feature?.selectedOptions?.length === 1) { //only one Item
            return option.id === feature.selectedOptions[0].option?.id;

        } else if (feature?.selectedOptions && feature?.selectedOptions?.length > 1) {
            let selectedItemUUIDs: Array<string> = [];
            let itemsUUIDs: Array<string> = [];
            let optionSelected: IConsolidatedFeatureSelection | undefined = feature?.selectedOptions.find((selection: IConsolidatedFeatureSelection) => {
                return selection.option.id === option.id
            });
            filteredItems.forEach((item: IConsolidatedClientItem) => {
                if (item.instanceId) itemsUUIDs.push(item.instanceId)
            });
            optionSelected?.clientItemContexts?.forEach((item: IConsolidatedClientItemContext) => {
                selectedItemUUIDs.push(item.instanceId);
            });

            return JSON.stringify(selectedItemUUIDs.sort()) === JSON.stringify(itemsUUIDs.sort());


        } else {
            return false;
        }
    }

    //====================================================================================================================
    static isSelectedOptionInGroup(optionGroup: IConsolidatedFeatureOptionGroup, feature: IFeaturePlus | undefined): boolean {
        let foundOption: boolean = false;


        let selectedOpionId: string | undefined = feature && feature.selectedOptions && feature.selectedOptions[0].option?.id,
            optionSelected: IConsolidatedFeatureOption | undefined;

        optionSelected = optionGroup?.options?.find((option: IConsolidatedFeatureOption) => option.id === selectedOpionId);

        if (optionSelected) {
            foundOption = true;
            return foundOption;

        } else if (optionGroup?.optionGroups && optionGroup?.optionGroups?.length > 0) {
            let foundGroup: IConsolidatedFeatureOptionGroup | undefined = optionGroup.optionGroups?.find((optionGroup: IConsolidatedFeatureOptionGroup) => {
                return this.isSelectedOptionInGroup(optionGroup, feature);
            });

            foundOption = Boolean(foundGroup);
            if (foundOption) {
                return true;
            }

        } else {
            foundOption = false;
        }


        return foundOption;
    }

    //==================================================================================================================
    static updatedFilteredItemContextList(itemContextList: Array<IConsolidatedClientItem> | undefined, filteredItemContextList: Array<IConsolidatedClientItem>): Array<IConsolidatedClientItem> {
        let updatedFilteredItemContextList: Array<IConsolidatedClientItem> = [];

        itemContextList?.forEach((itemContext: IConsolidatedClientItem) => {
            filteredItemContextList.forEach((filteredItemContext: IConsolidatedClientItem) => {
                if (filteredItemContext.instanceId === itemContext.instanceId) {
                    updatedFilteredItemContextList.push(itemContext);
                }
            });
        });

        return updatedFilteredItemContextList;
    }

    //=======================================================================
    static filterItemsWithFeature(selectedItems: Array<IConsolidatedClientItem>, feature: IFeaturePlus): Array<IConsolidatedClientItem> {
        let actualItems: Array<IConsolidatedClientItem> = [];
        //let itemIds: Array<string> = [];

        selectedItems?.forEach((item: IConsolidatedClientItem) => {
            if (item.configurationState?.find((configuration: IConsolidatedFeatureState) => configuration.featureId === feature.id)) {
                actualItems.push(item)
            }
        });

        return actualItems;
    }

    //==================================================================================================================
    static arrayIdFromVariant(items: Array<IConsolidatedClientItem> | []): Array<string> {
        let itemIds: Array<string> = [];

        items.forEach((item: IConsolidatedClientItem) => {
            if (item.instanceId) {
                itemIds.push(item.instanceId);
            }
        });

        return itemIds.sort();
    }

    //==================================================================================================================
    static arrayIdFromFeatures(features: Array<IFeaturePlus> | []): Array<string> {
        let featureIds: Array<string> = [];

        features.forEach((feature: IFeaturePlus) => {
            //if (feature.itemUUID) {
            featureIds.push(feature.id);
            //}
        });

        return featureIds.sort();
    }

    //==============================
    static findItemsFromCatalog(itemVariants: Array<IConsolidatedClientItem> | undefined, catatogId: string | undefined): Array<IConsolidatedClientItem> | undefined {
        if (!catatogId) {
            return undefined;
        }

        let itemsFromCatalog: Array<IConsolidatedClientItem> = [];

        itemVariants?.forEach((itemVariant: IConsolidatedClientItem) => {
            if (itemVariant.itemId?.split('.')[0] === catatogId) {
                itemsFromCatalog.push(itemVariant);
            }
        });

        return itemsFromCatalog;
    }

    //=======================================================
    static validateInput(selectedFeature: IFeaturePlus | undefined, inputValue: string, measureUnit: 'Metric' | 'Imperial', measurementSystem: 'Imperial' | 'Metric' | undefined): IInputValidationResult {
        let numericalValue: number | undefined,
            error: string | undefined = undefined,
            minValue: string | number | undefined = selectedFeature?.numericalSpec?.range?.min,
            maxValue: string | number | undefined = selectedFeature?.numericalSpec?.range?.max,
            increment: string | number | undefined = selectedFeature?.numericalSpec?.range?.increment;

        inputValue = inputValue.trim();

        if (inputValue.indexOf('  ') > -1) { //managing user error - double space instead of single space    
            inputValue = inputValue.replaceAll('  ', ' ');
        }
        if (inputValue.indexOf(' .') > -1) { //managing user error - space before decimal
            inputValue = inputValue.replaceAll(' .', '.');
        }
        if (isNaN(Number(inputValue))) { //maybe it is a number with fraction
            let valid: boolean = /^-?(?!.*\.\.)(\d|\.)+(ft?|'?)?((\s?|-?|\/)(\d?|\.|\s|[^\/]+\/[^\/])+(in?|"?))?$/.test(inputValue);
            if (inputValue.indexOf('.') > -1 && inputValue.indexOf('/') > -1) valid = false;

            if (!valid) {
                error = strings['numerical.error.invalid'];
            } else {
                let matches = inputValue.match(/(-?[0-9]+)\/([0-9]+)/),
                    entier: number,
                    fraction: number;

                if (matches) {
                    fraction = Number(matches[1]) / Number(matches[2]);
                    entier = inputValue.split(' ').length === 2 ? Number(inputValue.split(' ')[0]) : 0;
                    numericalValue = fraction !== Infinity ? entier + fraction : undefined;
                }
            }
        } else {
            numericalValue = parseFloat(inputValue);
        }

        numericalValue = this.convertBackToOriginal(numericalValue as number, measureUnit, measurementSystem);

        if (numericalValue === undefined || isNaN(Number(numericalValue))) {
            numericalValue = undefined;
            error = strings['numerical.error.invalid'];
        } else if (minValue && numericalValue < Number(minValue)) {
            error = strings['numerical.error.minimum'];
        } else if (maxValue && numericalValue > Number(maxValue)) {
            error = strings['numerical.error.maximum'];
        } else if (increment && numericalValue % Number(increment) !== 0) {
            error = strings['numerical.error.increment'];
        }

        return {
            originalInput: inputValue,
            outputValue: numericalValue,
            errorOutput: error,
        } as IInputValidationResult;
    }

    //=======================================================
    static getUpdatedOption(selectedFeature: IFeaturePlus | undefined, inputValue: number): IConsolidatedFeatureOption | null {
        let updatedOption: IConsolidatedFeatureOption | null = null,
            customOption: IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup | undefined = selectedFeature?.options?.find((option: IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup) => option.id.indexOf('customOption') > -1),
            firstoption: IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup | undefined = selectedFeature?.options && selectedFeature?.options[0];

        updatedOption = (customOption || firstoption) as IConsolidatedFeatureOption;

        if (!updatedOption) {
            updatedOption = selectedFeature?.defaultOption || null;
        }
        if (updatedOption) {
            updatedOption.value = inputValue; //convert to number  
        }

        return updatedOption;
    }

    //======================================================
    static isFeatureGroup(object?: any): boolean {
        return object !== undefined && (('features' in object) || ('groups' in object));
    }

    //======================================================
    static isOptionGroup(object?: any): boolean {
        return object !== undefined && (('options' in object) || ('groups' in object));
    }
    //=============================================================================
    static debounce(funcToDebounce: Function, debounceTime: number): Function {
        let timeoutHandle: number;

        return function () {
            clearTimeout(timeoutHandle);
            timeoutHandle = setTimeout(() => funcToDebounce.apply(null, Array.from(arguments)), debounceTime) as any;
        }
    }

    //================================================================================
    /*static findOptionPrefix(actualItems: Array<IConsolidatedClientItem>, feature: IFeaturePlus | undefined): string {
        let optionPrefix = '';

        if (actualItems && actualItems.length) {
            if (feature?.selectedOption?.id.split('.')[1] === '_IS_INCLUDED' && !(feature?.selectedOption?.value === 1)) {
                optionPrefix = 'No ';
            }
        }

        return optionPrefix;
    }*/

    //=====================================================
    static findItemsWithFeature(itemsContextList: Array<IConsolidatedClientItem> | undefined, selectedFeature: IFeaturePlus | undefined): Array<IConsolidatedClientItem> {
        let actualItems: Array<IConsolidatedClientItem> = [];

        itemsContextList?.forEach((itemVariant: IConsolidatedClientItem) => {
            if (itemVariant?.configurationState?.find((configurationState) => {
                return configurationState.featureId === selectedFeature?.id;
            })) {
                actualItems.push(itemVariant);
            }
        });

        return actualItems;
    }

    //=====================================================
    static findItemsWithOption(items: Array<IConsolidatedClientItem>, selectedOption: IConsolidatedFeatureSelection): Array<IConsolidatedClientItem> {
        let actualItems: Array<IConsolidatedClientItem> = [];

        selectedOption.clientItemContexts?.forEach((clientItemContext: IConsolidatedClientItemContext) => {
            let foundItem: IConsolidatedClientItem | undefined = items.find((item: IConsolidatedClientItem) => item.instanceId === clientItemContext.instanceId);
            if (foundItem) {
                actualItems.push(foundItem);
            }
        });

        return actualItems;
    }

    //============================================================
    static splitItemsUUIDSbyCatalogId(items: Array<IConsolidatedClientItem>): Map<string, Array<string>> {
        let itemsByCatalogIdMap: Map<string, Array<string>> = new Map();

        items.forEach((item: IConsolidatedClientItem) => {
            const catalogId: string | undefined = item.itemId?.split('.')[0];

            if (item.instanceId && catalogId) {

                if (itemsByCatalogIdMap.has(catalogId)) {
                    let actualInstanceIds: Array<string> = itemsByCatalogIdMap.get(catalogId) || [];

                    actualInstanceIds?.push(item.instanceId);
                    itemsByCatalogIdMap.set(catalogId, actualInstanceIds);
                } else {
                    itemsByCatalogIdMap.set(catalogId, [item.instanceId]);
                }
            }
        });

        return itemsByCatalogIdMap;
    }

    //============================================================
    static getItemsUUIDS(items: Array<IConsolidatedClientItem>): Array<string> {
        let itemUUIDS: Array<string> = [];

        items.forEach((item: IConsolidatedClientItem) => {
            if (item.instanceId) {
                itemUUIDS.push(item.instanceId);
            }
        });
        itemUUIDS.sort();

        return itemUUIDS;
    }


    //============================================================
    static getUUIDSFromFeatures(features: Array<IFeaturePlus>): Array<string> {
        let itemUUIDS: Array<string> = [];

        Array.isArray(features) && features.forEach((feature: IFeaturePlus) => {
            if (feature.selectedOptions && feature.selectedOptions.length) {
                feature.selectedOptions.forEach((selected: IConsolidatedFeatureSelection) => {
                    selected.clientItemContexts?.forEach((item: IConsolidatedClientItemContext) => {
                        if (!itemUUIDS.includes(item.instanceId)) {
                            itemUUIDS.push(item.instanceId);
                        }
                    });
                });
            }
        });
        itemUUIDS.sort();

        return itemUUIDS;
    }

    //======================================================================
    static uniqueUUIDSNumber(features: Array<IFeaturePlus>): number {
        let uuids: Array<string> = this.getUUIDSFromFeatures(features)

        return uuids.length;
    }
    //============================================================
    static getMergeUUIDsCode(features: Array<IFeaturePlus>): string | undefined {
        let itemUUIDS: Array<string> = this.getUUIDSFromFeatures(features),
            UUIDs: string | undefined = undefined;

        if (features.length > 1) {
            itemUUIDS.forEach((UUID: string) => {
                UUIDs = UUIDs + '-';
            });
        }

        return UUIDs;
    }
    //=============================================================================================
    static searchInOptionsTree(cloneOptionTree: Array<IConsolidatedFeatureOptionGroup | IConsolidatedFeatureOption>, search: string): Array<IConsolidatedFeatureOptionGroup | IConsolidatedFeatureOption> {

        for (let i = cloneOptionTree.length - 1; i >= 0; i--) {
            let optionGroup: IConsolidatedFeatureOptionGroup = cloneOptionTree[i] as IConsolidatedFeatureOptionGroup,
                isGroup: boolean = optionGroup !== undefined && Array.isArray(optionGroup?.optionGroups) || Array.isArray(optionGroup?.options);

            if (!isGroup && !cloneOptionTree[i].name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                //remove option that don't match search filter
                cloneOptionTree.splice(i, 1);
            }

            if (isGroup) {
                // check children
                for (let j = (optionGroup.options?.length || 0) - 1; j >= 0; j--) {
                    if (!optionGroup.options![j].name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                        optionGroup.options?.splice(j, 1);
                    }
                }
                // check groups
                let foundInSubTree: Array<IConsolidatedFeatureOptionGroup | IConsolidatedFeatureOption> = this.searchInOptionsTree(optionGroup.optionGroups || [], search);

                optionGroup.optionGroups = foundInSubTree;

                if (optionGroup.options?.length === 0 && optionGroup.optionGroups.length === 0) {
                    //clear empty group
                    cloneOptionTree.splice(i, 1);
                }
            }
        }

        return cloneOptionTree;
    }

    //=============================================================================================
    static searchInSubItemsTree(subItemsTree: Array<IFeatureGroupPlus | IFeaturePlus>, search: string, selectedFeature?: IFeaturePlus): Array<IFeatureGroupPlus | IFeaturePlus> {

        for (let i = subItemsTree.length - 1; i >= 0; i--) {
            let subItemGroup = subItemsTree[i] as IFeatureGroupPlus,
                isGroup: boolean = subItemGroup !== undefined && Array.isArray(subItemGroup?.featureGroups) || Array.isArray(subItemGroup?.features);

            if (!isGroup && !subItemsTree[i].name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                if (!selectedFeature || (selectedFeature && selectedFeature.id !== subItemsTree[i].id)) {
                    //remove option that don't match search filter
                    subItemsTree.splice(i, 1);
                }
            }

            if (isGroup) {
                // check children
                for (let j = (subItemGroup.features?.length || 0) - 1; j >= 0; j--) {
                    if (!subItemGroup.features![j].name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                        if (!selectedFeature || (selectedFeature && selectedFeature.id !== subItemGroup.features![j].id)) {
                            subItemGroup.features?.splice(j, 1);
                        }
                    }
                }
                // check groups
                let foundInSubTree: Array<IFeatureGroupPlus | IFeaturePlus> = this.searchInSubItemsTree(subItemGroup.featureGroups || [], search, selectedFeature);

                subItemGroup.featureGroups = foundInSubTree;

                if (subItemGroup.features?.length === 0 && subItemGroup.featureGroups.length === 0) {
                    //clear empty group
                    subItemsTree.splice(i, 1);
                }
            }
        }

        return subItemsTree;
    }

    //=============================================================================================
    static searchModifiedInSubItemsTree(subItemsTree: Array<IFeatureGroupPlus | IFeaturePlus>): Array<IFeatureGroupPlus | IFeaturePlus> {

        for (let i = subItemsTree.length - 1; i >= 0; i--) {
            let subItemGroup = subItemsTree[i] as IFeatureGroupPlus,
                isGroup: boolean = subItemGroup !== undefined && Array.isArray(subItemGroup?.featureGroups) || Array.isArray(subItemGroup?.features);

            if (!isGroup && (subItemsTree[i] as IFeaturePlus)?.selectionType === 'singleSelect' &&
                (subItemsTree[i] as IFeaturePlus).defaultOption?.id === (subItemsTree[i] as IFeaturePlus).selectedOptions![0]?.option.id) {
                //remove option that don't match search filter
                subItemsTree.splice(i, 1);
            } else if (!isGroup && (subItemsTree[i] as IFeaturePlus)?.selectionType === 'customNumeric' &&
                (subItemsTree[i] as IFeaturePlus).defaultOption?.value === (subItemsTree[i] as IFeaturePlus).selectedOptions![0]?.option?.value) {
                //remove option that don't match search filter
                subItemsTree.splice(i, 1);
            } else if (!isGroup && (subItemsTree[i] as IFeaturePlus)?.selectionType === 'toggle' &&
                (subItemsTree[i] as IFeaturePlus).defaultOption?.name === (subItemsTree[i] as IFeaturePlus).selectedOptions![0]?.option?.name) {
                //remove option that don't match search filter
                subItemsTree.splice(i, 1);
            }

            if (isGroup) {
                // check children
                for (let j = (subItemGroup.features?.length || 0) - 1; j >= 0; j--) {
                    let subFeature: IFeaturePlus | undefined = subItemGroup.features && subItemGroup.features[j];
                    if (subFeature?.selectionType === 'singleSelect' && subFeature &&
                        subFeature.defaultOption?.id === subFeature.selectedOptions![0]?.option?.id) {
                        subItemGroup.features?.splice(j, 1);
                    } else if (subItemGroup.features?.length && subItemGroup.features![j]?.selectionType === 'customNumeric' && subFeature &&
                        subItemGroup.features![j].defaultOption?.value === subFeature.selectedOptions![0]?.option.value) {
                        subItemGroup.features?.splice(j, 1);
                    } else if (subItemGroup.features?.length && subItemGroup.features![j]?.selectionType === 'toggle' && subFeature &&
                        subFeature.defaultOption?.name === subFeature.selectedOptions![0].option.name) {
                        subItemGroup.features?.splice(j, 1);
                    }
                }
                // check groups
                let foundInSubTree: Array<IFeatureGroupPlus | IFeaturePlus> = this.searchModifiedInSubItemsTree(subItemGroup.featureGroups || []);

                subItemGroup.featureGroups = foundInSubTree;

                if (subItemGroup.features?.length === 0 && subItemGroup.featureGroups.length === 0) {
                    //clear empty group
                    subItemsTree.splice(i, 1);
                }
            }
        }

        return subItemsTree;
    }

    //=============================================================================================
    static modifyfeatureInSubItemsTree(subItemsTree: Array<IFeatureGroupPlus | IFeaturePlus>): boolean {
        let wasModify: boolean = false;

        for (let i = subItemsTree.length - 1; i >= 0; i--) {
            let subItemGroup: IFeatureGroupPlus = subItemsTree[i] as IFeatureGroupPlus,
                isGroup: boolean = subItemGroup !== undefined && Array.isArray(subItemGroup?.featureGroups) || Array.isArray(subItemGroup?.features);

            if (!isGroup && (subItemsTree[i] as IFeaturePlus).defaultOption?.id !== (subItemsTree[i] as IFeaturePlus).selectedOptions![0]?.option.id) {
                wasModify = true;
            }

            if (isGroup && !wasModify) {
                // check children
                for (let j = (subItemGroup.features?.length || 0) - 1; j >= 0; j--) {
                    if (subItemGroup.features![j].defaultOption?.id !== subItemGroup.features![j].selectedOptions![0].option.id) {
                        wasModify = true;
                    }
                }
                if (!wasModify) {
                    // check groups
                    wasModify = this.modifyfeatureInSubItemsTree(subItemGroup.featureGroups || []);
                }
            }
        }

        return wasModify;
    }

    //=============================================================================================
    static featureTreeHasVisibleFeature(featureTree: Array<IFeatureGroupPlus | IFeaturePlus>): boolean {
        let hasVisible: boolean = false;

        for (let i = featureTree.length - 1; i >= 0; i--) {
            let featureGroup: IFeatureGroupPlus = featureTree[i] as IFeatureGroupPlus,
                isGroup: boolean = featureGroup !== undefined && Array.isArray(featureGroup?.featureGroups) || Array.isArray(featureGroup?.features);

            if (!isGroup && (featureTree[i] as IFeaturePlus).visible === true) {
                hasVisible = true;
                break;
            }

            if (isGroup && !hasVisible) {
                // check children
                for (let j = (featureGroup.features?.length || 0) - 1; j >= 0; j--) {
                    if (featureGroup.features![j].visible === true) {
                        hasVisible = true;
                        break;
                    }
                }
                if (!hasVisible) {
                    // check groups
                    hasVisible = this.featureTreeHasVisibleFeature(featureGroup.featureGroups || []);
                }
            }
        }

        return hasVisible;
    }

    //======================================================================
    static activateHandlePosition(doorOptions: Array<IConsolidatedFeatureOption>, orientation: string, positionHorizontal: string, positionVertical: string): boolean {

        if (Boolean(doorOptions.find((option: IConsolidatedFeatureOption) => {
            return (
                option.classification?.characteristics?.orientation === orientation &&
                option.classification?.characteristics?.positionHorizontal === positionHorizontal &&
                option.classification?.characteristics?.positionVertical === positionVertical
            )
        }))) {
            return true;
        } else {
            return false;
        }
    }

    //=====================================================================
    static optionToHandlePosition(selectedOption: IConsolidatedFeatureOption | string | undefined): handlePosition {
        let relatedPosition: handlePosition = 'topCenter',
            characteristics = selectedOption && selectedOption as IConsolidatedFeatureOption ? (selectedOption as IConsolidatedFeatureOption).classification?.characteristics : undefined;

        if (characteristics) {

            switch (true) {
                /*case selectedOption.name.indexOf('No Pull') > -1:
                    relatedPosition = 'none';
                    break;
                */
                case characteristics?.orientation === 'horizontal' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'top':
                    relatedPosition = 'topRight';
                    break;
                case characteristics?.orientation === 'vertical' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'top':
                    relatedPosition = 'topVertRight';
                    break;
                case characteristics?.orientation === 'diagonalRight' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'top':
                    relatedPosition = 'topRightDiagoRight';
                    break;
                case characteristics?.orientation === 'diagonalLeft' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'top':
                    relatedPosition = 'topRightDiagoLeft';
                    break;
                case characteristics?.orientation === 'horizontal' &&
                    characteristics?.positionHorizontal === 'center' &&
                    characteristics?.positionVertical === 'top':
                    relatedPosition = 'topCenter';
                    break;
                case characteristics?.orientation === 'vertical' &&
                    characteristics?.positionHorizontal === 'center' &&
                    characteristics?.positionVertical === 'top':
                    relatedPosition = 'topVertCenter';
                    break;

                case characteristics?.orientation === 'horizontal' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'middle':
                    relatedPosition = 'centerRight';
                    break;
                case characteristics?.orientation === 'vertical' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'middle':
                    relatedPosition = 'centerVertRight';
                    break;
                case characteristics?.orientation === 'diagonalRight' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'middle':
                    relatedPosition = 'centerRightDiagoRight';
                    break;
                case characteristics?.orientation === 'diagonalLeft' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'middle':
                    relatedPosition = 'centerRightDiagoLeft';
                    break;
                case characteristics?.orientation === 'horizontal' &&
                    characteristics?.positionHorizontal === 'center' &&
                    characteristics?.positionVertical === 'middle':
                    relatedPosition = 'centerCenter';
                    break;
                case characteristics?.orientation === 'vertical' &&
                    characteristics?.positionHorizontal === 'center' &&
                    characteristics?.positionVertical === 'middle':
                    relatedPosition = 'centerVertCenter';
                    break;
                case characteristics?.orientation === 'diagonalRight' &&
                    characteristics?.positionHorizontal === 'center' &&
                    characteristics?.positionVertical === 'middle':
                    relatedPosition = 'centerCenterDiagoRight';
                    break;
                case characteristics?.orientation === 'diagonalLeft' &&
                    characteristics?.positionHorizontal === 'center' &&
                    characteristics?.positionVertical === 'middle':
                    relatedPosition = 'centerCenterDiagoLeft';
                    break;

                case characteristics?.orientation === 'horizontal' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'bottom':
                    relatedPosition = 'bottomRight';
                    break;
                case characteristics?.orientation === 'vertical' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'bottom':
                    relatedPosition = 'bottomVertRight';
                    break;
                case characteristics?.orientation === 'diagonalRight' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'bottom':
                    relatedPosition = 'bottomRightDiagoRight';
                    break;
                case characteristics?.orientation === 'diagonalLeft' &&
                    characteristics?.positionHorizontal === 'side' &&
                    characteristics?.positionVertical === 'bottom':
                    relatedPosition = 'bottomRightDiagoLeft';
                    break;
                case characteristics?.orientation === 'horizontal' &&
                    characteristics?.positionHorizontal === 'center' &&
                    characteristics?.positionVertical === 'bottom':
                    relatedPosition = 'bottomCenter';
                    break;
                case characteristics?.orientation === 'vertical' &&
                    characteristics?.positionHorizontal === 'center' &&
                    characteristics?.positionVertical === 'bottom':
                    relatedPosition = 'bottomVertCenter';
                    break;
            }
        } else {
            relatedPosition = 'none';
        }

        return relatedPosition;
    }

    //=================================================================
    static handlePositionToOption(relatedPosition: handlePosition, options: Array<IConsolidatedFeatureOption>): IConsolidatedFeatureOption | undefined {
        let relatedOption: IConsolidatedFeatureOption | undefined = undefined;

        switch (relatedPosition) {
            case 'topRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'horizontal' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'top'
                    );
                });
                break;
            case 'topVertRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'vertical' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'top'
                    );
                });
                break;
            case 'topRightDiagoRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'diagonalRight' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'top'
                    );
                });
                break;
            case 'topRightDiagoLeft':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'diagonalLeft' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'top'
                    );
                });
                break;
            case 'topCenter':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'horizontal' &&
                        option.classification?.characteristics?.positionHorizontal === 'center' &&
                        option.classification?.characteristics?.positionVertical === 'top'
                    );
                });
                break;
            case 'topVertCenter':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'vertical' &&
                        option.classification?.characteristics?.positionHorizontal === 'center' &&
                        option.classification?.characteristics?.positionVertical === 'top'
                    );
                });
                break;


            case 'centerRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'horizontal' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'middle'
                    );
                });
                break;
            case 'centerVertRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'vertical' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'middle'
                    );
                });
                break;
            case 'centerRightDiagoRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'diagonalRight' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'middle'
                    );
                });
                break;
            case 'centerRightDiagoLeft':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'diagonalLeft' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'middle'
                    );
                });
                break;
            case 'centerCenter':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'horizontal' &&
                        option.classification?.characteristics?.positionHorizontal === 'center' &&
                        option.classification?.characteristics?.positionVertical === 'middle'
                    );
                });
                break;
            case 'centerVertCenter':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'vertical' &&
                        option.classification?.characteristics?.positionHorizontal === 'center' &&
                        option.classification?.characteristics?.positionVertical === 'middle'
                    );
                });
                break;
            case 'centerCenterDiagoRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'diagonalRight' &&
                        option.classification?.characteristics?.positionHorizontal === 'center' &&
                        option.classification?.characteristics?.positionVertical === 'middle'
                    );
                });
                break;
            case 'centerCenterDiagoLeft':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'diagonalLeft' &&
                        option.classification?.characteristics?.positionHorizontal === 'center' &&
                        option.classification?.characteristics?.positionVertical === 'middle'
                    );
                });
                break;

            case 'bottomRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'horizontal' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'bottom'
                    );
                });
                break;
            case 'bottomVertRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'vertical' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'bottom'
                    );
                });
                break;
            case 'bottomRightDiagoRight':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'diagonalRight' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'bottom'
                    );
                });
                break;
            case 'bottomRightDiagoLeft':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'diagonalLeft' &&
                        option.classification?.characteristics?.positionHorizontal === 'side' &&
                        option.classification?.characteristics?.positionVertical === 'bottom'
                    );
                });
                break;
            case 'bottomCenter':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'horizontal' &&
                        option.classification?.characteristics?.positionHorizontal === 'center' &&
                        option.classification?.characteristics?.positionVertical === 'bottom'
                    );
                });
                break;
            case 'bottomVertCenter':
                relatedOption = options.find((option: IConsolidatedFeatureOption) => {
                    return (
                        option.classification?.characteristics?.orientation === 'vertical' &&
                        option.classification?.characteristics?.positionHorizontal === 'center' &&
                        option.classification?.characteristics?.positionVertical === 'bottom'
                    );
                });
                break;
        }

        return relatedOption;
    }

    //======================================================================
    static showTabContent(targetTab: string, activeMenu: menuItem | undefined, previousMenu: menuItem, hasConfigurableItem: boolean, multiTabsEnabled: boolean, allowMultiItems: boolean, hasFeatures?: boolean): boolean {
        if (!allowMultiItems || !hasFeatures) {
            return false;
        }
        if (multiTabsEnabled) {
            return true;
        }
        if (activeMenu && (activeMenu.path === targetTab || activeMenu.path === 'setting' && previousMenu.path === targetTab) && hasConfigurableItem) {
            return true;
        }

        return false;
    }

    //======================================================================
    static findActiveTabFeatures(allFeatures: Array<IFeaturePlus | IFeatureGroupPlus>, activeMenu: string): Array<IFeaturePlus | IFeatureGroupPlus> {
        let activeFeaures: Array<IFeaturePlus> = [];

        if (activeMenu === 'style') {
            return this.searchInTree(allFeatures, 'style');

        } else if (activeMenu === 'subItems') {
            return this.searchInTree(allFeatures, 'subItem');

        } else if (activeMenu === 'dimension') {
            return this.searchInTree(allFeatures, 'dimension');

        } else if (activeMenu === 'positioning') {
            return this.searchInTree(allFeatures, 'positioning');

        } else if (activeMenu === 'opening') {
            return this.searchInTree(allFeatures, 'opening');

        } else if (activeMenu === 'alternative') {
            return this.searchInTree(allFeatures, 'alternative'); //todo

        } else if (activeMenu === 'light') {
            return this.searchInTree(allFeatures, 'lighting');
        }

        return allFeatures;
    }

    //======================================================================
    static splitFeatureByCatalog(features: Array<IFeaturePlus | IFeatureGroupPlus>): Map<string, Array<IFeaturePlus | IFeatureGroupPlus>> {
        let featuresByCatalogIdMap: Map<string, Array<IFeaturePlus | IFeatureGroupPlus>> = new Map();

        features.forEach((feature: IFeaturePlus | IFeatureGroupPlus) => {
            const catalogId: string = feature.id.split('.')[0];

            if (featuresByCatalogIdMap.has(catalogId)) {
                let actualFeatures: Array<IFeaturePlus | IFeatureGroupPlus> = featuresByCatalogIdMap.get(catalogId) || [];

                actualFeatures?.push(feature);
                featuresByCatalogIdMap.set(catalogId, actualFeatures);
            } else {
                featuresByCatalogIdMap.set(catalogId, [feature]);
            }
        });

        return featuresByCatalogIdMap;
    }

    //=========================================================================
    static findSelectedFeature(menuItem: menuItem, features: { style: IFeaturePlus | IFeatureGroupPlus, subItem: IFeaturePlus | IFeatureGroupPlus, position: IFeaturePlus | IFeatureGroupPlus, opening: IFeaturePlus | IFeatureGroupPlus, dimension: IFeaturePlus | IFeatureGroupPlus, light: IFeaturePlus | IConsolidatedFeatureGroup }): IFeaturePlus {
        let featureSelected: IFeaturePlus;

        switch (menuItem.path) {
            case 'style':
                featureSelected = this.findFirstFeatureInTree(features.style);
                break;
            case 'subItem':
                featureSelected = this.findFirstFeatureInTree(features.subItem);
                break;
            case 'position':
                featureSelected = this.findFirstFeatureInTree(features.position);
                break;
            case 'opening':
                featureSelected = this.findFirstFeatureInTree(features.opening);
                break;
            case 'dimension':
                featureSelected = this.findFirstFeatureInTree(features.dimension);
                break;
            case 'light':
                featureSelected = this.findFirstFeatureInTree(features.light);
                break;
            default:
                featureSelected = this.findFirstFeatureInTree(features.style);
        }

        return featureSelected;
    }

    //=============================================================================================
    static searchInTree(featureTree: Array<IFeatureGroupPlus | IFeaturePlus>, search: string): Array<IFeatureGroupPlus | IFeaturePlus> {
        let cloneFeatureTree = JSON.parse(JSON.stringify(featureTree));

        for (let i = cloneFeatureTree.length - 1; i >= 0; i--) {
            let subItemGroup = cloneFeatureTree[i] as IFeatureGroupPlus,
                isGroup: boolean = subItemGroup !== undefined && Array.isArray(subItemGroup?.featureGroups) || Array.isArray(subItemGroup?.features);

            if (!isGroup && (cloneFeatureTree[i] as IFeaturePlus).classification?.baseFeatureType !== search) {
                //remove option that don't match search filter
                cloneFeatureTree.splice(i, 1);
            }

            if (isGroup) {
                // check children
                for (let j = (subItemGroup.features?.length || 0) - 1; j >= 0; j--) {
                    if (subItemGroup.features![j].classification?.baseFeatureType !== search) {
                        subItemGroup.features?.splice(j, 1);
                    }
                }
                // check groups
                let foundInSubTree: Array<IFeatureGroupPlus | IFeaturePlus> = this.searchInTree(subItemGroup.featureGroups || [], search);

                subItemGroup.featureGroups = foundInSubTree;

                if (subItemGroup.features?.length === 0 && subItemGroup.featureGroups.length === 0) {
                    //clear empty group
                    cloneFeatureTree.splice(i, 1);
                }
                //if (subItemGroup.featureGroups.length === 0) {
                //    delete subItemGroup.featureGroups;
                //}
            }
        }

        return cloneFeatureTree;
    }


    //=======================================================================
    static findFirstOption(options: Array<IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup>): IConsolidatedFeatureOption | undefined {
        let firstOption: IConsolidatedFeatureOption | undefined = undefined;

        options.forEach((option: IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup) => {
            if (option && (option as IConsolidatedFeatureOptionGroup).options) {
                firstOption = this.findFirstOption((option as IConsolidatedFeatureOptionGroup).options || []);
            } else {
                firstOption = option as IConsolidatedFeatureOption;
            }
        });

        return firstOption;
    }

    //=======================================================================
    static findIsToggleOnly(featureOptionList: Array<IConsolidatedFeatureOption> | undefined): boolean {

        let options: Array<IConsolidatedFeatureOptionGroup | IConsolidatedFeatureOption> | undefined = featureOptionList?.filter((option: IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup) => option.id.split('.')[1] !== '_IS_INCLUDED' && option.id.split('.')[1] !== 'none');

        if (!options) {
            return false;
        } else if (options.length === 1 && !(options[0] as IConsolidatedFeatureOptionGroup).optionGroups) {
            //only one other Option than 'is_included', so it is a real Toggle.
            return true;
        } else {
            return false;
        }
    }

    //=======================================================================
    static isCheckboxChecked(feature: IFeaturePlus): boolean {

        if (feature?.selectedOptions && (feature?.selectedOptions[0].option.id.split('.')[1] === '_IS_INCLUDED' && feature?.selectedOptions[0].option.value === 1 ||
            feature?.selectedOptions[0].option.id.split('.')[1] !== '_IS_INCLUDED' && !Boolean(feature?.options?.find((option: IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup) => option?.id.split('.')[1] === 'none')) ||
            feature?.selectedOptions[0]?.option.id.split('.')[1] !== 'none' && Boolean(feature?.options?.find((option: IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup) => option?.id.split('.')[1] === 'none')))) {
            return true;
        } else {
            return false;
        }
    }

    //=======================================================================
    static filterEditingItemsInFeature(selectedItems: Array<IConsolidatedClientItem>, selectedOptions: IConsolidatedFeatureSelection[] | undefined): Array<IConsolidatedClientItem> {
        let actualItems: Array<IConsolidatedClientItem> = [];
        let itemIds: Array<string> = [];

        selectedOptions?.forEach((selection: IConsolidatedFeatureSelection) => {
            selection.clientItemContexts?.forEach((item: IConsolidatedClientItemContext) => {
                itemIds.push(item.instanceId);
            });
        });

        actualItems = selectedItems?.filter((item: IConsolidatedClientItem) => {
            return item.instanceId && itemIds.includes(item.instanceId);
        });

        return actualItems;
    }
    //=======================================================================
    static findItemsFromSelection(selectedItems: Array<IConsolidatedClientItem> | undefined, selectedOption: IConsolidatedFeatureSelection | undefined): Array<IConsolidatedClientItem> {
        let actualItems: Array<IConsolidatedClientItem> = [];

        if (selectedOption) {
            actualItems = !selectedItems ? [] : selectedItems?.filter((item: IConsolidatedClientItem) => {
                return selectedOption.clientItemContexts?.find((itemInfo: IConsolidatedClientItemContext) => {
                    return itemInfo.instanceId === item.instanceId;
                });
            });
        }

        return actualItems;
    }
    //=======================================================================
    static buildDropDownLabel(feature: IFeaturePlus, checkboxChecked: boolean): string {
        let label: string = '';

        if (checkboxChecked) {
            if (feature.selectedOptions && feature.selectedOptions.every((selection: IConsolidatedFeatureSelection) => selection.option.name === feature.selectedOptions![0].option.name)) {
                label = feature?.selectedOptions[0].option.name;

            } else if (feature.selectedOptions && feature.selectedOptions.length === 1) {
                label = feature.selectedOptions[0].option.name;

            } else if (feature.selectedOptions && feature.selectedOptions.length > 1) {
                label = strings['subitems.options.mix-values'];
            } else {
                label = 'Select ';
            }
        } else {
            label = ' '
        }
        return label;
    }

    //=======================================================================
    static buildNumericalLabel(feature: IFeaturePlus): string | number | undefined {
        let label: string | number | undefined = undefined;

        if (feature.selectedOptions?.length === 1 || feature.selectedOptions?.every((selection: IConsolidatedFeatureSelection) => selection.option.value === feature.selectedOptions![0].option.value)) {
            label = feature?.selectedOptions[0].option.value;

        } else if (feature.selectedOptions?.length && feature.selectedOptions?.length > 1) {
            label = strings['numerical.options.mix-values'];
        }

        return label;
    }

    //==============================================================================
    static filteringFeatures(allFeatures: Array<IFeaturePlus | IFeatureGroupPlus>, search: string, selectedFeatureId: string | undefined): Array<IFeaturePlus | IFeatureGroupPlus> {
        let featuresFound: Array<IFeaturePlus | IFeatureGroupPlus> = [],
            cloneAllFeaures: Array<IFeaturePlus | IFeatureGroupPlus> = JSON.parse(JSON.stringify(allFeatures));

        cloneAllFeaures.forEach((feature: IFeaturePlus | IFeatureGroupPlus) => {
            if ((feature as IFeatureGroupPlus).features) {
                (feature as IFeatureGroupPlus).features?.forEach((intFeature: IConsolidatedFeature, index: number) => {
                    if (intFeature.name?.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) === -1 &&
                        intFeature.id !== selectedFeatureId) {
                        (feature as IFeatureGroupPlus).features?.splice(index, 1);
                    }
                });

                if ((feature as IFeatureGroupPlus).features?.length) {
                    featuresFound.push(feature);
                }

            } else if (feature.name?.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
                feature.id === selectedFeatureId) {
                featuresFound.push(feature);
            }
        });

        return featuresFound;
    }

    //==============================
    static findNbrItemsFromCatalog(itemVariants: Array<IConsolidatedClientItem> | undefined, catatogId: string | undefined): number | undefined {
        if (!catatogId) {
            return undefined;
        }

        let nbrItemsFromCatalog: number = 0;

        itemVariants?.forEach((itemVariant: IConsolidatedClientItem) => {
            if (itemVariant.itemId?.split('.')[0] === catatogId) {
                nbrItemsFromCatalog++;
            }
        });

        return nbrItemsFromCatalog;
    }

    //======================================================
    static cssVar(name: string, value?: string): string {
        if (name[0] != '-') name = '--' + name; //allow passing with or without --
        if (value) document.documentElement.style.setProperty(name, value);
        return getComputedStyle(document.documentElement).getPropertyValue(name);
    }

    //=====================================
    static nbrVisibleCatalogs(allCatalogs: Array<ICatalogPlus>): number {
        return allCatalogs.filter((catalog: ICatalogPlus) => catalog.isVisible === true).length;
    }

    //===============================================================================
    static createOpeningsOptionArray(features: Array<IFeaturePlus>, items: IConsolidatedClientItem[]): Array<Array<string>> {
        let doorsArray: Array<Array<string>> = [];

        if (features.length > 1 && items.length === 1) {
            features.forEach((feature: IFeaturePlus, index: number) => {
                let featureName = feature.name;
                if (feature.name.indexOf('All') > -1) {
                    featureName = 'All';
                }
                doorsArray.push([feature.id + (feature.id !== 'all' ? '__' + index : ''), featureName + ' ' + (feature.id !== 'all' ? index + 1 : '')]);
            });
        }

        return doorsArray;
    }

    //==============================================================================
    static mergeOpeningsFeatures(features: Array<IFeaturePlus>, featureType: string, filteredItems: IConsolidatedClientItem[]): Array<IFeaturePlus> {
        if (features.length <= 1 || filteredItems.length !== 1) {
            return features;
        }

        let mergeFeature: IFeaturePlus = { ...features[0] },
            mergeValue: number = 0,
            mergeSelected: number = 0,
            mergeMax: number = 0;

        features = features.filter((feature: IFeaturePlus) => feature.id !== 'all');

        features.forEach((feature) => {
            if (Number(feature.defaultOption?.value) !== mergeValue) {
                mergeValue = feature.defaultOption?.value as number;
            }
            /*if (Number(feature!.selectedOptions!.value || 0) > mergeSelected) {
                mergeSelected = feature.selectedOptions?.value as number;
            }*/
            if (Number(feature.numericalSpec?.range?.max || 60) > mergeMax) {
                mergeMax = feature.numericalSpec?.range?.max as number || mergeMax;
            }
        });

        mergeFeature.id = 'all';
        //mergeFeature.itemUUID = features[0].itemUUID;
        mergeFeature.name = 'All ' + (featureType === 'Door' ? 'doors' : featureType === 'Drawer' ? 'drawers' : ' openings');
        mergeFeature.path = 'all';
        mergeFeature.defaultOption = Object.assign({}, mergeFeature.defaultOption, { value: mergeValue });
        mergeFeature.numericalSpec = Object.assign({}, mergeFeature.numericalSpec, { max: mergeMax });
        mergeFeature.selectedOptions = Object.assign({}, mergeFeature.selectedOptions, { value: mergeSelected });
        //const itemUUID = features[0].itemUUID;

        //if (features.every((feature: IFeaturePlus) => feature.itemUUID === itemUUID)) {
        features.push(mergeFeature);
        //} else {
        //    features = [];
        //    features.push(mergeFeature);
        //}

        return features;
    }

    //==========================================
    static hasFeaturesChanged(previousFeatures: Array<IFeaturePlus> | [] | undefined, newFeatures: Array<IFeaturePlus>): boolean {
        previousFeatures = previousFeatures?.filter((feature: IFeaturePlus) => feature.id !== 'all')

        let newIDs: Array<string> = Helpers.arrayIdFromFeatures(newFeatures),
            oldIDs: Array<string> = Helpers.arrayIdFromFeatures(previousFeatures || []);

        if (!(newIDs.every((id: string) => oldIDs.includes(id)) && oldIDs.every((id: string) => newIDs.includes(id)))) {
            return true; //not all the same IDs... so did change
        } else {
            return false; //same feature
        }
    }

    //==========================================
    static hasFeaturesValuesChanged(previousFeatures: Array<IFeaturePlus> | [] | undefined, newFeatures: Array<IFeaturePlus>): boolean {
        let sameValues: boolean = true;

        previousFeatures = previousFeatures?.filter((feature: IFeaturePlus) => feature.id !== 'all')

        newFeatures.forEach((feature: IFeaturePlus) => {
            let twinFeature: IFeaturePlus | undefined = previousFeatures?.find((oldFeature: IFeaturePlus) => {
                return oldFeature.id === feature.id;
            });
            if (sameValues && twinFeature && feature.selectedOptions && twinFeature.selectedOptions) {
                if (twinFeature.selectedOptions.length !== feature.selectedOptions.length) {
                    sameValues = false; //different length... not the same !
                }
                if (sameValues) {
                    for (var i = 0; i < feature.selectedOptions.length; i++) {
                        if (twinFeature.selectedOptions[i].option.id !== feature.selectedOptions[i].option.id ||
                            twinFeature.selectedOptions[i].option.value !== feature.selectedOptions[i].option.value ||
                            twinFeature.selectedOptions[i].option.name !== feature.selectedOptions[i].option.name) {

                            sameValues = false; //diffetrent values... not the same !
                            break;
                        }
                    }
                }
            }
        });

        return !sameValues;
    }

    //==============================================================================
    static mergeLightsFeatures(features: Array<IFeaturePlus>, featureType: 'Intensity' | 'Color'): Array<IFeaturePlus> {
        if (features.length <= 1) {
            return features;
        }

        let mergeFeature: IFeaturePlus = { ...features[0] },
            mergeValue: number = 0,
            mergeSelected: number = 0,
            mergeMax: number = 0;

        features = features.filter((feature: IFeaturePlus) => feature.id !== 'all');

        /*features.forEach((feature) => {
            if (Number(feature.defaultOption?.value) !== mergeValue) {
                mergeValue = feature.defaultOption?.value as number;
            }
            if (Number(feature!.selectedOptions!.value || 0) > mergeSelected) {
                mergeSelected = feature.selectedOptions?.value as number;
            }
            if (Number(feature.numericalSpec?.range?.max || 60) > mergeMax) {
                mergeMax = feature.numericalSpec?.range?.max as number || mergeMax;
            }
        });*/

        mergeFeature.name = strings['opening.all'] + featureType.toLocaleLowerCase() + 's';
        mergeFeature.id = 'all';
        //mergeFeature.itemUUID = featureType.toLocaleLowerCase();
        mergeFeature.defaultOption = Object.assign({}, mergeFeature.defaultOption, { value: mergeValue });
        mergeFeature.numericalSpec = Object.assign({}, mergeFeature.numericalSpec, { max: mergeMax });
        mergeFeature.selectedOptions = Object.assign({}, mergeFeature.selectedOptions, { value: mergeSelected });
        //const itemUUID = features[0].itemUUID;

        //if (features.every((feature: IFeaturePlus) => feature.itemUUID === itemUUID)) {
        features.push(mergeFeature);
        //} else {
        //features = [];
        //features.push(mergeFeature);
        //}

        return features;
    }

    //===============================================================================
    static createLightsOptionArray(features: Array<IFeaturePlus>, lightType: string): Array<Array<string>> {
        let lightsArray: Array<Array<string>> = [];

        if (features.length > 1) {
            features.forEach((feature: IFeaturePlus, index: number) => {
                lightsArray.push([feature.id, feature.name + ' ' + (feature.id !== 'all' ? index + 1 : '')]);
            });
            //doorsArray.push(['all' + openingType, 'All ' + openingType]);      
        }

        return lightsArray;
    }

    //===============================================================================
    static saveFacets(items: Array<IConsolidatedClientItem> | undefined, feature: IFeaturePlus, facets: Array<IFacet>): void {
        if (items && this.findPreviousFacets(items, feature) && this.findPreviousFacets(items, feature).length === 0) {

            if (items && items.length > 0) {
                facetsByFeatures.set(items[0].itemId + '__' + feature.id, facets);
            }
        }
    }

    //===============================================================================
    static updateSelectedFacets(items: Array<IConsolidatedClientItem> | undefined, feature: IFeaturePlus, filteredFacet: NullableFacetValuesMap): void {
        if (items && items.length > 0) {
            selectedFacetsByFeatures.set(items[0].itemId + '__' + feature.id, filteredFacet);
        }
    }

    //===============================================================================
    static updatePersistedSubItems(item: IConsolidatedClientItem, persistedChoice: PersistSubItems): void {
        if (item && item.instanceId) {
            persistedSubItemsByItem.set(item.instanceId, persistedChoice);
        }
    }
    //===============================================================================
    static getPersistedSubItems(item: IConsolidatedClientItem): PersistSubItems {
        let persistedSubItem: PersistSubItems | undefined = item.instanceId ? persistedSubItemsByItem.get(item.instanceId) : new Map();

        return persistedSubItem || new Map();
    }

    //======================================================================
    static findDisabledMenus(allFeatures: Array<IFeaturePlus | IFeatureGroupPlus>, selectedItems: Array<IConsolidatedClientItem>, menus: menuItem[], hideLightTab: boolean, alternativeItems: number, alternativeDimension: number): Array<menuItem> {
        let disabledMenu: Array<menuItem> = [];

        if (!allFeatures.length || Helpers.searchInTree(allFeatures, 'style').length === 0) { //allowed more than one
            disabledMenu.push(menus[0]);
        }
        if (!allFeatures.length || Helpers.searchInTree(allFeatures, 'subItem').length === 0) {
            disabledMenu.push(menus[1]);
        }
        if (!allFeatures.length || (Helpers.searchInTree(allFeatures, 'dimension').length === 0 && alternativeDimension === 0) || selectedItems.length !== 1) {
            disabledMenu.push(menus[2]);
        }
        if (!allFeatures.length || Helpers.searchInTree(allFeatures, 'opening').length === 0) { //allowed more than one
            disabledMenu.push(menus[3]);
        }
        if (!allFeatures.length || Helpers.searchInTree(allFeatures, 'positioning').length === 0) {
            disabledMenu.push(menus[4]);
        }
        if (allFeatures.length === 0 || selectedItems.length === 0 || selectedItems.length > 1 || alternativeItems === 0) {
            const alternativeMenu: menuItem | undefined = menus.find((menu: menuItem) => menu.path === 'alternative');
            if (alternativeMenu) disabledMenu.push(alternativeMenu);
        }
        if (!allFeatures.length || !hideLightTab && (Helpers.searchInTree(allFeatures, 'lighting').length === 0 || selectedItems.length !== 1)) {
            const lightMenu: menuItem | undefined = menus.find((menu: menuItem) => menu.path === 'light');
            if (lightMenu) disabledMenu.push(lightMenu);
        }
        if (!allFeatures.length && alternativeItems === 0) {
            const settingsMenu: menuItem | undefined = menus.find((menu: menuItem) => menu.path === 'setting');
            if (settingsMenu) disabledMenu.push(settingsMenu);
        }
        return disabledMenu;
    }

    //=====================================================================================
    static needToFetchOptions(featureSelected: IFeaturePlus | undefined, menuItem: menuItem | undefined, itemsUUID: Array<string>, currentSelectedItemsUUID: Array<string>, currentSelectedFeatureRefId: string | undefined): boolean {

        if (!featureSelected || menuItem?.path === 'subItem' || menuItem?.path === 'dimension') {
            return false;
        }

        if (featureSelected.id !== currentSelectedFeatureRefId ||
            (featureSelected as IFeaturePlus).options?.length === 0 ||
            !(itemsUUID.every((id: string) => currentSelectedItemsUUID.includes(id)) && currentSelectedItemsUUID.every((id: string) => itemsUUID.includes(id)))
        ) {
            return true;
        }

        return false;
    }

    //==========================================
    static hasItemsChanged(previousItemsSelected: Array<IConsolidatedClientItem> | [] | undefined, newItemsSelected: Array<IConsolidatedClientItem>): boolean {
        let newIDs: Array<string> = Helpers.arrayIdFromVariant(newItemsSelected),
            oldIDs: Array<string> = Helpers.arrayIdFromVariant(previousItemsSelected || []);

        return !(newIDs.every((id: string) => oldIDs.includes(id)) && oldIDs.every((id: string) => newIDs.includes(id)));
    }


    //===============================================
    static bestTabToShow(activeMenu: string | undefined, allFeatures: (IFeaturePlus | IFeatureGroupPlus)[], menus: menuItem[], hideLightTab: boolean, alternativeItems: number, alternativeDimension: number): menuItem | null {
        let bestTab: menuItem | null = null;

        switch (activeMenu) { //does have feature in active tab? 
            case 'alternative':
                alternativeItems > 0 ? bestTab = menus[5] : null;
                break;
            case 'style':
                Helpers.searchInTree(allFeatures, 'style').length > 0 ? bestTab = menus[0] : null;
                break;
            case 'subItem':
                Helpers.searchInTree(allFeatures, 'subItem').length > 0 ? bestTab = menus[1] : null;
                break;
            case 'dimension':
                let tempFeatures = Helpers.searchInTree(allFeatures, 'dimension');
                tempFeatures.length > 0 || alternativeDimension > 0 ? bestTab = menus[2] : null;
                break;
            case 'opening':
                Helpers.searchInTree(allFeatures, 'opening').length > 0 ? bestTab = menus[3] : null;
                break;
            case 'positioning':
                Helpers.searchInTree(allFeatures, 'positioning').length > 0 ? bestTab = menus[4] : null;
                break;

            case 'light':
                Helpers.searchInTree(allFeatures, 'ligth').length > 0 ? bestTab = menus[6] : null;
                break;
        }

        if (bestTab) {
            return bestTab; //we are good, keep this tab active

        } else { //need to find first tab with some features to expose
            if (activeMenu !== 'style' && Helpers.searchInTree(allFeatures, 'style').length > 0) {
                return menus[0];
            } else if (activeMenu !== 'subItem' && Helpers.searchInTree(allFeatures, 'subItem').length > 0) {
                return menus[1];
            } else if (activeMenu !== 'dimension' && (Helpers.searchInTree(allFeatures, 'dimension').length > 0 || alternativeDimension > 0)) {
                return menus[2];
            } else if (activeMenu !== 'opening' && Helpers.searchInTree(allFeatures, 'opening').length > 0) {
                return menus[3];
            } else if (activeMenu !== 'positioning' && Helpers.searchInTree(allFeatures, 'positioning').length > 0) {
                return menus[4];
            } else if (activeMenu !== 'alternative' && alternativeItems > 0) {
                return menus[5];
            } else if (activeMenu !== 'light' && !hideLightTab && Helpers.searchInTree(allFeatures, 'lighting').length > 0) {
                return menus[6];
            } else {
                return null;
            }
        }
    }



    //==============================
    static isMixedValues(feature: IFeaturePlus, realFeatures: Array<IFeaturePlus>): boolean {
        let selectedValue: number | undefined,
            mixValues: boolean = false;

        if (feature.id === 'all') {
            //need to check more...
            return mixValues;
        } else {
            if (feature.selectedOptions?.length === 1 || feature.selectedOptions?.every((selection: IConsolidatedFeatureSelection) => selection.option.value === feature.selectedOptions![0].option.value)) {
                mixValues = false;

            } else if (feature.selectedOptions?.length && feature.selectedOptions?.length > 1) {
                mixValues = true;
            }

            return mixValues;
        }
    }

    //================================================================================================
    static findPreviousFacets(items: Array<IConsolidatedClientItem> | undefined, feature: IFeaturePlus | undefined): Array<IFacet> {
        let previousFacets: Array<IFacet> = [];

        if (!feature || !items || items.length === 0) {
            return previousFacets;
        }

        return this.getFacetsByFeatures(items[0], feature);
    }

    //=================================================================================
    static getFacetsByFeatures(item: IConsolidatedClientItem, feature: IFeaturePlus): Array<IFacet> {

        let previousFacets: Array<IFacet> = facetsByFeatures?.get(item.itemId + '__' + feature?.id) || [];

        return previousFacets;
    }

    //================================================================================================
    static findPreviousSelectedFacets(items: Array<IConsolidatedClientItem> | undefined, feature: IFeaturePlus | undefined): ICharacteristicsFilter {
        let previousFacets: ICharacteristicsFilter = {};

        if (!feature || !items || items.length === 0) {
            return previousFacets;
        }

        return this.getSelectedFacetsByFeatures(items[0], feature);
    }

    //=================================================================================
    static getSelectedFacetsByFeatures(item: IConsolidatedClientItem, feature: IFeaturePlus): ICharacteristicsFilter {

        let previousFilteredFacets: Map<string, string[]> | undefined = selectedFacetsByFeatures?.get(item.itemId + '__' + feature?.id);

        return previousFilteredFacets ? Object.fromEntries(previousFilteredFacets) : {};
    }

    //=================================================================================
    static mergeFeaturesById(rootFeatures: Array<IFeaturePlus | IFeatureGroupPlus>): Array<[string, (IFeaturePlus | IFeatureGroupPlus)[]]> {
        let uniqueFeaturesMap: Map<string, Array<IFeaturePlus | IFeatureGroupPlus>> = new Map();

        //merge identical features
        rootFeatures.forEach((feature: IFeaturePlus | IFeatureGroupPlus) => {
            if (uniqueFeaturesMap.has(feature.id)) {
                let updateFeature: Array<IFeaturePlus | IFeatureGroupPlus> | [] = uniqueFeaturesMap.get(feature.id) || [];
                updateFeature?.push(feature);
                uniqueFeaturesMap.set(feature.id, updateFeature);
            } else {
                uniqueFeaturesMap.set(feature.id, [feature]);
            }
        });

        return Array.from(uniqueFeaturesMap);
    }

    //=================================================================================
    static mergeFeaturesByCode(rootFeatures: Array<IFeaturePlus>): Array<[string, (IFeaturePlus)[]]> {
        let uniqueFeaturesMap: Map<string, Array<IFeaturePlus>> = new Map();

        //merge identical features
        rootFeatures.forEach((feature: IFeaturePlus) => {
            if (uniqueFeaturesMap.has(feature.code)) {
                let updateFeature: Array<IFeaturePlus> | [] = uniqueFeaturesMap.get(feature.code) || [];
                updateFeature?.push(feature);
                uniqueFeaturesMap.set(feature.code, updateFeature);
            } else {
                uniqueFeaturesMap.set(feature.code, [feature]);
            }
        });

        return Array.from(uniqueFeaturesMap);
    }

    //================================================================================
    static hexToRGB(hex: string): Array<number> | null {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        const properHex = hex.replace(shorthandRegex, (m, r, g, b) => (r + r + g + g + b + b));

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(properHex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
        ] : null;
    }

    //=================================================================================
    static getMapKeyFromValue(map: any, input: string): string | undefined {
        for (let [key, value] of map.entries()) {
            if (value.includes(input)) {
                return key;
            }
        }

        return undefined;
    }

    //=================================================================================
    static getPackageVersion(): string {
        return PackageJSON.version;
    }
}

