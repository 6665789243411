export class InsertionOffsetsFactory {
    static create(insertionOffsets: IInsertionOffsets | V2.IInsertionOffset): IContentAPIInsertionOffsets {
        return new InsertionOffsets(insertionOffsets).serialize();
    }
}
    
export class InsertionOffsets {
    protected _position?: Vector3Str;
    protected _orientation?: Vector3Str;
    protected _context?: IInsertionOffsetContext;

    constructor(insertionOffsets: IInsertionOffsets | V2.IInsertionOffset) {
        this._position = insertionOffsets.position;
        this._orientation = insertionOffsets.orientation;
        this._context = insertionOffsets.context;
    }

    serialize(): IContentAPIInsertionOffsets {
        const insertionOffsets: IContentAPIInsertionOffsets = {};

        if (this._position !== undefined) {
            insertionOffsets.position = this._position;
        }

        if (this._orientation !== undefined) {
            insertionOffsets.orientation = this._orientation;
        }

        if (this._context !== undefined) {
            insertionOffsets.context = this._context;
        }

        return insertionOffsets;
    }
}

export interface IContentAPIInsertionOffsets {
    position?: Vector3Str;
    orientation?: Vector3Str;
    context?: IInsertionOffsetContext;
}