export class FeatureClassificationFactory {
    static create(featureClassification: IFeatureClassification): IContentAPIFeatureClassification {
        return new FeatureClassification(featureClassification).serialize();
    }
}    
    
export class FeatureClassification {
    protected _baseFeatureType: BaseFeatureType;
    protected _characteristics?: IFeatureCharacteristics;
    protected _tags?: Array<string>;

    constructor(featureClassification: IFeatureClassification) {
        this._baseFeatureType = featureClassification.baseFeatureType;
        this._characteristics = featureClassification.characteristics;
        this._tags = featureClassification.tags;
    }

    serialize(): IContentAPIFeatureClassification {
        const featureClassification: IContentAPIFeatureClassification = {
            baseFeatureType: this._baseFeatureType
        };

        if (this._characteristics !== undefined) {
            featureClassification.characteristics = this._characteristics;
        }

        if (this._tags !== undefined) {
            featureClassification.tags = this._tags;
        }

        return featureClassification;
    }
}

export interface IContentAPIFeatureClassification {
    baseFeatureType: BaseFeatureType;
    characteristics?: IFeatureCharacteristics;
    tags?: Array<string>;
}