import {
    Accessor,
    Root,
    Document,
    Buffer,
    Scene,
    Node
} from "@gltf-transform/core";
import { glMatrix, vec2, vec3 } from "gl-matrix";
import { VEC2_ZERO } from "./VectorUtils";

export type VectorsMinMax = {
    min: vec2;
    max: vec2;
};

export type Triangle = [vec3, vec3, vec3];
export type LineSegment3D = [vec3, vec3];

export type X_AXIS = 0;
export type Y_AXIS = 1;
export type Z_AXIS = 2;

export const X_AXIS: X_AXIS = 0;
export const Y_AXIS: Y_AXIS = 1;
export const Z_AXIS: Z_AXIS = 2;

export const
    MATERIAL_TRANSFER_TOKEN: string = "_TO_TRANSFER",
    NODE_REMOVAL_TOKEN: string = "_TO_REMOVE";

export type CubicUVRotations = {
    top: boolean
    front: boolean
    right: boolean
};

export interface IGeometryLogger {
    logUVGroup(node: Node, projectionMethod: ProjectionMethod): void,
    logUVGroupEnd(): void,
    logUnusableUVSet(node: Node, materialId: string): void,
    logPreexistingUVMax(node: Node, maxU: number, maxV: number): void,
    logBBoxMinMax(bboxMin: vec3, bboxMax: vec3): void,
    logPositionTextureCoordinate(positionPoint: vec3, normal: vec3, uvPoint: vec2, projectionMethod: TextureProjectionMethod): void,
    logRotatedUVPoint(uvPoint: vec2, rotatedPoint: vec2, zUp: boolean): void;
}

export abstract class AbstractGeometryLogger implements IGeometryLogger {
    logUVGroup(node: Node, projectionMethod: ProjectionMethod): void {
        throw new Error("Abstract Class Method should not be used.");
    }
    logUVGroupEnd(): void {
        throw new Error("Abstract Class Method should not be used.");
    }
    logUnusableUVSet(node: Node, materialId: string): void {
        throw new Error("Abstract Class Method should not be used.");
    }
    logPreexistingUVMax(node: Node, maxU: number, maxV: number): void {
        throw new Error("Abstract Class Method should not be used.");
    }
    logBBoxMinMax(bboxMin: vec3, bboxMax: vec3): void {
        throw new Error("Abstract Class Method should not be used.");
    }
    logPositionTextureCoordinate(positionPoint: vec3, normal: vec3, uvPoint: vec2, projectionMethod: TextureProjectionMethod): void {
        throw new Error("Abstract Class Method should not be used.");
    }
    logRotatedUVPoint(uvPoint: vec2, rotatedPoint: vec2, zUp: boolean): void {
        throw new Error("Abstract Class Method should not be used.");
    }
}

export interface IUVCalculationPayload {
    indices: Uint16Array,
    vertices: Float32Array,
    normals: Float32Array,
    scale: vec3,
    bboxMin: vec3,
    bboxMax: vec3,
    alignment?: IProjectionAlignment,
    sampling?: IMaterialTextureSamplingDef,
    texScaleFactor?: number,
    areVerticesZUp?: boolean,
    geometryLogger?: AbstractGeometryLogger
}

const RAD_90_DEG: number = glMatrix.toRadian(90) * -1;

export const
    LOCATION_TYPE_ON_FLOOR: LocationType = "onFloor",
    LOCATION_TYPE_AS_SUBITEM: LocationType = "asSubItem";

export class GeometryUtils {
    /**
     * method to alter input indices array and reverse the triangle winding order
     * @param indices
     */
    static reverseWindingOrder(indices: Array<number>): void {
        // change triangle clock
        for (let i: number = 0; i < indices.length; i += 3) {
            let temp: number = indices[i];
            indices[i] = indices[i + 1];
            indices[i + 1] = temp;
        }
    }

    static extract2DPointsFrom3DPoints(vertices: Float32Array, axis: [number, number]): Float32Array {
        let points2D: Array<number> = [];

        for (let i: number = 0; i < vertices.length; i += 3) {
            points2D.push(vertices[i + axis[0]]);
            points2D.push(vertices[i + axis[1]]);
        }

        return new Float32Array(points2D);
    }

    static getPointsFromVertices(vertices: Float32Array, indices?: Array<number>): Array<vec3> {
        let points: Array<vec3> = [];

        if (indices) {
            for (let i: number = 0; i < indices.length; i++) {
                points.push(vertices.slice(indices[i] * 3, (indices[i] * 3) + 3) as vec3);
            }
        } else {
            for (let i = 0; i < vertices.length; i += 3) {
                points.push(vertices.slice(i, i + 3) as vec3);
            }
        }

        return points;
    }

    static clampVertices(vertices: Float32Array, zeroAxis?: X_AXIS | Y_AXIS | Z_AXIS, minimum?: Vector3, maximum?: Vector3): Float32Array {
        let clampVertices: Float32Array = new Float32Array(vertices.length);

        for (let i: number = 0; i < vertices.length; i += 3) {
            if (minimum && maximum) {
                clampVertices[i] = Math.max(Math.min(vertices[i], maximum[0]), minimum[0]);
                clampVertices[i + 1] = Math.max(Math.min(vertices[i + 1], maximum[1]), minimum[1]);
                clampVertices[i + 2] = Math.max(Math.min(vertices[i + 2], maximum[2]), minimum[2]);
            } else {
                clampVertices.set(vertices.slice(i, i + 2), i);
                // clampVertices[i] = vertices[i];
                // clampVertices[i + 1] = vertices[i + 1];
                // clampVertices[i + 2] = vertices[i + 2];
            }

            if (zeroAxis !== undefined) {
                clampVertices[i + zeroAxis] = 0;
            }
        }

        return clampVertices;
    }

    static getTrianglesFromIndices(indices: Uint16Array, vertices: Float32Array): Array<Triangle> {
        let triangles: Array<Triangle> = [];

        for (let i: number = 0; i < indices.length; i += 3) {
            let p1: vec3 = vec3.fromValues(...Array.from(vertices.slice(indices[i] * 3, (indices[i] * 3) + 3)) as [number, number, number]),
                p2: vec3 = vec3.fromValues(...Array.from(vertices.slice(indices[i + 1] * 3, (indices[i + 1] * 3) + 3)) as [number, number, number]),
                p3: vec3 = vec3.fromValues(...Array.from(vertices.slice(indices[i + 2] * 3, (indices[i + 2] * 3) + 3)) as [number, number, number]);

            triangles.push([p1, p2, p3]);
        }

        return triangles;
    }

    static sortTriangles(triangles: Array<Triangle>): Array<Triangle> {
        let trianglesToOrder: Array<Triangle> = triangles.slice();
        let orderedTriangles: Array<Triangle> = [];

        // iterate over triangles
        let currentTriangle: Triangle = trianglesToOrder.shift() as Triangle;

        while (trianglesToOrder.length > 0) {
            orderedTriangles.push(currentTriangle);

            // find next triangle
            let foundTriangleIndex: number = trianglesToOrder.findIndex((triangle: Triangle) => {
                let touchPoints: number = 0;

                currentTriangle.forEach((currentVertex: vec3) => {
                    if (triangle.some((triangleVertex: vec3) => vec3.equals(currentVertex, triangleVertex))) {
                        touchPoints++;
                    }
                });

                return touchPoints == 2;
            });

            if (foundTriangleIndex === -1 && trianglesToOrder.length > 0) {
                throw new Error("Invalid triangulation");
            }

            currentTriangle = trianglesToOrder.splice(foundTriangleIndex, 1)[0];
            if (trianglesToOrder.length === 0) {
                orderedTriangles.push(currentTriangle);
            }
        }

        return orderedTriangles;
    }

    static getTrianglesOuterLineSegments(triangles: Array<Triangle>): Array<LineSegment3D> {
        let triangleLineSegments: Array<LineSegment3D> = [];

        let lineTracker: Map<string, LineSegment3D> = new Map();

        triangles.forEach((triangle: Triangle) => {
            triangle.forEach((vertex: vec3, idx: number) => {
                let nextVertex: vec3 = triangle[(idx + 1) % 3];
                let segment: LineSegment3D = [vertex, nextVertex];

                let key: string = segment.map((v: vec3) => vec3.str(v)).join(":");
                let matchKey: string | undefined;

                if (lineTracker.has(key)) {
                    matchKey = key;
                } else {
                    key = segment.slice().reverse().map((v: vec3) => vec3.str(v)).join(":");
                    if (lineTracker.has(key)) {
                        matchKey = key;
                    }
                }

                if (matchKey) {
                    let segmentToDel: LineSegment3D = lineTracker.get(matchKey) as LineSegment3D;
                    lineTracker.delete(matchKey);
                    let index: number = triangleLineSegments.indexOf(segmentToDel);
                    if (index > -1) {
                        triangleLineSegments.splice(index, 1);
                    }
                } else {
                    lineTracker.set(key, segment);
                    triangleLineSegments.push(segment);
                }
            });
        });

        return triangleLineSegments;
    }

    static chainLineSegments(lineSegments: Array<LineSegment3D>): Array<LineSegment3D> {
        let orderedLineSegments: Array<LineSegment3D> = [];
        let currentSegment: LineSegment3D | undefined;

        currentSegment = lineSegments.shift() as LineSegment3D;
        orderedLineSegments.push(currentSegment);

        while (lineSegments.length > 0) {
            if (!currentSegment) break;

            // find next segment
            let nextIdx: number = lineSegments.findIndex((segment: LineSegment3D) => {
                return vec3.equals(segment[0], currentSegment![1]);
            });

            if (nextIdx > -1) {
                currentSegment = lineSegments.splice(nextIdx, 1)[0];
                orderedLineSegments.push(currentSegment);
            } else if (lineSegments.length > 0) {
                throw new Error("Unable to find connecting segment");
            }
        }

        return orderedLineSegments;
    }

    static mergeGLTFDocuments(outDocument: Document, withDocument: Document, mergeScenes: boolean = false): Document {
        outDocument.merge(withDocument);

        let docRoot: Root = outDocument.getRoot();
        const mainBuffer: Buffer = docRoot.listBuffers()[0];
        if (mergeScenes) {
            let scenes: Array<Scene> = docRoot.listScenes(),
                rootScene: Scene = scenes[0];

            docRoot.setDefaultScene(rootScene);
            if (scenes.length > 1) {
                for (let i: number = 1; i < scenes.length; i++) {
                    scenes[i]
                        .listChildren()
                        .forEach((node: Node) => rootScene.addChild(node));
                    scenes[i].dispose();
                }
            }
        }

        docRoot.listAccessors().forEach((accessor: Accessor) => accessor.setBuffer(mainBuffer));
        docRoot.listBuffers().forEach((buff: Buffer, index: number) => index > 0 && buff.dispose());

        return outDocument;
    }

    static getInsertionOffset(insertionOffset: IInsertionOffsets | undefined, locationType: LocationType, installationType?: string): IInsertionOffset {
        if (!insertionOffset) return _createBlankOffset();

        let insertionOffsets: Array<IInsertionOffsets> = [insertionOffset];

        if (insertionOffset?.others) {
            insertionOffsets.push(...Object.values(insertionOffset.others));
        }

        let offset: IInsertionOffset = this.findInInsertionOffsets(insertionOffsets, locationType, installationType);
        return offset;
    }

    static getInsertionOrientationOffset(insertionOffset: IInsertionOffsets | undefined, locationType: LocationType, installationType?: string): Vector3Str {
        let offset: IInsertionOffset = this.getInsertionOffset(insertionOffset, locationType, installationType);
        return offset.orientation as Vector3Str;
    }

    // Search the insertionOffsets structure for a matching context. If one isn't found or hasn't been specified, return the default.
    static getInsertionPositionOffset(insertionOffset: IInsertionOffsets | undefined, locationType: LocationType, installationType?: string): Vector3Str {
        let offset: IInsertionOffset = this.getInsertionOffset(insertionOffset, locationType, installationType);
        return offset.position as Vector3Str;
    }

    // Search the insertionOffsets structure for a matching context. If one isn't found or hasn't been specified, return the default.
    static findInInsertionOffsets(insertionOffsets: Array<IInsertionOffsets>, locationType: LocationType, installationType?: string): IInsertionOffset {
        let offset: IInsertionOffset | undefined;

        insertionOffsets.some((otherOffset: IInsertionOffset) => {
            offset = GeometryUtils.getInsertionOffsetForContext(otherOffset, locationType, installationType);
            return Boolean(offset);
        });

        // If any offset does not provide a locationType, it is considered as "asSubItem"
        // At this point, if offset is still undefined, that means there is no offset` for the given locationType and no default offset is available,
        // so return ["0", "0", "0"]
        return offset ?? _createBlankOffset();;
    }

    static getInsertionOffsetForContext(offset: IInsertionOffset, locationType: LocationType, installationType?: string): IInsertionOffset | undefined {
        let insertionOffset: IInsertionOffset | undefined,
            // If InsertionOffset context is not provided OR context.locationType is not provided,
            // the default locationType is "asSubItem"
            locationTypeMatchFound: boolean | undefined =
                (locationType === LOCATION_TYPE_AS_SUBITEM && (!offset.context || offset.context.locationType.length === 0)) ||
                (offset.context?.locationType.includes(locationType) && (installationType === "*" || installationType === offset.context.installationType));

        if (offset && locationTypeMatchFound) {
            insertionOffset = offset;
        }

        return insertionOffset;
    }

    /**
     * Compute flat normals for a triangle of 3 position points.
     * 
     * https://gist.github.com/donmccurdy/34a60951796cf703c8f6a9e1cd4bbe58
     */
    static computeNormal(p1: vec3, p2: vec3, p3: vec3): vec3 {
        let faceNormal: vec3 = vec3.create(),
            v1: vec3 = vec3.create(),
            v2: vec3 = vec3.create();

        vec3.sub(v1, p2, p1);
        vec3.sub(v2, p3, p1);
        vec3.cross(faceNormal, v1, v2);
        vec3.normalize(faceNormal, faceNormal);

        return faceNormal;
    }


    static calculateNormals(indices: Uint16Array, positions: Float32Array, scale: vec3): Float32Array {
        let normals: Float32Array = new Float32Array(positions.length);

        for (let i: number = 0; i < indices.length; i += 3) {
            let v1idx: number = indices[i],
                v2idx: number = indices[i + 1],
                v3idx: number = indices[i + 2],
                p1: vec3 = vec3.fromValues(positions[v1idx * 3], positions[v1idx * 3 + 1], positions[v1idx * 3 + 2]),
                p2: vec3 = vec3.fromValues(positions[v2idx * 3], positions[v2idx * 3 + 1], positions[v2idx * 3 + 2]),
                p3: vec3 = vec3.fromValues(positions[v3idx * 3], positions[v3idx * 3 + 1], positions[v3idx * 3 + 2]);

            // Scale UVs in glTF base unit: 1 meter = 1 UV unit.
            p1 = vec3.multiply(vec3.create(), p1, scale);
            p2 = vec3.multiply(vec3.create(), p2, scale);
            p3 = vec3.multiply(vec3.create(), p3, scale);

            let faceNormal: vec3;

            faceNormal = vec3.fromValues(normals[v1idx * 3], normals[v1idx * 3 + 1], normals[v1idx * 3 + 2]);

            faceNormal = GeometryUtils.computeNormal(p1, p2, p3);
            normals.set(faceNormal, v1idx * 3);
            normals.set(faceNormal, v2idx * 3);
            normals.set(faceNormal, v3idx * 3);
        }

        return normals;
    }

    // make object arg with interface instead ?! list of args too long
    static calculateCubicUVs(uvCalcPayload: IUVCalculationPayload): Float32Array {
        let {
            indices,
            vertices,
            normals,
            scale,
            bboxMin,
            bboxMax,
            alignment,
            sampling,
            texScaleFactor,
            areVerticesZUp,
            geometryLogger
        }: IUVCalculationPayload = uvCalcPayload;

        let uvCoords: Float32Array = new Float32Array(vertices.length / 3 * 2),
            treatedIndices: Set<number> = new Set();

        for (let i: number = 0; i < indices.length; i += 3) {
            let v1idx: number = indices[i],
                v2idx: number = indices[i + 1],
                v3idx: number = indices[i + 2],
                p1: vec3 = vec3.fromValues(vertices[v1idx * 3], vertices[v1idx * 3 + 1], vertices[v1idx * 3 + 2]),
                p2: vec3 = vec3.fromValues(vertices[v2idx * 3], vertices[v2idx * 3 + 1], vertices[v2idx * 3 + 2]),
                p3: vec3 = vec3.fromValues(vertices[v3idx * 3], vertices[v3idx * 3 + 1], vertices[v3idx * 3 + 2]),
                uvPoints: Array<vec2> = new Array(3);

            // Scale UVs in glTF base unit: 1 meter = 1 UV unit.
            p1 = vec3.multiply(vec3.create(), p1, scale);
            p2 = vec3.multiply(vec3.create(), p2, scale);
            p3 = vec3.multiply(vec3.create(), p3, scale);

            let faceNormal: vec3;

            faceNormal = vec3.fromValues(normals[v1idx * 3], normals[v1idx * 3 + 1], normals[v1idx * 3 + 2]);


            uvPoints[0] = _projectPointCubic(p1, faceNormal, bboxMin, bboxMax, areVerticesZUp, geometryLogger);
            uvPoints[1] = _projectPointCubic(p2, faceNormal, bboxMin, bboxMax, areVerticesZUp, geometryLogger);
            uvPoints[2] = _projectPointCubic(p3, faceNormal, bboxMin, bboxMax, areVerticesZUp, geometryLogger);

            // In the case of shared vertices by multiple faces, we favor the front and back faces for consistency.
            let absNormX: number = Math.abs(faceNormal[0]),
                absNormY: number = Math.abs(faceNormal[1]),
                absNormZ: number = Math.abs(faceNormal[2]),
                isFrontBack: boolean = areVerticesZUp ? (absNormY >= absNormX && absNormY >= absNormZ) : (absNormZ >= absNormX && absNormZ >= absNormY);

            if (!treatedIndices.has(v1idx) || isFrontBack) {
                treatedIndices.add(v1idx);
                uvCoords.set(uvPoints[0], v1idx * 2);
            }

            if (!treatedIndices.has(v2idx) || isFrontBack) {
                treatedIndices.add(v2idx);
                uvCoords.set(uvPoints[1], v2idx * 2);
            }

            if (!treatedIndices.has(v3idx) || isFrontBack) {
                treatedIndices.add(v3idx);
                uvCoords.set(uvPoints[2], v3idx * 2);
            }
        }

        if (alignment) {
            _applyProjectionAlignment(indices, normals, uvCoords, alignment, undefined, sampling, texScaleFactor); // texCoordsAccessor, normalsAccessor, indicesAccessor, uvProjection.alignment, undefined, material
        }

        return uvCoords;
    }

    static calculatePlanarUVs(uvCalcPayload: IUVCalculationPayload, planeNormal: vec3): Float32Array {
        let {
            indices,
            vertices,
            normals,
            scale,
            bboxMin,
            bboxMax,
            alignment,
            sampling,
            texScaleFactor,
            areVerticesZUp,
            geometryLogger
        }: IUVCalculationPayload = uvCalcPayload;

        let uvCoords: Float32Array = new Float32Array(vertices.length / 3 * 2);

        for (let i: number = 0; i < indices.length; i++) {
            let texCoordIndex: number = indices[i],
                positionPoint: vec3 = vec3.fromValues(vertices[texCoordIndex * 3], vertices[texCoordIndex * 3 + 1], vertices[texCoordIndex * 3 + 2]);

            // Scale UVs in glTF base unit: 1 meter = 1 UV unit.
            positionPoint = vec3.multiply(vec3.create(), positionPoint, scale);

            let uvPoint: vec2 = _projectPointPlanar(positionPoint, planeNormal, bboxMin, bboxMax, areVerticesZUp);
            geometryLogger?.logPositionTextureCoordinate(positionPoint, planeNormal, uvPoint, "Planar" as TextureProjectionMethod);
            uvCoords.set(uvPoint, texCoordIndex * 2);
        }

        if (alignment) {
            _applyProjectionAlignment(indices, normals, uvCoords, alignment, planeNormal, sampling, texScaleFactor);
        }

        return uvCoords;
    }

    static rotateUvPointZUp(uvPoint: vec2, normal: Vector3, uvRotations: CubicUVRotations, areVerticesZUp: boolean = true): vec2 {
        let absNormX: number = Math.abs(normal[0]),
            absNormY: number = Math.abs(normal[1]),
            absNormZ: number = Math.abs(normal[2]),
            rotatedPoint: vec2 | undefined;

        if (absNormX >= absNormY && absNormX >= absNormZ) {
            if (uvRotations.right) {
                rotatedPoint = vec2.rotate(vec2.create(), uvPoint, VEC2_ZERO, RAD_90_DEG);
            }
        } else if (absNormY >= absNormX && absNormY >= absNormZ) {
            if (uvRotations.front) {
                rotatedPoint = vec2.rotate(vec2.create(), uvPoint, VEC2_ZERO, RAD_90_DEG);
            }
        } else if (uvRotations.top) {
            rotatedPoint = vec2.rotate(vec2.create(), uvPoint, VEC2_ZERO, RAD_90_DEG);
        }

        if (rotatedPoint) {
            // GeometryLogger.logRotatedUVPoint(uvPoint, rotatedPoint, areVerticesZUp);
            uvPoint = rotatedPoint;
        }

        return rotatedPoint || uvPoint;
    }

    /**
     * Returns a rotated uvPoint if it should be rotated around the origin according to the normal and uvRotations
     * configuration.
     */
    static rotateUvPointYUp(uvPoint: vec2, normal: Vector3, uvRotations: CubicUVRotations, areVerticesZUp: boolean = true, geometryLogger?: AbstractGeometryLogger): vec2 {
        let absNormX: number = Math.abs(normal[0]),
            absNormY: number = Math.abs(normal[1]),
            absNormZ: number = Math.abs(normal[2]),
            rotatedPoint: vec2 | undefined;

        if (absNormX >= absNormY && absNormX >= absNormZ) {
            if (uvRotations.right) {
                rotatedPoint = vec2.rotate(vec2.create(), uvPoint, VEC2_ZERO, RAD_90_DEG * -1);
            }
        } else if (absNormY >= absNormX && absNormY >= absNormZ) {
            if (uvRotations.top) {
                rotatedPoint = vec2.rotate(vec2.create(), uvPoint, VEC2_ZERO, RAD_90_DEG);
            }
        } else if (uvRotations.front) {
            rotatedPoint = vec2.rotate(vec2.create(), uvPoint, VEC2_ZERO, RAD_90_DEG);
        }

        if (rotatedPoint) {
            geometryLogger?.logRotatedUVPoint(uvPoint, rotatedPoint, areVerticesZUp);
            uvPoint = rotatedPoint;
        }

        return rotatedPoint || uvPoint;
    }
}

function _projectPointPlanar(point: Vector3, normal: Vector3, bboxMin: Vector3, bboxMax: Vector3, areVerticesZUp?: boolean): vec2 {
    let uvPoint: vec2,
        absNormX: number = Math.abs(normal[0]),
        absNormY: number = Math.abs(normal[1]),
        absNormZ: number = Math.abs(normal[2]);

    if (absNormX >= absNormZ && absNormX >= absNormY) {
        if (areVerticesZUp) {
            // YZ Plane (if in Z Up)
            uvPoint = vec2.fromValues((point[1] - bboxMin[1]), (bboxMin[2] - point[2]));
            if (normal[0] < 0) {
                uvPoint[0] *= -1;
            }
        } else {
            // ZY Plane (if in Y Up)
            uvPoint = vec2.fromValues((point[2] - bboxMin[2]), (point[1] - bboxMin[1]));
            if (normal[0] < 0) {
                uvPoint[0] *= -1;
            }
        }

    } else if (absNormY >= absNormX && absNormY >= absNormZ) {
        // XZ Plane
        uvPoint = vec2.fromValues((bboxMin[0] - point[0]), (bboxMin[2] - point[2]));

        if (normal[1] < 0) {
            uvPoint[0] *= -1;
        }
    } else {
        if (areVerticesZUp) {
            uvPoint = vec2.fromValues((point[0] - bboxMin[0]), (bboxMin[1] - point[1]));
        } else {
            uvPoint = vec2.fromValues((point[0] - bboxMin[0]), (bboxMax[1] - point[1]));
        }

        if (normal[2] < 0) {
            uvPoint[0] *= -1;
        }
    }

    return uvPoint;
}

function _projectPointCubic(point: Vector3, normal: Vector3, bboxMin: Vector3, bboxMax: Vector3, areVerticesZUp?: boolean, geometryLogger?: AbstractGeometryLogger): vec2 {
    let uvPoint: vec2;

    if (areVerticesZUp) {
        uvPoint = _projectPointCubic6ProjectionsZUp(point, normal, bboxMin, bboxMax, geometryLogger);
    } else {
        uvPoint = _projectPointCubic6ProjectionsYUp(point, normal, bboxMin, bboxMax, geometryLogger);
    }

    return uvPoint;
}

/**
 * Returns a UV point from projecting to the plane facing the point's normal, effectively a projection for each of
 * the 6 faces of the bbox.
 */
function _projectPointCubic6ProjectionsZUp(point: Vector3, normal: Vector3, bboxMin: Vector3, bboxMax: Vector3, geometryLogger?: AbstractGeometryLogger): vec2 {
    let uvPoint: vec2,
        absNormX: number = Math.abs(normal[0]),
        absNormY: number = Math.abs(normal[1]),
        absNormZ: number = Math.abs(normal[2]),
        u: number,
        v: number;

    if (absNormX >= absNormY && absNormX >= absNormZ) {
        // YZ Plane
        if (normal[0] < 0) {
            // left face
            u = bboxMax[1] - point[1]; // u in Y axis since we're in Z Up
        } else {
            // right face
            u = point[1] - bboxMin[1]; // u in Y axis since we're in Z Up
        }

        v = bboxMin[2] - point[2]; // v in Y axis -- bottom left image origin
    }
    else if (absNormY >= absNormX && absNormY >= absNormZ) {
        // XZ Plane
        if (normal[1] < 0) {
            // front facing
            u = point[0] - bboxMin[0];
        } else {
            // back facing
            u = bboxMax[0] - point[0];
        }

        v = bboxMin[2] - point[2]; // bottom left image origin
    } else {
        // XY Plane
        if (normal[2] < 0) {
            // bottom plane
            // v = point[1] - vecMin[1]; // top left image origin
            v = point[1] - bboxMax[1]; // bottom left image origin
        } else {
            // top plane
            // v = vecMax[1] - point[1]; // top left image origin
            v = bboxMin[1] - point[1]; // bottom left image origin
        }

        u = point[0] - bboxMin[0];
    }

    uvPoint = vec2.fromValues(u, v);
    geometryLogger?.logPositionTextureCoordinate(point, normal, uvPoint, "Cubic" as TextureProjectionMethod);

    return uvPoint;
}

/**
 * Returns a UV point from projecting to the plane facing the point's normal, effectively a projection for each of
 * the 6 faces of the bbox.
 */
function _projectPointCubic6ProjectionsYUp(point: Vector3, normal: Vector3, bboxMin: Vector3, bboxMax: Vector3, geometryLogger?: AbstractGeometryLogger): vec2 {
    let uvPoint: vec2,
        absNormX: number = Math.abs(normal[0]),
        absNormY: number = Math.abs(normal[1]),
        absNormZ: number = Math.abs(normal[2]),
        u: number,
        v: number;

    if (absNormX >= absNormY && absNormX >= absNormZ) {
        if (normal[0] < 0) {
            u = point[2] - bboxMin[2]; // u in Z axis since we're in Y Up
        } else {
            u = bboxMax[2] - point[2]; // u in Z axis since we're in Y Up
        }
        v = bboxMin[1] - point[1]; // v in Y axis -- bottom left image origin
    } else if (absNormY >= absNormX && absNormY >= absNormZ) {
        if (normal[1] < 0) {
            v = bboxMin[2] - point[2]; // bottom left image origin
        } else {
            v = point[2] - bboxMax[2]; // bottom left image origin
        }
        u = point[0] - bboxMin[0];
    } else {
        if (normal[2] < 0) {
            u = bboxMax[0] - point[0];
        } else {
            u = point[0] - bboxMin[0];
        }
        v = bboxMin[1] - point[1]; // bottom left image origin
    }

    uvPoint = vec2.fromValues(u, v);
    geometryLogger?.logPositionTextureCoordinate(point, normal, uvPoint, "Cubic" as TextureProjectionMethod);

    return uvPoint;
}

function _applyProjectionAlignment(indices: Uint16Array, normals: Float32Array, uvCoords: Float32Array, alignment: IProjectionAlignment, forcedNormal?: vec3, sampling?: IMaterialTextureSamplingDef, scaleFactor: number = 1): void {
    let treatedIndices: Set<number> = new Set(),
        materialWidth: number = (sampling?.realWidth ?? 1000) / scaleFactor,
        materialHeight: number = (sampling?.realHeight ?? 1000) / scaleFactor;

    let facePositiveX: VectorsMinMax = { min: [Infinity, Infinity], max: [-Infinity, -Infinity] },
        faceNegativeX: VectorsMinMax = { min: [Infinity, Infinity], max: [-Infinity, -Infinity] },
        facePositiveY: VectorsMinMax = { min: [Infinity, Infinity], max: [-Infinity, -Infinity] },
        faceNegativeY: VectorsMinMax = { min: [Infinity, Infinity], max: [-Infinity, -Infinity] },
        facePositiveZ: VectorsMinMax = { min: [Infinity, Infinity], max: [-Infinity, -Infinity] },
        faceNegativeZ: VectorsMinMax = { min: [Infinity, Infinity], max: [-Infinity, -Infinity] };

    // Save the U and V minimum and maximum of each projection.
    for (let i: number = 0; i < indices.length; i++) {
        const vIndex: number = indices[i],
            uvPoint: vec2 = vec2.fromValues(uvCoords[vIndex * 2], uvCoords[vIndex * 2 + 1]),
            normal: vec3 = forcedNormal ?? vec3.fromValues(normals[vIndex * 3], normals[vIndex * 3 + 1], normals[vIndex * 3 + 2]),
            absNormX: number = Math.abs(normal[0]),
            absNormY: number = Math.abs(normal[1]),
            absNormZ: number = Math.abs(normal[2]);

        if (absNormX >= absNormY && absNormX >= absNormZ) {
            if (normal[0] >= 0) {
                // X+ face
                facePositiveX.min = vec2.fromValues(Math.min(uvPoint[0], facePositiveX.min[0]), Math.min(uvPoint[1], facePositiveX.min[1]));
                facePositiveX.max = vec2.fromValues(Math.max(uvPoint[0], facePositiveX.max[0]), Math.max(uvPoint[1], facePositiveX.max[1]));
            } else {
                // X- face
                faceNegativeX.min = vec2.fromValues(Math.min(uvPoint[0], faceNegativeX.min[0]), Math.min(uvPoint[1], faceNegativeX.min[1]));
                faceNegativeX.max = vec2.fromValues(Math.max(uvPoint[0], faceNegativeX.max[0]), Math.max(uvPoint[1], faceNegativeX.max[1]));
            }
        } else if (absNormY >= absNormX && absNormY >= absNormZ) {
            if (normal[1] >= 0) {
                // Y+ face
                facePositiveY.min = vec2.fromValues(Math.min(uvPoint[0], facePositiveY.min[0]), Math.min(uvPoint[1], facePositiveY.min[1]));
                facePositiveY.max = vec2.fromValues(Math.max(uvPoint[0], facePositiveY.max[0]), Math.max(uvPoint[1], facePositiveY.max[1]));
            } else {
                // Y- face
                faceNegativeY.min = vec2.fromValues(Math.min(uvPoint[0], faceNegativeY.min[0]), Math.min(uvPoint[1], faceNegativeY.min[1]));
                faceNegativeY.max = vec2.fromValues(Math.max(uvPoint[0], faceNegativeY.max[0]), Math.max(uvPoint[1], faceNegativeY.max[1]));
            }
        } else {
            if (normal[2] >= 0) {
                // Z+ face
                facePositiveZ.min = vec2.fromValues(Math.min(uvPoint[0], facePositiveZ.min[0]), Math.min(uvPoint[1], facePositiveZ.min[1]));
                facePositiveZ.max = vec2.fromValues(Math.max(uvPoint[0], facePositiveZ.max[0]), Math.max(uvPoint[1], facePositiveZ.max[1]));
            } else {
                // Z- face
                faceNegativeZ.min = vec2.fromValues(Math.min(uvPoint[0], faceNegativeZ.min[0]), Math.min(uvPoint[1], faceNegativeZ.min[1]));
                faceNegativeZ.max = vec2.fromValues(Math.max(uvPoint[0], faceNegativeZ.max[0]), Math.max(uvPoint[1], faceNegativeZ.max[1]));
            }
        }
    }

    // Bound UVs [0, 1] to the projection alignment and the material's realWidth and realHeight
    for (let i: number = 0; i < indices.length; i++) {
        const vIndex: number = indices[i],
            uvPoint: vec2 = vec2.fromValues(uvCoords[vIndex * 2], uvCoords[vIndex * 2 + 1]),
            normal: vec3 = forcedNormal ?? vec3.fromValues(normals[vIndex * 3], normals[vIndex * 3 + 1], normals[vIndex * 3 + 2]),
            absNormX: number = Math.abs(normal[0]),
            absNormY: number = Math.abs(normal[1]),
            absNormZ: number = Math.abs(normal[2]);

        let minU: number,
            minV: number,
            maxU: number,
            maxV: number;

        if (absNormX >= absNormY && absNormX >= absNormZ) {
            if (normal[0] >= 0) {
                // X+ face
                [minU, minV] = facePositiveX.min;
                [maxU, maxV] = facePositiveX.max;
            } else {
                // X- face
                [minU, minV] = faceNegativeX.min;
                [maxU, maxV] = faceNegativeX.max;
            }
        } else if (absNormY >= absNormX && absNormY >= absNormZ) {
            if (normal[1] >= 0) {
                // Y+ face
                [minU, minV] = facePositiveY.min;
                [maxU, maxV] = facePositiveY.max;
            } else {
                // Y- face
                [minU, minV] = faceNegativeY.min;
                [maxU, maxV] = faceNegativeY.max;
            }
        } else {
            if (normal[2] >= 0) {
                // Z+ face
                [minU, minV] = facePositiveZ.min;
                [maxU, maxV] = facePositiveZ.max;
            } else {
                // Z- face
                [minU, minV] = faceNegativeZ.min;
                [maxU, maxV] = faceNegativeZ.max;
            }
        }

        let offsettedU: number = uvPoint[0],
            offsettedV: number = uvPoint[1];

        if (alignment.horizontal === "left") {
            // align the left boundary with the origin
            offsettedU = uvPoint[0] - minU;
        } else if (alignment.horizontal === "right") {
            // align the right boundary with the origin then offset left by the sampling realWidth
            offsettedU = uvPoint[0] - maxU + materialWidth;
        } else if (alignment.horizontal === "center") {
            // axis origin must be midway between left and right alignments
            offsettedU = (uvPoint[0] - minU + uvPoint[0] - maxU + materialWidth) / 2;
        }

        if (alignment.vertical === "top") {
            // align the top boundary with the origin
            offsettedV = uvPoint[1] - minV;
        } else if (alignment.vertical === "bottom") {
            // align the bottom boundary with the origin then offset up by the sampling realHeight
            offsettedV = uvPoint[1] - maxV + materialHeight;
        } else if (alignment.vertical === "middle") {
            // axis origin must be midway between top and bottom alignments
            offsettedV = (uvPoint[1] - minV + uvPoint[1] - maxV + materialHeight) / 2;
        }

        if (!treatedIndices.has(vIndex)) {
            treatedIndices.add(vIndex);
            uvCoords.set([offsettedU, offsettedV], vIndex * 2);
        }
    }
}

function _createBlankOffset(): IInsertionOffset {
    return {
        position: ["0", "0", "0"],
        orientation: ["0", "0", "0"]
    };
}