import { NumericalSpec } from "./NumericalSpec";

export class ClearanceSpecsFactory {
    static create(clearanceSpecs: IClearanceSpecs): IContentAPIClearanceSpecs {
        return new ClearanceSpecs(clearanceSpecs).serialize();
    }
}

export class ClearanceSpecs {
    protected _left?: NumericalSpec;
    protected _right?: NumericalSpec;
    protected _back?: NumericalSpec;
    protected _front?: NumericalSpec;
    protected _bottom?: NumericalSpec;
    protected _top?: NumericalSpec;
    protected _others?: Record<string, NumericalSpec>;

    constructor(clearanceSpecs: IClearanceSpecs) {
        this._left = clearanceSpecs.left ? new NumericalSpec(clearanceSpecs.left) : undefined;
        this._right = clearanceSpecs.right ? new NumericalSpec(clearanceSpecs.right) : undefined;
        this._back = clearanceSpecs.back ? new NumericalSpec(clearanceSpecs.back) : undefined;
        this._front = clearanceSpecs.front ? new NumericalSpec(clearanceSpecs.front) : undefined;
        this._bottom = clearanceSpecs.bottom ? new NumericalSpec(clearanceSpecs.bottom) : undefined;
        this._top = clearanceSpecs.top ? new NumericalSpec(clearanceSpecs.top) : undefined;

        if (clearanceSpecs.others) {
            this._others = {};
            Object.keys(clearanceSpecs.others).forEach((key) => {
                this._others![key] = new NumericalSpec(clearanceSpecs.others![key]);
            });
        }
    }

    serialize(): IContentAPIClearanceSpecs {
        const clearanceSpecs: IContentAPIClearanceSpecs = {};

        if (this._left !== undefined) {
            clearanceSpecs.left = this._left.serialize();
        }

        if (this._right !== undefined) {
            clearanceSpecs.right = this._right.serialize();
        }

        if (this._back !== undefined) {
            clearanceSpecs.back = this._back.serialize();
        }

        if (this._front !== undefined) {
            clearanceSpecs.front = this._front.serialize();
        }

        if (this._bottom !== undefined) {
            clearanceSpecs.bottom = this._bottom.serialize();
        }

        if (this._top !== undefined) {
            clearanceSpecs.top = this._top.serialize();
        }

        if (this._others !== undefined) {
            clearanceSpecs.others = {};
            Object.keys(this._others).forEach((key) => {
                clearanceSpecs.others![key] = this._others![key].serialize();
            });
        }

        return clearanceSpecs;
    }
}

export interface IContentAPIClearanceSpecs {
    left?: INumericalSpec;
    right?: INumericalSpec;
    back?: INumericalSpec;
    front?: INumericalSpec;
    bottom?: INumericalSpec;
    top?: INumericalSpec;
    others?: Record<string, INumericalSpec>;
}