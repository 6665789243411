export class CatalogClassificationFactory {
    static create(catalogClassification: ICatalogClassification): IContentAPICatalogClassification {
        return new CatalogClassification(catalogClassification).serialize();
    }
}    
    
export class CatalogClassification {
    protected _mainContentType?: string;
    protected _mainCategories?: Array<string>;
    protected _roomTypes?: Array<string>;
    protected _tags?: Array<string>;

    constructor(catalogClassification: ICatalogClassification) {
        this._mainContentType = catalogClassification.mainContentType;
        this._mainCategories = catalogClassification.mainCategories;
        this._roomTypes = catalogClassification.roomTypes;
        this._tags = catalogClassification.tags;
    }

    serialize(): IContentAPICatalogClassification {
        const classification: IContentAPICatalogClassification = {};

        if (this._mainContentType !== undefined) {
            classification.mainContentType = this._mainContentType;
        }

        if (this._mainCategories !== undefined) {
            classification.mainCategories = this._mainCategories;
        }

        if (this._roomTypes !== undefined) {
            classification.roomTypes = this._roomTypes;
        }

        if (this._tags !== undefined) {
            classification.tags = this._tags;
        }

        return classification;
    }
}

export interface IContentAPICatalogClassification {
    mainContentType?: string;
    mainCategories?: Array<string>;
    roomTypes?: Array<string>;
    tags?: Array<string>;
}