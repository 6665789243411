import { FeatureOption, IContentAPIFeatureOption } from "./FeatureOption";
import { FeatureOptionGroup, IContentAPIFeatureOptionGroup } from "./FeatureOptionGroup";
import { SearchResult, IContentAPISearchResult } from "./SearchResult";

export class ItemFeatureOptionsFactory {
    static create(featureOption: V2.IItemFeatureOptions | IGetItemFeatureOptionsResult): IContentAPIItemFeatureOptions {
        return new ItemFeatureOptions(featureOption).serialize();
    }
}
    
export class ItemFeatureOptions {
    protected _options?: Array<FeatureOption>;
    protected _optionGroups?: Array<FeatureOptionGroup>;
    protected _searchResult?: SearchResult;

    constructor(featureOption: V2.IItemFeatureOptions | IGetItemFeatureOptionsResult) {
        this._options = featureOption.options?.map((option: IFeatureOption) => new FeatureOption(option));
        this._optionGroups = featureOption.optionGroups?.map((optionGroup: IFeatureOptionGroup) => new FeatureOptionGroup(optionGroup));
        this._searchResult = featureOption.searchResult ? new SearchResult(featureOption.searchResult) : undefined;
    }

    serialize(): IContentAPIItemFeatureOptions {
        const featureOptions: IContentAPIItemFeatureOptions = {};

        if (this._options !== undefined) {
            featureOptions.options = this._options.map((option: FeatureOption) => option.serialize());
        }

        if (this._optionGroups !== undefined) {
            featureOptions.optionGroups = this._optionGroups.map((optionGroup: FeatureOptionGroup) => optionGroup.serialize());
        }

        if (this._searchResult !== undefined) {
            featureOptions.searchResult = this._searchResult.serialize();
        }

        return featureOptions;
    }
}

export interface IContentAPIItemFeatureOptions {
    options?: Array<IContentAPIFeatureOption>;
    optionGroups?: Array<IContentAPIFeatureOptionGroup>;
    searchResult?: IContentAPISearchResult;
}