// @ts-nocheck 
import { BadRequestError } from "./errors";

const requestQueue: Map<string, Promise<unknown>> = new Map();

/** Used as references for the maximum length and index of an array. */
const MAX_ARRAY_LENGTH = 4294967295;

type ResultClass<T> = { new(...args: any): T };

export class FunctionUtils {

    static validateMandatoryParameter(param: unknown, paramName: string, fallbackValue?: unknown): void {
        if (!param || (Array.isArray(param) && param.length < 1)) {
            throw new BadRequestError(`Missing mandatory parameter '${paramName}'`, fallbackValue);
        }
    }

    static validateMandatoryParameters(params: Array<unknown>, paramNames: Array<string>): void {
        params.forEach((param: unknown, index: number) => {
            this.validateMandatoryParameter(param, paramNames[index]);
        });
    }

    static async queueRequests<T>(cacheKey: string, promiseFunc: () => Promise<T>): Promise<T> {
        if (requestQueue.has(cacheKey)) {
            // console.log("\n\n\n QUEUING REQUEST FOR KEY: ", cacheKey);
            return requestQueue.get(cacheKey) as Promise<T>;
        } else {
            let handle: NodeJS.Timeout = setTimeout(() => {
                if (requestQueue.has(cacheKey)) {
                    // console.error(" **** POSSIBLE STUCK QUEUED REQUEST -- STALLED FOR 5 MINUTES --- FORCEFULLY TERMINATING **** ");
                    requestQueue.delete(cacheKey);
                }
            }, 60000);

            let promise: Promise<T> = promiseFunc();
            requestQueue.set(cacheKey, promise);

            try {
                await promise;
            } catch (e) {
                // graceful fallback
            } finally {
                requestQueue.delete(cacheKey);
                clearTimeout(handle);
            }

            return promise;
        }
    }

    static debounce(funcToDebounce: Function, debounceTime: number): Function {
        let timeoutHandle: number;
        return function () {
            clearTimeout(timeoutHandle);
            // eslint-disable-next-line prefer-spread, prefer-rest-params
            timeoutHandle = setTimeout(() => funcToDebounce.apply(null, Array.from(arguments)), debounceTime) as any;
        };
    }

    static executeNTimes<T>(numberOfTimes: number, fnToExecute: (index?: number) => T): Array<T> {
        if (numberOfTimes < 1 || numberOfTimes > Number.MAX_SAFE_INTEGER) {
            return [];
        }

        let index = -1;
        const length = Math.min(numberOfTimes, MAX_ARRAY_LENGTH);
        const result: Array<T> = new Array(length);
        while (++index < length) {
            result[index] = fnToExecute(index);
        }

        index = MAX_ARRAY_LENGTH;
        numberOfTimes -= MAX_ARRAY_LENGTH;
        while (++index < numberOfTimes) {
            fnToExecute(index);
        }

        return result;
    }

    /**
     *  Execute the `fnToExecute` concurrently
     * 
     * @param dataSet Array of generic type to be passed to `fnToExecute`
     * @param fnToExecute Function to be executed concurrently
     * @param ResultType `Map` or `Array` (Class name of object in which result is expected)
     * @param parallelChunkCount Number of concurrent executions of `fnToExecute`
     * @returns 
     */
    static executeInChunks<T, U, V>(
        dataSet: Array<T>,
        fnToExecute: (dataItem: T) => Promise<U>,
        ResultType: ResultClass<V>,
        parallelChunkCount: number
    ): Promise<V> {
        if (dataSet.length < 1) {
            return Promise.resolve(new ResultType());
        }

        return new Promise((resolve: (value: V) => void, reject: (reason?: unknown) => unknown) => {
            let results: V = new ResultType(),
                index: number = 0;

            const executor = (): void => {
                if (index === dataSet.length) {
                    return;
                }

                const dataItem: T = dataSet[index++];

                fnToExecute(dataItem)
                    .then((resultItem: U) => {
                        let count: number = 0;
                        if (results instanceof Map) {
                            results.set(dataItem, resultItem);
                            count = results.size;
                        } else if (results instanceof Array) {
                            results.push(resultItem);
                            count = results.length;
                        }

                        count === dataSet.length
                            ? resolve(results)
                            : executor();
                    })
                    .catch(e => reject(e));
            };

            FunctionUtils.executeNTimes(parallelChunkCount, executor);
        });
    }
}
