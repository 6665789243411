import { useEffect, useRef, useState } from "react";
import { EntityType, authoringStateMgr, useAuthoringStateMgr } from "../..";
import { AuthoringCatalogEntity, getAuthoringCatalogEntities } from "../../utils/EntityUtils";
import { useAuthoringGroupsState } from "../../state/useAuthoringGroupsState";
import { CommonUtils } from "@cic/utils";
import { ExplorerRoutes } from "./useNavigation";
import { NullableFacetValuesMap } from "../catalog-browser/useSearchResults";
import { isEqual } from "lodash";

const persistedSearchByTab: Map<string, string> = new Map(); // Filter by Tab

export function useExplorerEntities(
    currentPath: string[],
    catalogItems: AuthoringCatalogEntity[],
    filters: V2.IOptionsSearchItems,
    currentCatalog: ICatalog & ICatalogVersion
) {
    const currentPathRef = useRef<string | null>(null);
    const [entities, setEntities] = useState<AuthoringCatalogEntity[]>([]);
    const [allEntities, setAllEntities] = useState<AuthoringCatalogEntity[]>([]);
    const [itemEntities, setItemEntities] = useState<AuthoringCatalogEntity[]>([]);
    const [featureEntities, setFeatureEntities] = useState<AuthoringCatalogEntity[]>([]);
    const [attributeEntities, setAttributeEntities] = useState<AuthoringCatalogEntity[]>([]);
    const [materialEntities, setMaterialEntities] = useState<AuthoringCatalogEntity[]>([]);
    const [restrictionEntities, setRestrictionEntities] = useState<AuthoringCatalogEntity[]>([]);
    const [filteredEntities, setFilteredEntities] = useState<AuthoringCatalogEntity[]>([]);
    const [newEntities, setNewEntities] = useState<string[]>([]);
    const [deletedEntities, setDeletedEntities] = useState<string[]>([]);
    const [modifiedEntities, setModifiedEntities] = useState<string[]>([]);
    const [invalidEntities, setInvalidEntities] = useState<string[]>([]);
    const authoringGroupsState = useAuthoringGroupsState();
    const [showList, setShowList] = useState(true);
    const [showNewForm, setShowNewForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
    const [selectedCatalogs, setSelectedCatalogs] = useState<(ICatalog & ICatalogVersion)[]>([currentCatalog]);
    const [offset, setOffset] = useState<number>(0);
    const [baseItemTypes, setBaseItemTypes] = useState<IFacetValue[] | null>(null);
    const [dimensions, setDimensions] = useState<IFacet[] | null>(null);
    const [selectedFacets, setSelectedFacets] = useState<ISearchClassifications>({});
    const [characteristics, setCharacteristics] = useState<IFacet[] | null>(null);
    const [selectedDimensions, setSelectedDimensions] = useState<IDimensionFilters>({ measurementUnit: "cm" });
    const [totalResults, setTotalResults] = useState<number>(0);
    const isGroupsPath = currentPath.length >= 1 && currentPath[1] === EntityType.ITEMGROUPS;
    const { state } = useAuthoringStateMgr();

    const onSearchTermChanged = (value: string) => {
        setSearchTerm(value);
        persistedSearchByTab.set(currentPath.toString(), value);
    };

    const onFetchMoreItems = async () => {
        if (
            currentPath[1] === EntityType.FEATURES ||
            currentPath[1] === EntityType.ATTRIBUTES ||
            currentPath[1] === EntityType.MATERIALS ||
            currentPath[1] === EntityType.RESTRICTIONS
        ) {
            return;
        }
        filters.offset = offset + 100;
        setOffset(offset + 100);
        await searchItems(searchTerm + "*", filters, entities);
    };

    const search = async () => {
        if (!loading) {
            setLoading(true);
            filters.classificationFilter = filters.classificationFilter || {};

            if (selectedGroup) {
                filters.groupRefs = selectedGroup ? [selectedGroup] : undefined;
                filters.catalogVersionIds = selectedCatalogs.map((catalog) => catalog.id);
                delete filters.classificationFilter.catalogs;
            } else if (selectedCatalogs.length > 0) {
                filters.catalogVersionIds = selectedCatalogs.map((catalog) => catalog.id);
                delete filters.classificationFilter.catalogs;
                delete filters.groupRefs;
            }

            if (
                selectedFacets.characteristics?.catalogs &&
                Object.entries(selectedFacets.characteristics.catalogs).length > 0
            ) {
                filters.classificationFilter.catalogs = selectedFacets.characteristics.catalogs;
                delete selectedFacets.characteristics.catalogs;
            }
            filters.classificationFilter.baseItemTypes = selectedFacets.baseItemTypes;
            filters.classificationFilter.characteristics = selectedFacets.characteristics;
            filters.dimensionFilter = selectedDimensions;
            filters.nbPerPage = 100;
            filters.offset = 0;

            await getFilteredItems(searchTerm + "*", filters, []);
            await searchItems(searchTerm + "*", filters, []);
        }
    };

    const searchItems = async (
        searchTerm: string,
        filters: V2.IOptionsSearchItems,
        entities: AuthoringCatalogEntity[]
    ) => {
        let tempEntites: AuthoringCatalogEntity[] = [];

        if (filters.groupRefs?.some((ref) => ref.split(".").includes("uncategorized"))) {
            filters.groupRefs = [];
        }

        CiCAPI.content.v2.searchItems(searchTerm, filters).then((response) => {
            if (response.result && response.result) {
                tempEntites = response.result.items.map((i) => {
                    return {
                        entity: i,
                        catalogVersionId: i.catalog.catalogVersion?.id,
                        type: EntityType.ITEMS,
                        code: i.code,
                        id: i.id,
                    } as AuthoringCatalogEntity;
                });
                setTotalResults(response.result.total);
                setBaseItemTypes(
                    response.result.facets?.find((facet) => facet.code === "baseItemTypes")?.values || null
                );

                if (selectedFacets.baseItemTypes) {
                    setCharacteristics(
                        response.result.facets?.filter((facet: IFacet) => {
                            return !/(baseItemTypes|width|height|depth)/.test(facet.code) && facet.values.length > 1;
                        }) || null
                    );

                    setDimensions(
                        response.result.facets?.filter((facet: IFacet) => {
                            return /(width|height|depth)/.test(facet.code) && facet.values.length > 1;
                        }) || null
                    );
                }
                setItemEntities(tempEntites);
                setEntities(entities.concat(tempEntites));
                getPendingChanges(currentPath, entities.concat(tempEntites));
            }
        });
    };

    const getFilteredItems = async (
        searchTerm: string,
        filters: V2.IOptionsSearchItems,
        accumulatedItems: AuthoringCatalogEntity[]
    ) => {
        let filtersCopy = JSON.parse(JSON.stringify(filters));
        delete filtersCopy.groupRefs;

        CiCAPI.content.v2.searchItems(searchTerm, filtersCopy).then((response) => {
            if (response.result && response.result.items) {
                const newItems = response.result.items.map((i) => {
                    return {
                        entity: i,
                        catalogVersionId: i.catalog.catalogVersion?.id,
                        type: EntityType.ITEMS,
                        code: i.code,
                        id: i.id,
                    } as AuthoringCatalogEntity;
                });

                accumulatedItems?.push(...newItems);                    
                accumulatedItems && setFilteredEntities(accumulatedItems);
                
                if (response.result.total > accumulatedItems.length) {
                    filtersCopy.offset += 100;
                    getFilteredItems(searchTerm, filtersCopy, accumulatedItems);
                }
            }
        });
    };

    const onFilterChange = (facet: IFacet | undefined, facetValues: IFacetValue[] | undefined, type: string) => {
        if (type === "baseItemType" || type === undefined) {
            onCategoryChanged(facetValues && facetValues[0]);
        } else if (type === "characteristic") {
            onFacetValueChanged(facet, facetValues);
        } else if (type === "dimensions") {
            onDimensionFacetValueChanged(facet, facetValues);
        } else if (type === "dimension") {
            //to complete
            //onOneDimensionValueChanged(facet as string, (facetValues as any).minValue, (facetValues as any).maxValue);
        }
    };

    const onCategoryChanged = (category: IFacetValue | undefined) => {
        if (category !== undefined) {
            selectedFacets.baseItemTypes = [category.code];
        } else {
            delete selectedFacets.baseItemTypes;
        }
        //start fresh
        delete selectedFacets.characteristics;
        delete selectedFacets.catalogs;
        delete selectedFacets.companies;

        delete selectedDimensions.widths;
        delete selectedDimensions.heights;
        delete selectedDimensions.depths;
        setSelectedDimensions({ ...selectedDimensions });
        setSelectedFacets({ ...selectedFacets });
    };

    const onFacetValueChanged = (facet: IFacet | undefined, selectedFacetValues: IFacetValue[] | undefined) => {
        if (!selectedFacets.characteristics) {
            selectedFacets.characteristics = {};
        }

        let featureFacets: NullableFacetValuesMap = new Map(Object.entries(selectedFacets.characteristics)),
            facetValues: Array<string> = [];

        if (facet === undefined) {
            //clear all facets
            featureFacets = new Map();
        } else if (selectedFacetValues === undefined) {
            //clear this facet
            featureFacets.delete(facet!.code);
        } else {
            facetValues = selectedFacetValues.map((facetValue) => facetValue.code);
            featureFacets.set(facet!.code, facetValues);
        }

        if (featureFacets.size > 0) {
            //adjust actual Array
            const facets = Object.fromEntries(featureFacets);
            selectedFacets!.characteristics = Object.assign({}, facets);
        } else {
            //remove all facets
            delete selectedFacets!.characteristics;
        }

        setSelectedFacets({ ...selectedFacets });
    };

    const onDimensionFacetValueChanged = (facet: IFacet | undefined, facetValues: IFacetValue[] | undefined) => {
        let updatedselectedDimensions: IDimensionFilters = {};

        if (facet?.code) {
            let facetDimension = facet?.code === "width" ? "widths" : facet?.code === "height" ? "heights" : "depths";
            let featureFacets: NullableFacetValuesMap = new Map(Object.entries(selectedDimensions));
            let newFacetValues: Array<string> | any = [];

            if (facet === undefined) {
                //clear all facets
                featureFacets = new Map();
            } else if (facetValues === undefined) {
                //clear this facet
                featureFacets.delete(facetDimension);
            } else {
                facetValues.forEach((facetValue: IFacetValue) => {
                    newFacetValues.push({
                        min: facetValue!.range?.from,
                        max: facetValue!.range?.to,
                        maxInclusive: false,
                        filterInRanges: false,
                    });
                });
                featureFacets.set(facetDimension, newFacetValues);
            }

            if (featureFacets.size > 0) {
                //adjust actual Array
                const facets = Object.fromEntries(featureFacets);

                updatedselectedDimensions = Object.assign({}, facets);
                updatedselectedDimensions.measurementUnit = "cm";
            } else {
                //remove all facets
                updatedselectedDimensions = { measurementUnit: "cm" };
            }
        }
        setSelectedDimensions(updatedselectedDimensions);
    };

    const searchFeatures = async (currentPath: string[]) => {
        const cleanedSearchTerm = searchTerm.replace(/\*$/, "");
        const versionId = currentPath[0];
        const type = currentPath[1] as EntityType;

        if (!loading) {
            setLoading(true);
            try {
                if (cleanedSearchTerm.length === 0) {
                    const authoringEntities = await getAuthoringCatalogEntities(type, versionId, catalogItems);
                    if (authoringEntities) {
                        setEntities(authoringEntities);
                        setFeatureEntities(authoringEntities);
                        getPendingChanges(currentPath, authoringEntities);
                    } else {
                        setEntities([]);
                    }
                } else {
                    const filterEntities = (featureEntities: any[], cleanedSearchTerm: string) => {
                        return featureEntities.filter((entity) => {
                            const matchCode = entity.code.toLowerCase().includes(cleanedSearchTerm.toLowerCase());
                            const matchNames=entity.entity.names && Object.values(entity.entity.names).some((name:any)=>name.toLowerCase().includes(cleanedSearchTerm.toLowerCase()))  
                            return matchCode||matchNames;
                        });
                    };
                    const filteredEntities = filterEntities(featureEntities, cleanedSearchTerm);
                    setEntities(filteredEntities);
                    setTotalResults(filteredEntities.length);
                    setFeatureEntities(filteredEntities);
                    getPendingChanges(currentPath, filteredEntities);
                }
            } catch (error) {
                console.error("Error fetching entities:", error);
            } finally {
                setLoading(false);
            }
        }
    };
    const searchAttributes = async (currentPath: string[]) => {
        const cleanedSearchTerm = searchTerm.replace(/\*$/, "");
        const versionId = currentPath[0];
        const type = currentPath[1] as EntityType;

        if (!loading) {
            setLoading(true);
            try {
                if (cleanedSearchTerm.length === 0) {
                    const authoringEntities = await getAuthoringCatalogEntities(type, versionId);
                    if (authoringEntities) {
                        setEntities(authoringEntities);
                        setAttributeEntities(authoringEntities);
                        getPendingChanges(currentPath, authoringEntities);
                    } else {
                        setEntities([]);
                    }
                } else {
                    const filterEntities = (attributeEntities: any[], cleanedSearchTerm: string) => {
                        return attributeEntities.filter((entity) => {
                            const matchCode = entity.code.toLowerCase().includes(cleanedSearchTerm.toLowerCase());
                            const matchNames=entity.entity.names && Object.values(entity.entity.names).some((name:any)=>name.toLowerCase().includes(cleanedSearchTerm.toLowerCase()))  
                            return matchCode||matchNames;
                        });
                    };
                    const filteredEntities = filterEntities(attributeEntities, cleanedSearchTerm);
                    setEntities(filteredEntities);
                    setTotalResults(filteredEntities.length);
                    setAttributeEntities(filteredEntities);
                    getPendingChanges(currentPath, filteredEntities);
                }
            } catch (error) {
                console.error("Error fetching entities:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const searchMaterials = async (currentPath: string[]) => {
        const cleanedSearchTerm = searchTerm.replace(/\*$/, "");
        const versionId = currentPath[0];
        const type = currentPath[1] as EntityType;

        if (!loading) {
            setLoading(true);
            try {
                if (cleanedSearchTerm.length === 0) {
                    const authoringEntities = await getAuthoringCatalogEntities(type, versionId);
                    if (authoringEntities) {
                        setEntities(authoringEntities);
                        setMaterialEntities(authoringEntities);
                        getPendingChanges(currentPath, authoringEntities);
                    } else {
                        setEntities([]);
                    }
                } else {
                    const filterEntities = (materialEntities: any[], cleanedSearchTerm: string) => {
                        return materialEntities.filter((entity) => {
                            const matchCode = entity.code.toLowerCase().includes(cleanedSearchTerm.toLowerCase()) 
                            const matchNames=entity.entity.names && Object.values(entity.entity.names).some((name:any)=>name.toLowerCase().includes(cleanedSearchTerm.toLowerCase()))  
                            return matchCode||matchNames;
                        });
                    };
                    const filteredEntities = filterEntities(materialEntities, cleanedSearchTerm);
                    setEntities(filteredEntities);
                    setTotalResults(filteredEntities.length);
                    setMaterialEntities(filteredEntities);
                    getPendingChanges(currentPath, filteredEntities);
                }
            } catch (error) {
                console.error("Error fetching entities:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const searchRestrictions = async (currentPath: string[]) => {
        const cleanedSearchTerm = searchTerm.replace(/\*$/, "");
        const versionId = currentPath[0];
        const type = currentPath[1] as EntityType;

        if (!loading) {
            setLoading(true);
            try {
                if (cleanedSearchTerm.length === 0) {
                    const authoringEntities = await getAuthoringCatalogEntities(type, versionId);
                    if (authoringEntities) {
                        setEntities(authoringEntities);
                        setRestrictionEntities(authoringEntities);
                        getPendingChanges(currentPath, authoringEntities);
                    } else {
                        setEntities([]);
                    }
                } else {
                    const filterEntities = (restrictionEntities: any[], cleanedSearchTerm: string) => {
                        return restrictionEntities.filter((entity) => {
                            const matchCode = entity.code.toLowerCase().includes(cleanedSearchTerm.toLowerCase()) 
                            const matchNames=entity.entity.names && Object.values(entity.entity.names).some((name:any)=>name.toLowerCase().includes(cleanedSearchTerm.toLowerCase()))  
                            return matchCode||matchNames;
                        });
                    };
                    const filteredEntities = filterEntities(restrictionEntities, cleanedSearchTerm);
                    setEntities(filteredEntities);
                    setTotalResults(filteredEntities.length);
                    setRestrictionEntities(filteredEntities);
                    getPendingChanges(currentPath, filteredEntities);
                }
            } catch (error) {
                console.error("Error fetching entities:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const updateAllEntities = async (currentPath: string[], entities: any[], newEntities: any[]) => {
        const versionId = currentPath[0];
        const type = currentPath[1] as EntityType;
        let allNewEntities: AuthoringCatalogEntity[] = [];

        if (newEntities.length > 0) {
            const fetchPromises = newEntities.map(async (code) => {
                const { entity: fetchedEntity } = await authoringStateMgr.entities.fetchEntity(type, code, versionId);

                if ("itemRef" in fetchedEntity && fetchedEntity.itemRef) {
                    const { entity: refEntity } = await authoringStateMgr.entities.fetchEntityRef(
                        type,
                        fetchedEntity.itemRef,
                        versionId
                    );
                    return {
                        ...refEntity,
                        ...fetchedEntity,
                        groupRefs: fetchedEntity.groupRefs || [],
                    };
                } else {
                    const { entity } = await authoringStateMgr.entities.fetchEntity(
                        type,
                        fetchedEntity.code,
                        versionId
                    );
                    return entity;
                }
            });

            const fetchedEntities = await Promise.all(fetchPromises);
            allNewEntities = fetchedEntities.map((entity: any) => ({
                entity,
                code: entity.code,
                type,
                catalogVersionId: versionId,
                id: `${versionId}.${entity.code}`,
            }));
        }

        const updatedEntities = [
            ...entities,
            ...allNewEntities.filter(
                (newEntity) => !entities.some((existingEntity) => existingEntity.id === newEntity.id)
            ),
        ];

        const filteredEntities = filterBySelectedGroup(updatedEntities);

        setAllEntities(filteredEntities);
        setLoading(false);
    };

    const filterBySelectedGroup = (entities: any[]) => {
        if (!selectedGroup) return entities;

        let filteredEntities: AuthoringCatalogEntity[] = [];

        if (selectedGroup.split(".")[1] !== "uncategorized") {
            filteredEntities = entities.filter((entity) => {
                const groupRefs = entity.entity.groupRefs;
                return groupRefs?.includes(selectedGroup) || groupRefs?.includes(selectedGroup.split(".")[1]);
            });
        } else {
            filteredEntities = entities.filter((entity) => entity.entity.groupRefs?.length === 0);
        }

        return filteredEntities;
    };

    const getPendingChanges = (currentPath: string[], entities: any[]) => {
        const { invalid, modified, deleted, added } = authoringStateMgr.pendingChanges.getChangedEntities(
            currentPath[0],
            currentPath[1] as EntityType
        );

        setInvalidEntities(invalid);
        setNewEntities(added);
        setDeletedEntities(deleted);
        setModifiedEntities(modified);

        updateAllEntities(currentPath, entities, added);
    };

    const setGroupPath = (id: string, versionId: string) => {
        if (!id) return;

        if (currentPath.length === 2) {
            setEntities([]);
            setShowList(true);
        } else {
            const group = authoringGroupsState.getCurrentGroup(versionId, EntityType.ITEMGROUPS, id);
            if (group) {
                setEntities([
                    {
                        code: group.code,
                        entity: group,
                        type: EntityType.ITEMGROUPS,
                        catalogVersionId: versionId,
                        id: `${versionId}.${group.code}`,
                    },
                ]);
            } else {
                setEntities([]);
            }
            setShowList(false);
        }
    };

    useEffect(() => {
        if (currentPath.length < 2) return;

        const { invalid, modified, deleted, added } = authoringStateMgr.pendingChanges.getChangedEntities(
            currentPath[0],
            currentPath[1] as EntityType
        );

        if (
            !isEqual(added, newEntities) ||
            !isEqual(deleted, deletedEntities) ||
            !isEqual(modified, modifiedEntities) ||
            !isEqual(invalid, invalidEntities)
        ) {
            getPendingChanges(currentPath, entities);
        }
    }, [currentPath, state]);

    useEffect(() => {
        if (currentPath.toString() === currentPathRef.current || currentPath.length < 2) return;
        currentPathRef.current = currentPath.toString();

        let groupRef: string | null = null;
        const previousSearch: string = persistedSearchByTab.get(currentPath.toString()) || "";
        const versionId = currentPath[0];
        const type = currentPath[1] as EntityType;
        const id = currentPath.at(-1) || "";
        const [version, code] = CommonUtils.splitEntityId(id);

        if (currentPath.at(-1) === ExplorerRoutes.NEW_ENTITY) {
            //Creating a New Entity
            setShowList(false);
            setLoading(false);
            setShowNewForm(true);
            return;
        }

        setShowNewForm(false);
        setOffset(0);

        if (currentPath && currentPath.length > 2) {
            groupRef = currentPath[0] + "." + currentPath[currentPath.length - 1];
        }
        if (selectedGroup !== groupRef) setSelectedGroup(groupRef);
        if (searchTerm !== previousSearch) setSearchTerm(previousSearch || "");

        if (!isGroupsPath) {
            if (!code) return;

            if (currentPath.length === 2) {
                setShowList(true);
                if (currentPath[1] === EntityType.ITEMS) {
                    setEntities(itemEntities);
                    setTotalResults(itemEntities.length);
                    return;
                }
                getAuthoringCatalogEntities(type, versionId, catalogItems).then((authoringEntities) => {
                    if (authoringEntities) {
                        setEntities(authoringEntities);
                        setTotalResults(authoringEntities.length);
                    } else {
                        setEntities([]);
                    }
                });
                return;
            }

            if (type === EntityType.ITEMS && currentPath.length > 2 && !version) {
                setShowList(true);
                if (currentPath[1] === EntityType.ITEMS) {
                    setEntities(itemEntities);
                    return;
                }

                setEntities(catalogItems);
                return;
            }
            authoringStateMgr.entities.fetchEntity(type, code, versionId).then(({ entity }) => {
                if (!entity) {
                    console.warn(`Entity not found: ${code}`);
                } else {
                    setEntities([
                        {
                            entity,
                            code: entity.code,
                            type,
                            catalogVersionId: versionId,
                            id: `${versionId}.${entity.code}`,
                        },
                    ]);
                }
                setShowList(false);
            });
        } else {
            setGroupPath(id, versionId);
        }
    }, [currentPath]);

    useEffect(() => {
        if (currentPath[currentPath.length - 1].includes(".") || currentPath.at(-1) === ExplorerRoutes.NEW_ENTITY) {
            return;
        }

        if (currentPath[1] === EntityType.FEATURES) {
            searchFeatures(currentPath);
        } else if (currentPath[1] === EntityType.ITEMS) {
            search();
        } else if (currentPath[1] === EntityType.ATTRIBUTES) {
            searchAttributes(currentPath);
        } else if (currentPath[1] === EntityType.MATERIALS) {
            searchMaterials(currentPath);
        } else if (currentPath[1] === EntityType.RESTRICTIONS) {
            searchRestrictions(currentPath);
        }
    }, [filters, searchTerm, selectedGroup, selectedCatalogs, selectedFacets, selectedDimensions,currentPath[1]]);

    return {
        entities: allEntities,
        newEntities,
        deletedEntities,
        modifiedEntities,
        showList,
        baseItemTypes,
        characteristics,
        dimensions,
        totalResults,
        isGroupsPath,
        invalidEntities,
        loading,
        showNewForm,
        searchTerm,
        filteredEntities,
        onSearchTermChanged,
        onFilterChange,
        onFetchMoreItems,
    };
}
