import { IContentAPICatalogBaseInfo, CatalogBaseInfo } from "./CatalogBaseInfo";
import { ClearanceSpecs, IContentAPIClearanceSpecs } from "./ClearanceSpecs";
import { Descriptions, IContentAPIDescriptions } from "./Descriptions";
import { Dimensions, IContentAPIDimensions } from "./Dimensions";
import { DimensionSpecs, IContentAPIDimensionSpecs } from "./DimensionSpecs";
import { Images, IContentAPIImages } from "./Images";
import { InsertionOffsets, IContentAPIInsertionOffsets } from "./InsertionOffsets";
import { ItemClassification, IContentAPIItemClassification } from "./ItemClassification";
import { ItemNames, IContentAPIItemNames } from "./ItemNames";
import { ReferenceCodes, IContentAPIReferenceCodes } from "./ReferenceCodes";

export abstract class ItemBase {
    protected _id: string;
    protected _code: string;
    protected _names: ItemNames;
    protected _descriptions?: Descriptions;
    protected _images?: Images;
    protected _dimensions?: Dimensions;
    protected _dimensionSpecs?: DimensionSpecs;
    protected _clearanceDistances?: ClearanceSpecs;
    protected _insertionLocations?: Array<LocationType>;
    protected _insertionOffsets?: Array<InsertionOffsets>;
    protected _refCodes?: ReferenceCodes;
    protected _classification?: ItemClassification;
    protected _catalog: CatalogBaseInfo;
    protected _catalogId: string;
    protected _catalogVersionId: string;
    protected _groupRefs?: Array<string>;
    protected _visible: boolean;
    protected _extras?: string;
    protected _sequenceOrder?: number;

    // V1 ONLY
    protected _geometryTypes: Array<GeometryType>;
    protected _hasGeometry?: boolean;

    protected constructor(itemBase: Partial<IItemBase> | V2.IItemBase, v2GeometryInfo?: V2.IGeometriesInfo) {
        let v1ItemBase: IItemBase = itemBase as IItemBase;
        let v2ItemBase: V2.IItemBase = itemBase as V2.IItemBase;

        this._id = itemBase.id ?? "";
        this._code = itemBase.code ?? "";
        this._names = new ItemNames(itemBase.names ?? { main: "" });
        this._catalogId = itemBase.catalogId ?? "";
        this._catalogVersionId = itemBase.catalogVersionId ?? "";
        this._geometryTypes = v1ItemBase?.geometryTypes ?? v2GeometryInfo?.geometryTypes ?? [];
        this._visible = !!itemBase.visible;

        if (itemBase.descriptions) {
            this._descriptions = new Descriptions(itemBase.descriptions);
        }

        if (itemBase.images?.main?.uri) {
            this._images = new Images(itemBase.images);
        }

        if (itemBase.dimensions) {
            this._dimensions = new Dimensions(itemBase.dimensions);
        }

        if (itemBase.dimensionSpecs) {
            this._dimensionSpecs = new DimensionSpecs(itemBase.dimensionSpecs);
        }

        if (itemBase.clearanceDistances) {
            this._clearanceDistances = new ClearanceSpecs(itemBase.clearanceDistances);
        }

        this._insertionLocations = itemBase.insertionLocations;

        if (itemBase.refCodes) {
            this._refCodes = new ReferenceCodes(itemBase.refCodes);
        }

        if (itemBase.classification) {
            this._classification = new ItemClassification(itemBase.classification);
        }

        this._groupRefs = itemBase.groupRefs;
        this._extras = itemBase.extras;
        this._sequenceOrder = itemBase.sequenceOrder;

        // V1 ONLY
        this._hasGeometry = v1ItemBase.hasGeometry ?? v2GeometryInfo?.hasGeometry; //#TODO this should be removed and replaced with geometryTypes

        if (v1ItemBase.insertionOffsets && !Array.isArray(v1ItemBase.insertionOffsets)) {
            this._insertionOffsets = [new InsertionOffsets(v1ItemBase.insertionOffsets)];
        }

        // V2 ONLY
        if (Array.isArray(v2ItemBase.insertionOffsets) && v2ItemBase.insertionOffsets.length) {
            this._insertionOffsets = [];
            v2ItemBase.insertionOffsets.forEach((insertionOffset: V2.IInsertionOffset) => {
                this._insertionOffsets!.push(new InsertionOffsets(insertionOffset));
            });
        }
        
        if (v2ItemBase.catalog) {
            this._catalog = new CatalogBaseInfo(v2ItemBase.catalog);
            this._catalogId = v2ItemBase.catalog.id;
            this._catalogVersionId = v2ItemBase.catalog.catalogVersion?.id ?? "";
        } else {
            this._catalog = new CatalogBaseInfo({
                id: this._catalogId ?? "",
                code: "",
                name: "",
                companyDisplayName: "",
                companyRefCode: "",
                measurementSystem: "Imperial",
                catalogVersion: {
                    id: this._catalogVersionId ?? "",
                    status: CiCAPI.content.constants.CATALOG_STATUS.ACTIVATED,
                    version: ""
                }
            });
        }
    }

    serialize(): IContentAPIItemBase {
        const itemBase: IContentAPIItemBase = {
            id: this._id,
            code: this._code,
            names: this._names.serialize(),
            catalog: this._catalog.serialize(),
            catalogId: this._catalogId,
            catalogVersionId: this._catalogVersionId,
            geometryTypes: this._geometryTypes,
            visible: this._visible
        };

        if (this._hasGeometry !== undefined) {
            itemBase.hasGeometry = this._hasGeometry;
        }
        
        if (this._descriptions !== undefined) {
            itemBase.descriptions = this._descriptions.serialize();
        }

        if (this._images !== undefined) {
            itemBase.images = this._images.serialize();
        }

        if (this._dimensions !== undefined) {
            itemBase.dimensions = this._dimensions.serialize();
        }

        if (this._dimensionSpecs !== undefined) {
            itemBase.dimensionSpecs = this._dimensionSpecs.serialize();
        }

        if (this._clearanceDistances !== undefined) {
            itemBase.clearanceDistances = this._clearanceDistances.serialize();
        }

        if (this._insertionLocations !== undefined) {
            itemBase.insertionLocations = this._insertionLocations;
        }

        if (this._insertionOffsets !== undefined) {
            itemBase.insertionOffsets = this._insertionOffsets.map((insertionOffset: InsertionOffsets) => insertionOffset.serialize());
        }

        if (this._refCodes !== undefined) {
            itemBase.refCodes = this._refCodes.serialize();
        }

        if (this._classification !== undefined) {
            itemBase.classification = this._classification.serialize();
        }

        if (this._groupRefs !== undefined) {
            itemBase.groupRefs = this._groupRefs;
        }

        if (this._extras !== undefined) {
            itemBase.extras = this._extras;
        }

        if (this._sequenceOrder !== undefined) {
            itemBase.sequenceOrder = this._sequenceOrder;
        }

        return itemBase;
    }
}

export interface IContentAPIItemBase {
    id: string;
    code: string;
    names: IContentAPIItemNames;
    descriptions?: IContentAPIDescriptions;
    images?: IContentAPIImages;
    dimensions?: IContentAPIDimensions;
    dimensionSpecs?: IContentAPIDimensionSpecs;
    clearanceDistances?: IContentAPIClearanceSpecs;
    insertionLocations?: Array<LocationType>;
    insertionOffsets?: Array<IContentAPIInsertionOffsets>;
    refCodes?: IContentAPIReferenceCodes;
    classification?: IContentAPIItemClassification;
    catalog: IContentAPICatalogBaseInfo,
    catalogId: string;
    catalogVersionId: string;
    groupRefs?: Array<string>;
    visible: boolean;
    extras?: string;
    sequenceOrder?: number;
    /** @deprecated use IContentAPIItemVariant.geometryInfos.geometryTypes */
    geometryTypes: Array<GeometryType>;
    /** @deprecated use IContentAPIItemVariant.geometryInfos.hasGeometry */
    hasGeometry?: boolean;
}