import { Entity, EntityType, SchemaEntityUtils, authoringStateMgr } from "../";

export const createEntity = async (
    entity: any,
    type: EntityType,
    catalogVersionId: string,
    groupParentCode?: string,
) => {
    switch (type) {
        case EntityType.FEATURES:
            return await CiCAPI.authoring.createCatalogFeature(
                catalogVersionId,
                entity,
            );
        case EntityType.MATERIALS:
            return await CiCAPI.authoring.createCatalogMaterial(
                catalogVersionId,
                entity,
            );
        case EntityType.RESTRICTIONS:
            return await CiCAPI.authoring.createCatalogRestriction(
                catalogVersionId,
                entity,
            );
        case EntityType.ITEMS:
            return await CiCAPI.authoring.createCatalogItem(
                catalogVersionId,
                entity,
            );
        case EntityType.ATTRIBUTES:
            return await CiCAPI.authoring.createCatalogAttribute(
                catalogVersionId,
                entity,
            );
        case EntityType.ITEMGROUPS:
            return await CiCAPI.authoring.createCatalogItemGroup(
                catalogVersionId,
                entity,
                groupParentCode,
            );
    }
};

export const saveEntity = async (
    entity: any,
    type: EntityType,
    catalogVersionId: string,
) => {
    switch (type) {
        case EntityType.FEATURES:
            return await CiCAPI.authoring.updateCatalogFeature(
                catalogVersionId,
                entity,
            );
        case EntityType.MATERIALS:
            return await CiCAPI.authoring.updateCatalogMaterial(
                catalogVersionId,
                entity,
            );
        case EntityType.RESTRICTIONS:
            return await CiCAPI.authoring.updateCatalogRestriction(
                catalogVersionId,
                entity,
            );
        case EntityType.ITEMS:
            return await CiCAPI.authoring.updateCatalogItem(
                catalogVersionId,
                entity,
            );
        case EntityType.ATTRIBUTES:
            return await CiCAPI.authoring.updateCatalogAttribute(
                catalogVersionId,
                entity,
            );
        case EntityType.DEPENDENCIES:
            return await CiCAPI.authoring.updateCatalogDependency(
                catalogVersionId,
                entity.entity,
            );
        case EntityType.ITEMGROUPS:
            return await CiCAPI.authoring.updateCatalogItemGroup(
                catalogVersionId,
                entity,
            );
    }
};

export const deleteEntity = async (
    entity: any,
    type: EntityType,
    catalogVersionId: string,
) => {
    switch (type) {
        case EntityType.FEATURES:
            return await CiCAPI.authoring.deleteCatalogFeature(
                catalogVersionId,
                entity.code,
            );
        case EntityType.MATERIALS:
            return await CiCAPI.authoring.deleteCatalogMaterial(
                catalogVersionId,
                entity.code,
            );
        case EntityType.RESTRICTIONS:
            return await CiCAPI.authoring.deleteCatalogRestriction(
                catalogVersionId,
                entity.code,
            );
        case EntityType.ITEMS:
            return await CiCAPI.authoring.deleteCatalogItem(
                catalogVersionId,
                entity.code,
            );
        case EntityType.ATTRIBUTES:
            return await CiCAPI.authoring.deleteCatalogAttribute(
                catalogVersionId,
                entity.code,
            );
        case EntityType.ITEMGROUPS:
            return await CiCAPI.authoring.deleteCatalogItemGroup(
                catalogVersionId,
                entity.code,
            );
    }
};

export type AuthoringCatalogEntity = {
    type: EntityType;
    id: string;
    catalogVersionId: string;
    code: string;
    entity?: Entity;
    source?: Entity;
    affected?: AuthoringCatalogEntity[];
};
export async function getAuthoringCatalogEntities(
    type: EntityType,
    catalogVersionId: string,
    catalogItems?: AuthoringCatalogEntity[],
): Promise<AuthoringCatalogEntity[]> {
    const entities = await getCatalogEntities(type, catalogVersionId);
    let authoringEntities: AuthoringCatalogEntity[] = [];

    if (entities) {
        switch (type) {
            case EntityType.FEATURES:
            case EntityType.ITEMS:
                authoringEntities = await Promise.all(
                    entities.map(
                        async (
                            entity: ICatalogFeatureDef | ICatalogItemDef,
                        ) => {
                            let source: Entity | undefined;
                            //@ts-ignore
                            if (entity.featureRef || entity.itemRef) {
                                source = await getCatalogEntity(
                                    type,
                                    //@ts-ignore
                                    entity.featureRef || entity.itemRef,
                                    catalogVersionId,
                                );
                            }

                            const code = entity?.code || source?.code;
                            return {
                                entity,
                                id: `${catalogVersionId}.${code}`,
                                type,
                                catalogVersionId,
                                source,
                                code,
                            };
                        },
                    ),
                );

                if (type === EntityType.FEATURES) {
                    const featureRefs = await getFeatureRefs(
                        catalogVersionId,
                        catalogItems || [],
                        authoringEntities.map((e) => e.entity!),
                    );
                    authoringEntities = authoringEntities
                        .map((entity) => {
                            //@ts-ignore
                            if (entity.entity?.featureRef) {
                                entity.code =
                                    //@ts-ignore
                                    entity.entity.featureRef.split(":")[1];
                                //@ts-ignore
                                entity.entity.code = entity.code;
                            }
                            return entity;
                        })
                        .concat(
                            featureRefs?.map((f: ICatalogFeatureDef) => {
                                return {
                                    source: f,
                                    code: f.code,
                                    id: `${catalogVersionId}.${f.code}`,
                                };
                            }) || [],
                        );

                    authoringEntities = authoringEntities.map((entity) => {
                        entity.affected = catalogItems?.filter(
                            (item: AuthoringCatalogEntity) => {
                                const refs =
                                    //@ts-ignore
                                    item.entity?.featureRefs ||
                                    //@ts-ignore
                                    item.source?.featureRefs ||
                                    [];
                                return refs.some(
                                    (ref: any) =>
                                        ref.featureRef ===
                                        entity.code.split(":").at(-1),
                                );
                            },
                        );

                        return entity;
                    });
                }
                break;
            default:
                authoringEntities = entities.map((entity: Entity) => {
                    return {
                        entity,
                        type,
                        catalogVersionId,
                        code: entity.code,
                        id: catalogVersionId + "." + entity.code,
                    };
                });
        }
    }

    return authoringEntities;
}

export async function getCatalogEntities(
    type: EntityType,
    catalogVersionId: string,
) {
    let catalogEntities: any;

    const handleResponse = (resp: { success?: unknown; result?: Entity[] }) => {
        if (resp.success) {
            catalogEntities = resp.result;
        } else {
            console.error(resp);
        }
    };

    switch (type) {
        case EntityType.ITEMS:
            await CiCAPI.authoring
                .getCatalogItemDefs(catalogVersionId)
                .then(handleResponse);
            break;
        case EntityType.RESTRICTIONS:
            await CiCAPI.authoring
                .getCatalogRestrictionDefs(catalogVersionId)
                .then(handleResponse);
            break;
        case EntityType.MATERIALS:
            await CiCAPI.authoring
                .getCatalogMaterialDefs(catalogVersionId)
                .then(handleResponse);
            break;
        case EntityType.FEATURES:
            await CiCAPI.authoring
                .getCatalogFeatureDefs(catalogVersionId)
                .then(handleResponse);
            break;
        case EntityType.ATTRIBUTES:
            await CiCAPI.authoring
                .getCatalogAttributeDefs(catalogVersionId)
                .then(handleResponse);
            break;
        case EntityType.DEPENDENCIES:
            await CiCAPI.authoring
                .getVersionDefById(catalogVersionId)
                .then((resp) => {
                    catalogEntities = [
                        {
                            code: `${catalogVersionId}-dependencies`,
                            entity: resp.result?.catalogDependencies,
                        },
                    ];
                });
            break;
        case EntityType.ITEMGROUPS:
            await CiCAPI.authoring
                .getGroupDefs(catalogVersionId)
                .then(handleResponse);
            break;
    }

    return catalogEntities;
}

export function getEntityName(entity: AuthoringCatalogEntity) {
    //@ts-ignore`
    const names: any = entity.entity?.names || entity.source?.names;

    if (names) {
        return names.main || names.planView || names.others || names;
    } else {
        //@ts-ignore
        const name = entity.entity?.name || entity.source?.name;

        return name || entity.code;
    }
}

export async function getCatalogEntity(
    type: EntityType,
    code: string,
    catalogVersionId: string,
    rootCatalogId?: string,
) {
    let entity: Entity | undefined;
    let response: IAPIResponse<any>;
    let entityId = code.startsWith(catalogVersionId)
        ? code
        : `${catalogVersionId}.${code}`;

    switch (type) {
        case EntityType.ITEMS:
            if (code.includes(":")) {
                response = await CiCAPI.authoring.getCatalogItemDef(
                    code,
                    rootCatalogId || catalogVersionId,
                );
            } else {
                response = await CiCAPI.authoring.getCatalogItemDef(entityId);
            }
            entity = response.result;
            break;
        case EntityType.RESTRICTIONS:
            response =
                await CiCAPI.authoring.getCatalogRestrictionDefById(entityId);
            entity = response.result;
            break;
        case EntityType.ATTRIBUTES:
            const refSplit = code.split(":");

            if (refSplit.length === 2) {
                const catalogRef = refSplit[0] + ":";
                code = refSplit[1].toLowerCase();
                entityId = `${catalogRef}${code}`;
                response = await CiCAPI.authoring.getCatalogAttributeDefs(
                    catalogRef,
                    catalogVersionId,
                );
            } else {
                response =
                    await CiCAPI.authoring.getCatalogAttributeDefs(
                        catalogVersionId,
                    );
            }

            if (response.code === "OK" && response.result) {
                entity = response.result.find(
                    (attribute: ICatalogAttributeDef) =>
                        attribute.code === code,
                );
                if(entity) {
                    //@ts-ignore
                    entity.id = entityId;
                }
            }
            break;
        case EntityType.MATERIALS:
           const materialRefSplit = code?.split(":");
            let materialCode = code;
            if (materialRefSplit && materialRefSplit.length > 1) {
                const [externalCatalog, code] = materialRefSplit;
                materialCode = code;
                response = await CiCAPI.authoring.getCatalogMaterialDefs(externalCatalog + ":", catalogVersionId);
            } else {
                response = await CiCAPI.authoring.getCatalogMaterialDefs(catalogVersionId);
            }
            if (response.success) {
                entity = response.result?.find((mat: IMaterialDef) => mat.code.toLowerCase() === materialCode.toLowerCase());
            }
            break;           
        case EntityType.FEATURES:
            if (code.includes(":")) {
                response = await CiCAPI.authoring.getCatalogFeatureDef(
                    code,
                    rootCatalogId || catalogVersionId,
                );
            } else {
                response = await CiCAPI.authoring.getCatalogFeatureDef(
                    entityId,
                    catalogVersionId,
                );
            }
            entity = response.result;
            break;        
    }

    return entity;
}

export async function getFeatureRefs(
    versionId: string,
    catalogItems: AuthoringCatalogEntity[],
    allEntities: Entity[] = [],
) {
    const featurePromises = catalogItems.map(
        async (item: AuthoringCatalogEntity) => {
            //@ts-ignore
            return _getEntityFeatures(item, versionId);
        }
    );

    const featureResults = (await Promise.all(featurePromises)).flat();
    if (featureResults.length === 0) return null;

    const schema = await authoringStateMgr.schemas.fetchSchema(
        EntityType.FEATURES,
    );
    if (!schema) return null;

    return _mapFeaturesToSchema(featureResults, allEntities, schema);
}

async function _getEntityFeatures(
    item: any,
    versionId: string
): Promise<ICatalogItemDef[]> {
    const code = item?.itemRef ?? item.id;
    const entity = (await getCatalogEntity(
        EntityType.ITEMS,
        code,
        versionId
    )) as ICatalogItemDef;

    if (!entity || !entity.featureRefs) return [];

    const featureRefs = entity.featureRefs;
    const entityVersionId = entity.catalog.versionId;

    const features = await Promise.all(
        featureRefs.map(async (featureRef) => {
            return (await getCatalogEntity(
                EntityType.FEATURES,
                featureRef.featureRef,
                entityVersionId,
            )) as ICatalogItemDef;
        }),
    );
    return features;
}

async function _mapFeaturesToSchema(
    featureResults: ICatalogItemDef[],
    allEntities: Entity[],
    schema: any,
): Promise<any> {
    if (schema && schema.anyOf && schema.anyOf.length > 1) {
        return featureResults
            .map((feature) => {
                const featureRef = authoringStateMgr.catalogs.getCatalogRef(feature.catalog.versionId);
                const tempFeatureRef = SchemaEntityUtils.fromEntityInstance(
                    Object.assign(
                        {
                            featureRef: featureRef ? `${featureRef}:${feature.code}` : feature.code,
                        },
                        feature,
                    ),
                    schema.anyOf![1] as any,
                );

                return Object.assign(tempFeatureRef, {
                    code: tempFeatureRef.featureRef,
                });
            })
            .filter(
                (f) =>
                    !allEntities.find(
                        //@ts-ignore
                        (e) => e.code === f.code || e.featureRef === f.code,
                    ),
            )
            .filter(
                (f, index, self) =>
                    index === self.findIndex((t) => t.code === f.code),
            );
    }

    return null;
}

export function generateCodeFromName(name: string = "") {
    return name
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "_")
        .replace(/ /g, "_");
}
