export class CatalogVersionBaseInfo {
    protected _id: string;
    protected _status: CATALOG_STATUS;
    protected _version: string;

    constructor(catalogVersionBaseInfo: ICatalogVersionBaseInfo) {
        this._id = catalogVersionBaseInfo.id;
        this._status = catalogVersionBaseInfo.status;
        this._version = catalogVersionBaseInfo.version;
    }

    serialize(): IContentAPICatalogVersionBaseInfo {
        return {
            id: this._id,
            status: this._status,
            version: this._version
        };
    }
}

export interface IContentAPICatalogVersionBaseInfo {
    id: string;
    status: CATALOG_STATUS;
    version: string;
}