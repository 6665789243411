export class PricingReportItemAvailabilityFactory {
    static create(pricingReportItemAvailability: IPricingReportItemAvailability): IContentAPIPricingReportItemAvailability {
        return new PricingReportItemAvailability(pricingReportItemAvailability).serialize();
    }
}

export class PricingReportItemAvailability {
    protected _status?: string;
    protected _details?: string;
    protected _inventoryQuantity?: number;

    constructor(pricingReportItemAvailability: IPricingReportItemAvailability) {
        this._status = pricingReportItemAvailability.status;
        this._details = pricingReportItemAvailability.details;
        this._inventoryQuantity = pricingReportItemAvailability.inventoryQuantity;
    }

    serialize(): IContentAPIPricingReportItemAvailability {
        const pricingReportItemAvailability: IContentAPIPricingReportItemAvailability = {};

        if (this._status !== undefined) {
            pricingReportItemAvailability.status = this._status;
        }

        if (this._details !== undefined) {
            pricingReportItemAvailability.details = this._details;
        }

        if (this._inventoryQuantity !== undefined) {
            pricingReportItemAvailability.inventoryQuantity = this._inventoryQuantity;
        }

        return pricingReportItemAvailability;
    }
}

export interface IContentAPIPricingReportItemAvailability {
    status?: string;
    details?: string;
    inventoryQuantity?: number;
}