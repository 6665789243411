// @ts-nocheck -- for deno

/**
 * In this file, we can use the constants which are used globally across multiple libs or apps
 */

export const IS_BROWSER: boolean = typeof window !== "undefined" && typeof window.document !== "undefined";
export const IS_NODE: boolean = typeof process !== "undefined" && process.versions != null && process.versions.node != null;
export const IS_WEB_WORKER: boolean = typeof self === "object" && self.constructor && self.constructor.name === "DedicatedWorkerGlobalScope";

export const MASTER_CONFIG_KEY: string = "master_config";
// for backward compatibility. // TODO: Remove condition in Q2 2025 (when latest version of CDS is pushed) and keep app_config only
export const CONFIG_STORE_NAME: string = !(IS_BROWSER || IS_WEB_WORKER) ? "config" : "app_config"; // was "config "...
// for backward compatibility // TODO: Remove condition in Q2 2025 (when latest version of CDS is pushed) and keep app_cache only
export const CACHE_STORE_NAME: string = !(IS_BROWSER || IS_WEB_WORKER) ? "cache" : "app_cache"; // was "cache" .... 

export const DOWNLOADS_STORE_NAME: string = "downloads";
export const DOWNLOADED_CATALOGS_KEY: string = "downloaded:catalogInfo";
export const DEFAULT_DOWNLOAD_MAX_AGE: number = 7 * 24 * 60 * 60 * 1000;
export const DEFAULT_CHUNK_SIZE: number = 20;  // Maximum number of simultaneous saves (to reduce amount of data flow between processes)

export const USE_DESIGN_SERVICES_CONFIG_KEY: string = "contentPlatform.useDesignServices";

/** 
 * Maximum number of simultaneous delete operations
 * Bigger number causes  following error (especially on windows)
 * "EMFILE: Too many open files" (errno: -4066, code: 'emfile', syscall: 'open',).
 */
export const MAX_DELETE_COUNT: number = 6;

/**
 * Chrome has a limit of 6 connections per host name, and a max of 10 connections.
 * 
 * @link https://bluetriangle.com/blog/blocking-web-performance-villain
 */
export const MAX_CONCURRENT_DOWNLOADS: number = 6;

/**
 * Description of the Offline Response Code (can be used to display the message)
 */
export const OfflineResponseDetails: Record<string, string> = {
    "DOWNLOAD_OK": "Catalog downloaded successfully.",
    "NO_STORAGE": "No storage available.",
    "NO_WEB_MODE": "Cannot download catalog in Web-version",
    "NO_CATALOG_TO_DOWNLOAD": "No catalog to download.",
    "DOWNLOAD_FAILED": "Could not download the catalog.",
    "ALREADY_DOWNLOADED": "This catalog is already downloaded.",
    "REMOVE_OK": "Catalog was successfully removed from downloads.",
    "REMOVE_FAILED": "Could not remove catalog from downloads.",
    "NO_CATALOG_REMOVED": "No catalog was removed.",
    "CANNOT_REMOVE_DEPENDENT": "Cannot remove this catalog as other catalog(s) depend on it.",
    "NO_SPACE_AVAILABLE": "No space available on drive to save the catalog.",
}

/**
 * List of standard and widely used non-standard HTTP request headers
 * 
 * @see https://en.wikipedia.org/wiki/List_of_HTTP_header_fields
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers
 * 
 */
export const STD_HTTP_REQUEST_HEADERS: string[] = [
    // Standard HTTP request headers
    "a-im",
    "accept",
    "accept-charset",
    "accept-datetime",
    "accept-encoding",
    "accept-language",
    "access-control-request-method",
    "access-control-request-headers",
    "authorization",
    "cache-control",
    "connection",
    "content-encoding",
    "content-length",
    "content-md5",
    "content-type",
    "cookie",
    "date",
    "expect",
    "forwarded",
    "from",
    "host",
    "http2-settings",
    "if-match",
    "if-modified-since",
    "if-none-match",
    "if-range",
    "if-unmodified-since",
    "max-forwards",
    "origin",
    "pragma",
    "prefer",
    "proxy-authorization",
    "range",
    "referer",
    "te",
    "trailer",
    "transfer-encoding",
    "user-agent",
    "upgrade",
    "via",
    "warning",
    // Widely used Non-standard HTTP request headers
    "upgrade-insecure-requests",
    "x-requested-with",
    "dnt",
    "x-forwarded-for",
    "x-forwarded-host",
    "x-forwarded-proto",
    "front-end-https",
    "x-http-method-override",
    "x-att-deviceid",
    "x-wap-profile",
    "proxy-connection",
    "x-uidh",
    "x-csrf-token",
    "x-request-id",
    "x-correlation-id",
    "correlation-id",
    "save-data",
    "sec-gpc",
];

/**
 * Map for the error messages returned by the Pricing API
 */
export const PricingErrorMessagesMap: Map<string, string> = new Map([
    ["PriceListNotFound", "Unable to find a valid price list, either it doesn't exist, is expired, inactive or the user is not entitled to it."],
    ["ItemWithoutReferenceCode", "The ref code is missing for the item in the pricing data."],
    ["NoRefCode", "Item has no ref codes, so it can't be priced in the report."],
    ["PriceRuleNotFound", "There is no price rule found for the item."],
    ["PriceValueNotFound", "There is no price value found for the item."],
    ["CatalogNotConfigured", "The catalog for the item is not setup for pricing."],
    ["CustomFunctionNotResolved", "The custom function defined for the pricing could not resolve correctly."],
    ["NonPriceableItem", "Some Items have no available price definitions."]
]);


// #region Frequently used Regular Expressions
/**
 * Regex to check ALL attribute references in the text
 * 
 * @example
 * ```
 * const testString: string = "#this_catalogId##test_material#";
 * const matches: Array<string> | null = testString.match(GLOBAL_ATTRIBUTE_RE);
 * ```
 */
export const GLOBAL_ATTRIBUTE_RE: RegExp = /#.+?#/g;