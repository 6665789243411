import { ClientItemContext, IContentAPIClientItemContext } from "./ClientItemContext";
import { FeatureOption, IContentAPIFeatureOption } from "./FeatureOption";

export class FeatureSelectionFactory {
    static create(featureSelection: V2.IFeatureSelection | IContentAPIFeatureSelection, clientItemContexts?: Array<IContentAPIClientItemContext>): IContentAPIFeatureSelection {
        return new FeatureSelection(featureSelection, clientItemContexts).serialize();
    }
}

export class FeatureSelection {
    protected _option: FeatureOption;
    protected _clientItemContexts?: Array<ClientItemContext>;

    constructor(featureSelection: V2.IFeatureSelection | IContentAPIFeatureSelection, clientItemContexts?: Array<IContentAPIClientItemContext>) {
        this._option = new FeatureOption(featureSelection.option);
        this._clientItemContexts =
            clientItemContexts?.map((clientItemContext: IContentAPIClientItemContext) => new ClientItemContext(clientItemContext)) ??
            featureSelection.clientItemContexts?.map((clientItemContext: V2.IClientItemContext) => new ClientItemContext(clientItemContext));
    }

    get option(): FeatureOption {
        return this._option;
    }

    get clientItemContexts(): Array<ClientItemContext> | undefined {
        return this._clientItemContexts;
    }    
    
    serialize(): IContentAPIFeatureSelection {
        const featureSelection: IContentAPIFeatureSelection = {
            option: this._option.serialize(),
        };

        if (this._clientItemContexts !== undefined) {
            featureSelection.clientItemContexts = this._clientItemContexts.map((clientItemContext: ClientItemContext) => clientItemContext.serialize());
        }

        return featureSelection;
    }
}

export interface IContentAPIFeatureSelection {
    option: IContentAPIFeatureOption;
    clientItemContexts?: Array<IContentAPIClientItemContext>;
}