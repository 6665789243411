import { IContentAPIItemVariant } from "./ItemVariant";
import { ConfigurationState, FeatureState, IContentAPIConfigurationState } from "./ConfigurationState";
import { Dimensions, IContentAPIDimensions } from "./Dimensions";

export class ClientItemFactory {
    static create(clientItem: IClientItem): IContentAPIClientItem {
        return new ClientItem(clientItem).serialize();
    }

    static fromItemVariant(source: IContentAPIItemVariant): IContentAPIClientItem {
        let instanceExtras: Record<string, unknown> | undefined;

        if (source.position !== undefined) {
            instanceExtras = {};
            instanceExtras.position = source.position;
        }

        if (source.orientation !== undefined) {
            instanceExtras ??= {};
            instanceExtras.orientation = source.orientation;
        }

        if (source.selected !== undefined) {
            instanceExtras ??= {};
            instanceExtras.selected = source.selected;
        }

        let clientItem: IClientItem = {
            itemId: source.id,
            configurationState: source.configurationState,
            instanceId: source.uuid,
            position: source.position,
            orientation: source.orientation,
            dimensions: source.dimensions,
            geometries: source.geometriesInfo.geometries,
            subItemScope: source.subItemScope,
            instanceExtras
        };

        return new ClientItem(clientItem).serialize();
    }    
}

export class ClientItemRefFactory {
    static fromItemVariant(itemVariant: IContentAPIItemVariant): IClientItemRef {
        return {
            itemId: itemVariant.id,
            instanceId: itemVariant.uuid,
            configurationState: itemVariant.configurationState
        };
    }
}

export class ClientItem {
    protected _itemId?: string;
    protected _configurationState?: ConfigurationState;
    protected _instanceId?: string;
    protected _refCode?: string;
    protected _instanceExtras?: Record<string, unknown>;
    protected _automations?: Record<string, unknown>;
    protected _position?: V2.Vector3;
    protected _orientation?: V2.Vector3;
    protected _dimensions?: Dimensions;
    protected _geometries?: EmbeddedGeometries;
    protected _source?: string;
    protected _subItemScope?: string;

    constructor(clientItem: IClientItem) {
        this._itemId = clientItem.itemId;

        if (clientItem.configurationState) {
            this._configurationState = [];
            clientItem.configurationState.forEach((featureState: IFeatureState) => {
                this._configurationState?.push(new FeatureState(featureState));
            });
        }

        this._instanceId = clientItem.instanceId;
        this._instanceExtras = clientItem.instanceExtras;
        this._automations = clientItem.automations;
        this._position = clientItem.position as V2.Vector3;
        this._orientation = clientItem.orientation as V2.Vector3;

        if (clientItem.dimensions) {
            this._dimensions = new Dimensions(clientItem.dimensions);
        }

        this._geometries = clientItem.geometries;
        this._source = clientItem.source;
        this._subItemScope = clientItem.subItemScope;
    }

    serialize(): IContentAPIClientItem {
        const clientItem: IContentAPIClientItem = {};

        if (this._itemId !== undefined) {
            clientItem.itemId = this._itemId;
        }

        if (this._configurationState !== undefined) {
            clientItem.configurationState = this._configurationState.map((featureState: FeatureState) => featureState.serialize());
        }

        if (this._instanceId !== undefined) {
            clientItem.instanceId = this._instanceId;
        }

        if (this._refCode !== undefined) {
            clientItem.refCode = this._refCode;
        }

        if (this._instanceExtras !== undefined) {
            clientItem.instanceExtras = this._instanceExtras;
        }

        if (this._automations !== undefined) {
            clientItem.automations = this._automations;
        }

        if (this._position !== undefined) {
            clientItem.position = this._position;
        }

        if (this._orientation !== undefined) {
            clientItem.orientation = this._orientation;
        }

        if (this._dimensions !== undefined) {
            clientItem.dimensions = this._dimensions.serialize();
        }

        if (this._geometries !== undefined) {
            clientItem.geometries = this._geometries;
        }

        if (this._source !== undefined) {
            clientItem.source = this._source;
        }

        if (this._subItemScope !== undefined) {
            clientItem.subItemScope = this._subItemScope;
        }

        return clientItem;
    }
}

export interface IContentAPIClientItem extends IContentAPIClientItemRef {
    refCode?: string;
    instanceExtras?: Record<string, unknown>;
    automations?: Record<string, unknown>;
    position?: V2.Vector3;
    orientation?: V2.Vector3;
    dimensions?: IContentAPIDimensions;
    geometries?: EmbeddedGeometries;
    source?: string;
}

export interface IContentAPIClientItemRef {
    itemId?: string,
    configurationState?: IContentAPIConfigurationState,
    instanceId?: string,
    subItemScope?: string
}