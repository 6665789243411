import { ItemUpdateReportEntry, IContentAPIItemUpdateReportEntry } from "./ItemUpdateReportEntry";

export class ItemUpdateReportFactory {
    static create(itemUpdateReport: IItemUpdateReport): IContentAPIItemUpdateReport {
        return new ItemUpdateReport(itemUpdateReport).serialize();
    }
}

export class ItemUpdateReport {
    protected _entries: Array<ItemUpdateReportEntry>;
    
    constructor(itemUpdateReport: IItemUpdateReport) {
        this._entries = itemUpdateReport.entries?.map((reportItem: IItemUpdateReportEntry) => new ItemUpdateReportEntry(reportItem));
    }

    serialize(): IContentAPIItemUpdateReport {
        return {
            entries: this._entries.map((reportItem: ItemUpdateReportEntry) => reportItem.serialize())
        };
    }
}


export interface IContentAPIItemUpdateReport {
    entries: Array<IContentAPIItemUpdateReportEntry>;
}