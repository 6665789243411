export class PricingTaskFactory {
    static create(pricingTask: IPricingTaskDef): IContentAPIPricingTask {
        return new PricingTask(pricingTask).serialize();
    }
}

export class PricingTask {
    protected _type: string;
    protected _refCode: string;

    constructor(pricingTask: IPricingTaskDef) {
        this._type = pricingTask.type;
        this._refCode = pricingTask.refCode;
    }

    serialize(): IContentAPIPricingTask {
        return {
            type: this._type,
            refCode: this._refCode
        }
    }
}

export interface IContentAPIPricingTask {
    type: string;
    refCode: string;
}