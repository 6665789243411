export class ItemListCalculationStepCustomFunctionFactory {
    static create(itemListCalculationStepCustomFunctionDef: IItemListCalculationCustomFunctionDef): IContentAPIItemListCalculationStepCustomFunction {
        return new ItemListCalculationStepCustomFunction(itemListCalculationStepCustomFunctionDef).serialize();
    }
}

export class ItemListCalculationStepCustomFunction {
    protected _expression: string;
    protected _result: number;
    protected _parameters: Record<string, string>;

    constructor(itemListCalculationStepCustomFunctionDef: IItemListCalculationCustomFunctionDef) {
        this._expression = itemListCalculationStepCustomFunctionDef.expression;
        this._result = itemListCalculationStepCustomFunctionDef.result;
        this._parameters = itemListCalculationStepCustomFunctionDef.parameters;
    }

    serialize(): IContentAPIItemListCalculationStepCustomFunction {
        return {
            expression: this._expression,
            result: this._result,
            parameters: this._parameters
        };
    }
}

export interface IContentAPIItemListCalculationStepCustomFunction {
    expression: string;
    result: number;
    parameters: Record<string, string>;
}