import { IContentAPICatalog } from "./Catalog";
import { IContentAPICatalogClassification } from "./CatalogClassification";
import { IContentAPICatalogVersion } from "./CatalogVersion";
import { IContentAPIImageRef } from "./ImageRef";
import { IContentAPIPricingTaskList } from "./PricingTaskList";

export class CompleteCatalogVersionFactory {
    static create(catalog: IContentAPICatalog, catalogVersion: IContentAPICatalogVersion): IContentAPICompleteCatalogVersion {
        return new CompleteCatalogVersion(catalog, catalogVersion).serialize();
    }
}

/**
 * this class exposes a CatalogVersion, but also contains the information inherited from the parent Catalog so everything can be accessed in one easy place 
 */
export class CompleteCatalogVersion implements IContentAPICompleteCatalogVersion {
    private _consolidatedCatalog: IContentAPICatalog;
    private _consolidatedCatalogVersion: IContentAPICatalogVersion;

    constructor(catalog: IContentAPICatalog, catalogVersion: IContentAPICatalogVersion) {
        this._consolidatedCatalog = catalog;
        this._consolidatedCatalogVersion = catalogVersion;
    }

    get id(): string {
        return this._consolidatedCatalogVersion.id;
    }

    get code(): string {
        return this._consolidatedCatalog.code;
    }

    get name(): string {
        return this._consolidatedCatalog.name;
    }

    get status(): CATALOG_STATUS {
        return this._consolidatedCatalogVersion.status;
    }

    get companyRefCode(): string {
        return this._consolidatedCatalog.companyRefCode;
    }

    get companyDisplayName(): string {
        return this._consolidatedCatalog.companyDisplayName;
    }

    get measurementSystem(): string {
        return this._consolidatedCatalog.measurementSystem;
    }

    get localization(): ILocalization {
        return this._consolidatedCatalog.localization;
    }

    get accessLevel(): string | undefined {
        return this._consolidatedCatalog.accessLevel;
    }

    get createdDate(): string {
        return this._consolidatedCatalogVersion.createdDate;
    }

    get updatedDate(): string {
        return this._consolidatedCatalogVersion.updatedDate;
    }

    get description(): string | undefined {
        return this._consolidatedCatalog.description;
    }

    get image(): IContentAPIImageRef | undefined {
        return this._consolidatedCatalog.image;
    }

    get altImages(): Array<IContentAPIImageRef> | undefined {
        return this._consolidatedCatalog.altImages;
    }

    get classification(): IContentAPICatalogClassification | undefined {
        return this._consolidatedCatalog.classification;
    }

    get externalCodes(): IExternalCodes | undefined {
        return this._consolidatedCatalog.externalCodes;
    }

    get parentCatalogId(): string {
        return this._consolidatedCatalog.id;
    }

    get version(): string {
        return this._consolidatedCatalogVersion.version;
    }

    get availableOffline(): boolean | undefined {
        return this._consolidatedCatalogVersion.availableOffline;
    }

    set availableOffline(value: boolean | undefined) {
        this._consolidatedCatalogVersion.availableOffline = value;
    }

    get accessStatus(): string | undefined {
        return this._consolidatedCatalog.accessStatus;
    }

    get pricing(): IContentAPIPricingTaskList | undefined {
        return this._consolidatedCatalog.pricing;
    }
    
    get rating(): number | undefined {
        return this._consolidatedCatalogVersion.rating;
    }
    
    get certification(): ICatalogCertification | undefined {
        return this._consolidatedCatalogVersion.certification;
    }

    serialize(): IContentAPICompleteCatalogVersion {
        const catalogVersion: IContentAPICompleteCatalogVersion = {
            id: this.id,
            code: this.code,
            name: this.name,
            status: this.status,
            companyRefCode: this.companyRefCode,
            companyDisplayName: this.companyDisplayName,
            measurementSystem: this.measurementSystem,
            localization: this.localization,
            createdDate: this.createdDate,
            updatedDate: this.updatedDate,
            parentCatalogId: this.parentCatalogId,
            version: this.version
        }

        if (this.accessLevel !== undefined) {
            catalogVersion.accessLevel = this.accessLevel;
        }

        if (this.description !== undefined) {
            catalogVersion.description = this.description;
        }

        if (this.image !== undefined) {
            catalogVersion.image = this.image;
        }

        if (this.altImages !== undefined) {
            catalogVersion.altImages = this.altImages.map(image => image);
        }

        if (this.classification !== undefined) {
            catalogVersion.classification = this.classification;
        }

        if (this.externalCodes !== undefined) {
            catalogVersion.externalCodes = this.externalCodes;
        }

        if (this.availableOffline !== undefined) {
            catalogVersion.availableOffline = this.availableOffline;
        }

        if (this.accessStatus !== undefined) {
            catalogVersion.accessStatus = this.accessStatus;
        }

        if (this.pricing !== undefined) {
            catalogVersion.pricing = this.pricing;
        }

        if (this.rating !== undefined) {
            catalogVersion.rating = this.rating;
        }
        
        if (this.certification !== undefined) {
            catalogVersion.certification = this.certification;
        }
        
        return catalogVersion;
    }
}

export interface IContentAPICompleteCatalogVersion {
    id: string;
    code: string;
    name: string;
    status: CATALOG_STATUS;
    companyRefCode: string;
    companyDisplayName: string;
    measurementSystem: string;
    localization: ILocalization;
    accessLevel?: string;
    createdDate: string;
    updatedDate: string;
    description?: string;
    image?: IContentAPIImageRef;
    altImages?: Array<IContentAPIImageRef>;
    classification?: IContentAPICatalogClassification;
    certification?: ICatalogCertification,
    rating?: number,
    externalCodes?: IExternalCodes;
    parentCatalogId: string;
    version: string;
    availableOffline?: boolean;
    accessStatus?: string;
    pricing?: IContentAPIPricingTaskList;
}