import { CatalogVersionBaseInfo, IContentAPICatalogVersionBaseInfo } from "./CatalogVersionBaseInfo";
import { ImageRef, IContentAPIImageRef } from "./ImageRef";

export class CatalogBaseInfo {
    protected _id: string;
    protected _code: string;
    protected _name: string;
    protected _companyDisplayName: string;
    protected _description?: string;
    protected _image?: ImageRef;
    protected _companyRefCode: string;
    protected _measurementSystem: MeasurementSystem;
    protected _catalogVersion?: CatalogVersionBaseInfo;

    constructor(catalogBaseInfo: ICatalogBaseInfo) {
        this._id = catalogBaseInfo.id;
        this._code = catalogBaseInfo.code;
        this._name = catalogBaseInfo.name;
        this._companyDisplayName = catalogBaseInfo.companyDisplayName;
        this._description = catalogBaseInfo.description;
        this._companyRefCode = catalogBaseInfo.companyRefCode;
        this._measurementSystem = catalogBaseInfo.measurementSystem;
        this._catalogVersion = catalogBaseInfo.catalogVersion ? new CatalogVersionBaseInfo(catalogBaseInfo.catalogVersion) : undefined;

        if (catalogBaseInfo.image) {
            this._image = new ImageRef(catalogBaseInfo.image);
        }
    }

    serialize(): IContentAPICatalogBaseInfo {
        const catalogBaseInfo: IContentAPICatalogBaseInfo = {
            id: this._id,
            code: this._code,
            name: this._name,
            companyDisplayName: this._companyDisplayName,
            companyRefCode: this._companyRefCode,
            measurementSystem: this._measurementSystem
        };

        if (this._description !== undefined) {
            catalogBaseInfo.description = this._description;
        }

        if (this._image !== undefined) {
            catalogBaseInfo.image = this._image.serialize();
        }

        if (this._catalogVersion !== undefined) {
            catalogBaseInfo.catalogVersion = this._catalogVersion.serialize();
        }
        
        return catalogBaseInfo;
    }
}

export interface IContentAPICatalogBaseInfo {
    id: string;
    code: string;
    name: string;
    companyDisplayName: string;
    description?: string;
    companyRefCode: string;
    measurementSystem: MeasurementSystem;
    image?: IContentAPIImageRef;
    catalogVersion?: IContentAPICatalogVersionBaseInfo;
}