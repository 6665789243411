export class ImageRefFactory {
    static create(imageRef: IImageRef): IContentAPIImageRef {
        return new ImageRef(imageRef).serialize();
    }
}
    
export class ImageRef {
    protected _uri: string;
    protected _tags?: Array<string>;
    protected _type?: string;

    constructor(imageRef: IImageRef) {
        this._uri = imageRef.uri;
        this._tags = imageRef.tags;
        this._type = imageRef.type;
    }

    serialize(): IContentAPIImageRef {
        const imageRef: IContentAPIImageRef = {
            uri: this._uri
        };

        if (this._tags !== undefined) {
            imageRef.tags = this._tags;
        }

        if (this._type !== undefined) {
            imageRef.type = this._type;
        }

        return imageRef;
    }
}

export interface IContentAPIImageRef {
    uri: string;
    tags?: Array<string>;
    type?: string;
}