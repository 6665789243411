import { EntityType, EntityUtils, SchemaEntityUtils } from "../..";
import { AuthoringStateMgr } from "./AuthoringStateMgr";

export class ItemCopyMgr {
    private authoringMgr: AuthoringStateMgr;

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
    }

    public async createItem(
        catalogId: string,
        code: string,
        name?: string,
        groupRefs: string[] = []
    ) {
        const starterCatalogId = catalogId.split(".")[0];

        this._getItem(catalogId, code, name).then((template) => {
            
            this._generateItemFromOriginal(
                template,
                code,
                name,
                groupRefs,
                starterCatalogId
            ).then((item) => {

                this.authoringMgr.entities.createEntity(
                    EntityType.ITEMS,
                    code,
                    String(this.authoringMgr.state.currentCatalogVersion!.id),
                    item
                );

                this.authoringMgr.notifyChange();
                console.log("Item copied and created successfully");
                return item;
            });
        });          
    }

    private async _getItem(
        templateId: string,
        code: string,
        name?: string
    ) {
        const defaultLocale =
            this.authoringMgr.state.currentCatalog?.localization.default || "en-US";
        let template: ICatalogItemDef | undefined;
        if (templateId !== "blank") {
            template = (await EntityUtils.getCatalogEntity(
                EntityType.ITEMS,
                templateId,
                ""
            )) as ICatalogItemDef;
        }

        if (!template) {
            template = {
                code,
                names: {
                    main: {
                        [defaultLocale]: name || code,
                    },
                },
            } as unknown as ICatalogItemDef;
        }
        return template;
    }

    private async _generateItemFromOriginal(
        template: ICatalogItemDef,
        code: string,
        name?: string,
        groupRefs: string[] = [],
        starterCatalogId: string = ""
    ) {
        const defaultLocale = this.authoringMgr.state.currentCatalog?.localization.default || "en-US";
        const schema = await this.authoringMgr.schemas.fetchSchema(
            EntityType.ITEMS,
            template
        );
        const item: ICatalogItemDef = SchemaEntityUtils.fromEntityInstance(
            template,
            schema
        );

        item.code = code;
        item.groupRefs = groupRefs && groupRefs[0] === "uncategorized" ? [] : groupRefs;
        delete item.classification?.tags; 

        if (name) {
            (item.names.main as Record<string, string>)[defaultLocale] = name;
        }

        this._copyAttributes(item, starterCatalogId);
        this._copyFeatures(item, starterCatalogId);
        this._copyMaterials(item, starterCatalogId);
        this._copyRestrictions(item, starterCatalogId);

        return item;
    }

    private async _copyRestrictions(
        item: ICatalogItemDef,
        starterCatalogId: string
    ) {
        await this.authoringMgr.schemas.fetchSchema(EntityType.RESTRICTIONS);
        const restrictions = item.restrictionRefs || [];

        console.log("Copying Restrictions : ", restrictions.length);
        restrictions.forEach((restrictionRef) => {
            if (!restrictionRef.includes(":")) {
                this.authoringMgr.restrictions.copyRestriction(
                    starterCatalogId,
                    restrictionRef
                );
            }
        });
    }

    private async _copyMaterials(
        item: ICatalogItemDef,
        starterCatalogId: string
    ) {
        await this.authoringMgr.schemas.fetchSchema(EntityType.MATERIALS);
        const materials =
            item.geometries?.main?.layers?.map((l) => l.materialRef) || [];

        console.log("Copying Materials: ", materials.length);
        materials.forEach((materialRef) => {
            if (!materialRef.includes(":")) {
                this.authoringMgr.materials.copyMaterial(
                    starterCatalogId,
                    materialRef
                );
            }
        });
    }

    private async _copyFeatures(
        item: ICatalogItemDef,
        starterCatalogId: string
    ) {
        await this.authoringMgr.schemas.fetchSchema(EntityType.FEATURES);
        const features = item.featureRefs || [];

        console.log("Copying Features: ", features.length);
        features.forEach((featureRef) => {
            if (!featureRef.featureRef.includes(":")) {
                this.authoringMgr.features.copyFeature(
                    starterCatalogId,
                    featureRef.featureRef
                );
            }
        });
    }

    private async _copyAttributes(
        item: ICatalogItemDef,
        starterCatalogId: string
    ) {
        // avoid fetching the schema for each attribute
        await this.authoringMgr.schemas.fetchSchema(EntityType.ATTRIBUTES);
        await CiCAPI.authoring.getCatalogAttributeDefs(starterCatalogId).then((resp: { success?: unknown; result?: ICatalogAttributeDef[] } ) => 
        
        {
            if (resp.success && resp.result) {
                resp.result.forEach((attr) => { 
                    
                    const attrsToCopy = new Set<string>();
                    
                    attrsToCopy.add(attr.code);                        
                    console.log("Copying Attributes: ", attrsToCopy.size);
                    attrsToCopy.forEach((attrToCopy) => {
                        this.authoringMgr.attributes.copyAttribute(
                            starterCatalogId,
                            attrToCopy
                        );
                    });
                    
                });
            }
        });
    }
}
