// noinspection ES6PreferShortImport

import {
    CatalogFactory,
    CatalogVersionFactory,
    ClientItemFactory,
    CompleteCatalogVersionFactory,
    ConfigurationChangeFactory,
    IContentAPICatalog,
    IContentAPICatalogVersion,
    IContentAPIClientItem,
    IContentAPICompleteCatalogVersion,
    IContentAPIConfigurationChange,
    IContentAPIConfigurationState,
    IContentAPIItemFeatureOptions,
    IContentAPIItemFeatures,
    IContentAPIItemPricingReport,
    IContentAPIItemUpdateReport,
    IContentAPIItemVariant,
    IContentAPIItemVariantProposal,
    IContentAPISearchItemsResult,
    ItemFeatureOptionsFactory,
    ItemFeaturesFactory,
    ItemPricingReportFactory,
    ItemUpdateReportFactory,
    ItemVariantFactory,
    ItemVariantProposalFactory,
    SearchItemsResultFactory,
    IContentAPIFeatureState,
    IContentAPICommercialItemList,
    CommercialItemListFactory
} from "./index";

class ContentAPISdkUtils {

    static async getSearchResults(searchText: string, apiHandle: ICiCAPI = CiCAPI, options: IOptionsSearchItems | V2.IOptionsSearchItems): Promise<IAPIResponseWithDetails<IContentAPISearchItemsResult, ICatalogVersionErrorDetails>> {
        let result: IContentAPISearchItemsResult | undefined,
            response: IAPIResponseWithDetails<V2.ISearchItemsResult, ICatalogVersionErrorDetails> = await apiHandle.content.v2.searchItems(searchText, options);

        if (response.result) {
            result = SearchItemsResultFactory.create(response.result);
        } else {
            console.warn("searchItems didn't return results", response.success, response.result, response.details);
            result = SearchItemsResultFactory.create({ total: 0, offset: options.offset ?? 0, nbPerPage: options.nbPerPage ?? 0, items: [] });
        }

        return Object.assign({}, response, { result }) as IAPIResponseWithDetails<IContentAPISearchItemsResult, ICatalogVersionErrorDetails>;
    }

    static async searchItems(searchText: string, apiHandle: ICiCAPI = CiCAPI, options: IOptionsSearchItems | V2.IOptionsSearchItems): Promise<IContentAPISearchItemsResult> {
        let searchItems: IContentAPISearchItemsResult | undefined;

        try {
            let response: IAPIResponseWithDetails<V2.ISearchItemsResult, ICatalogVersionErrorDetails> = await apiHandle.content.v2.searchItems(searchText, options);
            if (response.success && response.result) {
                searchItems = SearchItemsResultFactory.create(response.result);
            } else {
                console.warn("searchItems didn't return results", response.success, response.result, response.details);
            }
        } catch (error) {
            console.error("searchItems::Failed", error);
        }

        if (!searchItems) {
            searchItems = SearchItemsResultFactory.create({ total: 0, offset: options.offset ?? 0, nbPerPage: options.nbPerPage ?? 0, items: [] });
        }

        return searchItems;
    }

    static async getItemVariant(clientItem: IContentAPIClientItem | IClientItemRef, apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetItemVariant | V2.IOptionsGetItemVariant)
        : Promise<IContentAPIItemVariant | undefined> {

        if (!clientItem.itemId) {
            throw new Error("Cannot fetch clientItem without an ID");
        }

        let itemVariant: IContentAPIItemVariant | undefined;
        try {
            let response = await apiHandle.content.v2.getItemVariant(clientItem, options);

            if (response.success && response.result) {
                itemVariant = ItemVariantFactory.create(response.result);
                this._resetClientItemPropertiesInItemVariant(itemVariant, clientItem);
            } else {
                console.warn("getItemVariant didn't return results", response.success, response.result, response.details);
            }
        } catch (error) {
            console.error("getItemVariant::Failed", error);
        }

        return itemVariant;
    }

    static async getItemVariantById(itemId: string, configurationState?: IContentAPIConfigurationState | IConfigurationState, apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetItemVariant | V2.IOptionsGetItemVariant)
        : Promise<IAPIResponseWithDetails<IContentAPIItemVariant, Array<IContentAPIConfigurationChange>>> {

        let clientItem: IClientItemRef = { itemId, configurationState },
            response: IAPIResponseWithDetails<V2.IItemVariant, Array<IConfigurationChange>> = await apiHandle.content.v2.getItemVariant(clientItem, options),
            result: IContentAPIItemVariant | undefined,
            details: Array<IContentAPIConfigurationChange> | undefined;

        if (response.success && response.result) {
            result = ItemVariantFactory.create(response.result);
            if (response.details) {
                details = response.details.map((change: IConfigurationChange) => ConfigurationChangeFactory.create(change));
            }
        } else {
            console.warn("getItemVariant didn't return results", response.success, response.result, response.details);
        }

        return Object.assign({}, response, { result, details }) as IAPIResponseWithDetails<IContentAPIItemVariant, Array<IContentAPIConfigurationChange>>;
    }

    static async getItemVariants(clientItems: Array<IContentAPIClientItem | IClientItemRef>, apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetItemVariant | V2.IOptionsGetItemVariant)
        : Promise<Array<IContentAPIItemVariant>> {

        let uniqueVariants: Map<string, IContentAPIItemVariant> = new Map(),
            promises: Array<Promise<unknown>>,
            itemVariants: Array<IContentAPIItemVariant>;

        promises = clientItems.map(async (clientItem: IContentAPIClientItem | IClientItemRef) => {
            if (clientItem.itemId) {
                let key: string = this._getItemVariantKey(clientItem),
                    response: IContentAPIItemVariant | undefined;

                if (!uniqueVariants.has(key)) {
                    response = await this.getItemVariant(clientItem, apiHandle, options);
                    if (response) {
                        uniqueVariants.set(key, response);
                    }
                }
            }
        });

        await Promise.all(promises);

        itemVariants = clientItems.map((clientItem: IContentAPIClientItem) => {
            let key: string = this._getItemVariantKey(clientItem),
                fetchedVariant: IContentAPIItemVariant | undefined;

            if (uniqueVariants.get(key)) {
                fetchedVariant = Object.assign({}, uniqueVariants.get(key));
                this._resetClientItemPropertiesInItemVariant(fetchedVariant, clientItem);
            }

            return fetchedVariant;
        }).filter((itemVariant: IContentAPIItemVariant | undefined) => itemVariant) as Array<IContentAPIItemVariant>;

        return itemVariants;
    }

    static async getItemVariantsFromCDF(designData: ICommonDesignFormat, apiHandle: ICiCAPI = CiCAPI, options?: V2.IOptionsGetItemVariantsFromCDF, progressCallback?: IProgressCallback)
        : Promise<Array<IContentAPIItemVariant>> {

        let itemVariants: Array<IContentAPIItemVariant> = [];

        try {
            let response: IAPIResponseWithDetails<Array<V2.IItemVariant>, Array<string>> = await apiHandle.content.v2.getItemVariantsFromCDF(designData, options, progressCallback);
            if (response.details?.length) {
                console.error("getItemVariantsFromCDF failed for these item(s): ", response.details);
            }

            if (response.success && response.result) {
                itemVariants = response.result.map((itemVariantRef: V2.IItemVariant) => ItemVariantFactory.create(itemVariantRef));
            } else {
                console.warn("getItemVariantsFromCDF didn't return results", response.success, response.result);
            }
        } catch (e) {
            console.error("getItemVariantsFromCDF::Failed", e);
            itemVariants = [];
        }

        return itemVariants;
    }

    static async getItemAutomationsV1(items: Array<IClientItem | IClientItemRef | IContentAPIClientItem>, apiHandle: ICiCAPI = CiCAPI): Promise<Array<IContentAPIClientItem>> {
        let clientItems: Array<IContentAPIClientItem> = [];

        try {
            let result: IAPIResponse<Array<IClientItem>> = await apiHandle.content.getItemAutomations(items);
            if (result.success && result.result) {
                return result.result.map((item: IClientItem) => ClientItemFactory.create(item));
            } else {
                console.warn("getItemAutomations didn't return results", result.success, result.result);
            }
        } catch (e) {
            console.error("getItemAutomations::Failed", e);
            clientItems = [];
        }

        return clientItems;
    }

    static async getItemAutomations(designData: ICommonDesignFormat, apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetItemAutomations): Promise<Array<IContentAPIClientItem>> {
        let clientItems: Array<IContentAPIClientItem> = [];

        try {
            let result: IAPIResponse<Array<IClientItem>> = await apiHandle.content.v2.getItemAutomations(designData, options);
            if (result.success && result.result) {
                return result.result.map((item: IClientItem) => ClientItemFactory.create(item));
            } else {
                console.warn("getItemAutomationsV2 didn't return results", result.success, result.result);
            }
        } catch (e) {
            console.error("getItemAutomationsV2::Failed", e);
            clientItems = [];
        }

        return clientItems;
    }

    static async getCompleteCatalogVersions(apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetCatalogs): Promise<Array<IContentAPICompleteCatalogVersion>> {
        let catalogs: Array<IContentAPICompleteCatalogVersion> = [];

        try {
            options = Object.assign({}, options, { visibleOnly: false });
            let response: IAPIResponse<Array<V2.ICatalog>> = await apiHandle.content.v2.getCatalogs(options);

            if (response.success && response.result) {
                response.result
                    .forEach((catalogRef: V2.ICatalog) => {
                        let catalog: IContentAPICatalog = CatalogFactory.create(catalogRef);

                        catalogRef.catalogVersions.map((catalogVersionRef: V2.ICatalogVersion) => {
                            let catalogVersion: IContentAPICatalogVersion = CatalogVersionFactory.create(catalogVersionRef);
                            let completeCatalog: IContentAPICompleteCatalogVersion = CompleteCatalogVersionFactory.create(catalog, catalogVersion);

                            catalogs.push(completeCatalog);
                        });
                    });
            } else {
                console.warn("getCompleteCatalogs didn't return results", response.success, response.result);
            }
        } catch (e) {
            console.error("getCompleteCatalogs::Failed", e);
            catalogs = [];
        }

        return catalogs;
    }

    static async getCatalogs(apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetCatalogs): Promise<Array<IContentAPICatalog>> {
        let catalogs: Array<IContentAPICatalog> = [],
            response: IAPIResponse<Array<V2.ICatalog>> = await apiHandle.content.v2.getCatalogs(options);

        if (response.success) {
            response.result?.forEach((catalogRef: V2.ICatalog) => {
                catalogs.push(CatalogFactory.create(catalogRef, catalogRef.catalogVersions));
            });
        } else {
            throw response.code;
        }

        return catalogs;
    }

    static async getCatalog(catalogId: string, apiHandle: ICiCAPI = CiCAPI): Promise<IContentAPICatalog | undefined> {
        let catalog: IContentAPICatalog | undefined;

        try {
            let response: IAPIResponse<V2.ICatalog> = await apiHandle.content.v2.getCatalog(catalogId);
            if (response.success && response.result) {
                catalog = CatalogFactory.create(response.result, response.result.catalogVersions);
            } else {
                console.warn("getCatalog didn't return results", response.success, response.result);
            }
        } catch (e) {
            console.error("getCatalog::Failed", e);
            catalog = undefined;
        }

        return catalog;
    }

    static async downloadCatalogVersion(catalogVersionId: string, apiHandle: ICiCAPI = CiCAPI): Promise<IAPIResponse<OfflineResponseCode>> {
        return apiHandle.content.v2.downloadCatalogVersion(catalogVersionId);
    }

    static async removeDownloadedCatalogVersion(catalogVersionId: string, apiHandle: ICiCAPI = CiCAPI): Promise<IAPIResponse<OfflineResponseCode>> {
        return apiHandle.content.v2.removeDownloadedCatalogVersion(catalogVersionId);
    }

    static async requestCatalogAccess(catalogId: string, apiHandle: ICiCAPI = CiCAPI): Promise<IAPIResponse<RequestAccessStatus>> {
        return apiHandle.content.v2.requestCatalogAccess(catalogId);
    }

    static async getCatalogVersionItemGroups(catalogVersionId: string, apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetCatalogVersionItemGroups): Promise<Array<IItemGroup>> {
        let groups: Array<IItemGroup> = [];
        try {
            let response: IAPIResponse<Array<IItemGroup>> = await apiHandle.content.v2.getCatalogVersionItemGroups(catalogVersionId, options);
            if (response.success && Array.isArray(response.result)) {
                groups = response.result;
            } else {
                console.warn("getCatalogVersionItemGroups didn't return results", response.success, response.result);
            }
        } catch (e) {
            console.error("getCatalogVersionItemGroups::Failed", e);
            groups = [];
        }

        return groups ?? [];
    }

    static async getItemVariantProposals(clientItem: IClientItemRef, apiHandle: ICiCAPI = CiCAPI, options: IOptionsGetItemVariantProposals): Promise<Array<IContentAPIItemVariantProposal>> {
        let variantProposals: Array<IContentAPIItemVariantProposal> = [];
        try {
            let { itemId, configurationState }: IClientItemRef = clientItem;
            if (itemId) {
                const response: IAPIResponse<Array<V2.IItemVariantProposal>> = await apiHandle.content.v2.getItemVariantProposals({ itemId, configurationState }, options);
                if (response.success && Array.isArray(response.result)) {
                    variantProposals = response.result.map((itemVariantProposal: V2.IItemVariantProposal) => ItemVariantProposalFactory.create(itemVariantProposal));
                } else {
                    console.warn("getItemVariantProposals didn't return results", response.success, response.result);
                }
            } else {
                console.warn("getItemVariantProposals didn't receive a proper itemId");
            }
        } catch (e) {
            console.error("getItemVariantProposals::Failed", e);
            variantProposals = [];
        }

        return variantProposals;
    }

    static async getItemDynamicFeatures(item: IClientItemRef, apiHandle: ICiCAPI = CiCAPI, options?: IGetItemDynamicFeaturesOptions): Promise<Array<IDynamicFeature>> {
        let dynamicFeatures: Array<IDynamicFeature> = [];
        try {
            const response: IAPIResponse<Array<IDynamicFeature>> = await apiHandle.content.v2.getItemDynamicFeatures(item, options);
            if (response.success && Array.isArray(response.result)) {
                dynamicFeatures = response.result;
            } else {
                console.warn("getItemDynamicFeatures didn't return results", response.success, response.result);
            }
        } catch (e) {
            console.error("getItemDynamicFeatures::Failed", e);
            dynamicFeatures = [];
        }

        return dynamicFeatures;
    }

    static async getItemFeatures(items: Array<IClientItemRef>, apiHandle: ICiCAPI = CiCAPI, options?: V2.IOptionsGetItemFeatures): Promise<IContentAPIItemFeatures | undefined> {
        let features: IContentAPIItemFeatures | undefined;

        try {
            const response: IAPIResponse<V2.IItemFeatures> = await apiHandle.content.v2.getItemFeatures(items, options);
            if (response.success && response.result) {
                features = ItemFeaturesFactory.create(response.result);
            } else {
                console.warn("getItemFeatures didn't return results", response.success, response.result);
            }
        } catch (e) {
            console.error("getItemFeatures::Failed", e);
            features = undefined;
        }

        return features;
    }

    static async getItemFeatureOptions(items: Array<IClientItemRef>, featureId: string, apiHandle: ICiCAPI = CiCAPI, options?: V2.IOptionsGetItemFeatureOptions)
        : Promise<IContentAPIItemFeatureOptions | undefined> {

        let featureOptions: IContentAPIItemFeatureOptions | undefined;

        try {
            const response: IAPIResponse<V2.IItemFeatureOptions> = await apiHandle.content.v2.getItemFeatureOptions(items, featureId, options);
            if (response.success && response.result) {
                featureOptions = ItemFeatureOptionsFactory.create(response.result);
            } else {
                console.warn("getItemFeatureOptions didn't return results", response.success, response.result);
            }
        } catch (e) {
            console.error("getItemFeatureOptions::Failed", e);
            featureOptions = undefined;
        }

        return featureOptions;
    }

    static async getItemGeometry(clientItem: IClientItemRef, apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetItemGeometry | V2.IOptionsGetItemGeometry)
        : Promise<GLB | undefined> {

        let geometry: GLB | undefined;
        try {
            if (clientItem.itemId) {
                let response: IAPIResponse<GLB> = await apiHandle.content.v2.getItemGeometry(clientItem, options);
                if (response.success && response.result) {
                    geometry = response.result;
                } else {
                    console.warn("getItemGeometry didn't return results", response.success, response.result);
                }
            } else {
                console.warn("getItemGeometry didn't receive a proper itemId");
            }
        } catch (e) {
            console.error("getItemGeometry::Failed", e);
            geometry = undefined;
        }

        return geometry;
    }

    static async getItemUpdateReport(commonDesignFormat: ICommonDesignFormat, apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetItemUpdateReport)
        : Promise<IContentAPIItemUpdateReport | undefined> {

        let response: IAPIResponse<IItemUpdateReport> = await apiHandle.content.v2.getItemUpdateReport(commonDesignFormat, options),
            result: IContentAPIItemUpdateReport | undefined;

        if (response.success && response.result) {
            result = ItemUpdateReportFactory.create(response.result);
        } else {
            console.warn("getItemUpdateReport didn't return results", response.success, response.result);
        }

        return result;
    }

    static async getItemPricingReport(commonDesignFormat: ICommonDesignFormat, apiHandle: ICiCAPI = CiCAPI, options: IOptionsGetItemPricingReport)
        : Promise<IAPIResponseWithDetails<IContentAPIItemPricingReport, IContentAPIItemPricingReport>> {

        let response: IAPIResponseWithDetails<IItemPricingReport, IItemPricingReport> = await apiHandle.content.v2.getItemPricingReport(commonDesignFormat, options),
            result: IContentAPIItemPricingReport | undefined,
            details: IContentAPIItemPricingReport | undefined;

        if (response.success && response.result) {
            result = ItemPricingReportFactory.create(response.result);
        } else {
            console.warn("getItemPricingReport returned", response.success, response.details);
            if (response.details) {
                details = ItemPricingReportFactory.create(response.details);
            }
        }

        return Object.assign({}, response, { result, details }) as IAPIResponseWithDetails<IContentAPIItemPricingReport, IContentAPIItemPricingReport>;
    }

    static async getItemPricingReportV1(items: Array<IClientItemRef>, apiHandle: ICiCAPI = CiCAPI, options: IOptionsGetItemPricingReport)
        : Promise<IAPIResponseWithDetails<IContentAPIItemPricingReport, IContentAPIItemPricingReport>> {

        let response: IAPIResponseWithDetails<IItemPricingReport, IItemPricingReport> = await apiHandle.content.getItemPricingReport(items, options),
            result: IContentAPIItemPricingReport | undefined,
            details: IContentAPIItemPricingReport | undefined;

        if (response.success && response.result) {
            result = ItemPricingReportFactory.create(response.result);
        } else {
            console.warn("getItemPricingReport didn't return results", response.success, response.result, response.details);
            if (response.details) {
                details = ItemPricingReportFactory.create(response.details);
            }
        }

        return Object.assign({}, response, { result, details }) as IAPIResponseWithDetails<IContentAPIItemPricingReport, IContentAPIItemPricingReport>;
    }

    static async getCommercialItemList(commonDesignFormat: ICommonDesignFormat, apiHandle: ICiCAPI = CiCAPI, options?: IOptionsGetCommercialItemList)
        : Promise<IAPIResponseWithDetails<IContentAPICommercialItemList, IContentAPICommercialItemList>> {

        let response: IAPIResponseWithDetails<ICommercialItemList, ICommercialItemList> = await apiHandle.content.v2.getCommercialItemList(commonDesignFormat, options),
            result: IContentAPICommercialItemList | undefined,
            details: IContentAPICommercialItemList | undefined;

        if (response.success && response.result) {
            result = CommercialItemListFactory.create(response.result);
        } else {
            console.warn("getCommercialItemList returned results with possible errors : ", response.success, response.result);
            if (response.details) {
                details = CommercialItemListFactory.create(response.details);
            }
        }

        return Object.assign({}, response, { result, details }) as IAPIResponseWithDetails<IContentAPICommercialItemList, IContentAPICommercialItemList>;
    }

    static async disassembleItem(clientItem: IClientItem, apiHandle: ICiCAPI = CiCAPI): Promise<Array<IContentAPIClientItem>> {
        let disassembledItems: Array<IContentAPIClientItem> = [];
        try {
            const response: IAPIResponse<Array<IClientItem>> = await apiHandle.content.v2.disassembleItem(clientItem);
            if (response.success && Array.isArray(response.result)) {
                disassembledItems = response.result.map((item: IClientItem) => ClientItemFactory.create(item));
            } else {
                console.warn("disassembleItem didn't return results", response.success, response.result);
            }
        } catch (e) {
            console.error("disassembleItem::Failed", e);
            disassembledItems = [];
        }

        return disassembledItems;
    }

    static configurationStatesToCacheKey(configurationState?: IContentAPIConfigurationState, filterBySource: boolean = true): string {
        let cacheKey: string = "[",
            featureStates: Array<IContentAPIFeatureState> = Array.from(configurationState ?? []); // de-ref original list

        //!!!!
        //NOTE: Any changes here should also be reflected in the ItemManager.configurationStatesToCacheKey method
        //!!!!        

        if (featureStates && featureStates.length > 0) {
            Array.from(featureStates)
                .sort(this._sortFeatureStates)
                .forEach((featureState: IContentAPIFeatureState) => {
                    if (!filterBySource || featureState.source !== "default") {
                        let featureScope: string = featureState.subItemScope ? `${featureState.subItemScope}/` : "",
                            featureStateValue: string = featureState.value === undefined ? "" : "=" + featureState.value;

                        cacheKey += `${featureScope}${featureState.featureId}=${featureState.optionId}${featureStateValue};`;
                    }
                });
        }

        return cacheKey += "]";
    }

    static findContainerDivByClassName(node: Element | null, className: string): Element | null {
        if (!node) return null;

        let foundNode: Element | null = null;

        if ((node as HTMLElement).classList.contains(className)) {
            foundNode = node;
        }

        if (!foundNode) {
            foundNode = ContentAPISdkUtils.findContainerDivByClassName(node.parentElement, "doc-selector-entry");
        }

        return foundNode;
    }

    /* to make sure we don't call the refresh too often */
    static debounce(funcToDebounce: Function, debounceTime: number): Function {
        let timeoutHandle: number;
        return function () {
            clearTimeout(timeoutHandle);
            timeoutHandle = setTimeout(() => funcToDebounce.apply(null, Array.from(arguments)), debounceTime) as any;
        };
    }

    private static _getItemVariantKey(clientItem: IContentAPIClientItem | IClientItemRef): string {
        return clientItem.itemId + this.configurationStatesToCacheKey(clientItem.configurationState);
    }

    private static _resetClientItemPropertiesInItemVariant(destinationItemVariant: IContentAPIItemVariant, sourceClientItem: IContentAPIClientItem): void {
        let extras: Record<string, unknown> | undefined = sourceClientItem.instanceExtras;

        if (extras && extras.selected) {
            destinationItemVariant.selected = extras.selected as boolean;
        }

        if (sourceClientItem.position) {
            destinationItemVariant.position = sourceClientItem.position as V2.Vector3;
        }

        if (sourceClientItem.orientation) {
            destinationItemVariant.orientation = sourceClientItem.orientation as V2.Vector3;
        }

        destinationItemVariant.uuid = sourceClientItem.instanceId;
    }

    private static _sortFeatureStates(stateB: IContentAPIFeatureState, stateA: IContentAPIFeatureState): number {
        let valueA: string = `${stateA.featureId}`,
            valueB: string = `${stateB.featureId}`,
            rc: number = 0;

        if (valueA < valueB) {
            rc = -1;
        } else if (valueA > valueB) {
            rc = 1;
        }
        return rc;
    }
}

export default ContentAPISdkUtils;