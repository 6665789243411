export class DescriptionsFactory {
    static create(descriptions: IDescriptions): IContentAPIDescriptions {
        return new Descriptions(descriptions).serialize();
    }
}

export class Descriptions {
    protected _long?: string;
    protected _short?: string;
    protected _moreInfoUri?: string;
    protected _main?: string;
    protected _others?: IOtherDescriptions;

    constructor(descriptions: IDescriptions) {
        this._long = descriptions.long;
        this._short = descriptions.short;
        this._moreInfoUri = descriptions.moreInfoUri;
        this._main = descriptions.main;
        this._others = descriptions.others;
    }

    serialize(): IContentAPIDescriptions {
        const descriptions: IContentAPIDescriptions = {};

        if (this._long !== undefined) {
            descriptions.long = this._long;
        }

        if (this._short !== undefined) {
            descriptions.short = this._short;
        }

        if (this._moreInfoUri !== undefined) {
            descriptions.moreInfoUri = this._moreInfoUri;
        }

        if (this._main !== undefined) {
            descriptions.main = this._main;
        }

        if (this._others !== undefined) {
            descriptions.others = this._others;
        }

        return descriptions;
    }
}

export interface IContentAPIDescriptions {
    long?: string;
    short?: string;
    moreInfoUri?: string;
    main?: string;
    others?: IOtherDescriptions;
}