import { IContentAPIItemVariant } from "./ItemVariant";
import { IContentAPIItemBase, ItemBase } from "./ItemBase";
import { ItemVariantProposal, IContentAPIItemVariantProposal } from "./ItemVariantProposal";

export class SearchItemFactory {
    static create(searchItem: IItem | V2.IItem): IContentAPISearchItem {
        return new SearchItem(searchItem).serialize();
    }

    static fromItemVariant(source: IContentAPIItemVariant): IContentAPISearchItem {
        return source as IContentAPIItemBase as IContentAPISearchItem;
    }
}

export class SearchItem extends ItemBase {
    protected _variantProposals?: Array<ItemVariantProposal>;

    constructor(searchItem: IItem | V2.IItem) {
        super(searchItem);

        if (searchItem.variantProposals?.length) {
            this._variantProposals = [];
            searchItem.variantProposals.forEach((variantProposal: IItemVariantProposal | V2.IItemVariantProposal) => {
                this._variantProposals?.push(new ItemVariantProposal(variantProposal));
            });
        }
    }

    get variantProposals(): Array<ItemVariantProposal> | undefined {
        return this._variantProposals;
    }

    serialize(): IContentAPISearchItem {
        const searchItem: IContentAPISearchItem = {
            ...super.serialize()
        };

        if (this._variantProposals !== undefined) {
            searchItem.variantProposals = this._variantProposals.map((variantProposal: ItemVariantProposal) => variantProposal.serialize());
        }

        return searchItem;
    }
}

export interface IContentAPISearchItem extends IContentAPIItemBase {
    variantProposals?: Array<IContentAPIItemVariantProposal>;
}