import { EntityType } from "../..";
import { AuthoringStateMgr, State } from "./AuthoringStateMgr";

export class FeaturesMgr {
    private authoringMgr: AuthoringStateMgr;
    private state: State;

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
        this.state = this.authoringMgr.state;
    }

    public async copyFeature(sourceCatalogId: string, code: string) {
        if (
            !(await this.authoringMgr.entities.exists(
                EntityType.FEATURES,
                code
            ))
        ) {
            const { entity } = await this.authoringMgr.entities.fetchEntity(
                EntityType.FEATURES,
                code,
                sourceCatalogId,
                undefined,
                true
            );

            const original = entity as ICatalogFeatureDef;
            if (original) {
                this._copyFeatureOptions(sourceCatalogId, original.options);
                original.groupRefs = [];
                this.authoringMgr.entities.createEntity(
                    EntityType.FEATURES,
                    code,
                    String(this.state.currentCatalogVersion!.id),
                    {
                        ...original,
                        code,
                    },
                    true
                );
            }
        }
    }

    private _copyFeatureOptions(
        sourceCatalogId: string,
        options?: ICatalogOptionDef[]
    ) {
        options?.forEach((option) => {
            const attributes = option.itemModification?.attributes || [];
            const layers = option.itemModification?.layers || [];
            const groups = option.groupRefs || [];

            attributes.forEach((attr) => {
                if (attr && !attr.attributeRef.includes(":")) {
                    this.authoringMgr.attributes.copyAttribute(
                        sourceCatalogId,
                        attr.attributeRef
                    );
                }
            });

            layers.forEach((layer) => {
                if (layer && !layer.materialRef.includes(":")) {
                    this.authoringMgr.materials.copyMaterial(
                        sourceCatalogId,
                        layer.materialRef
                    );
                }
            });
            
            if (groups.length > 0) {
                option.groupRefs = [];                
            }            
        });
    }
}
