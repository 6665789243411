export class ItemListProviderTaxFactory {
    static create(itemListProviderTax: IItemListProviderTax): IContentAPIItemListProviderTax {
        return new ItemListProviderTax(itemListProviderTax).serialize();
    }
}

export class ItemListProviderTax {
    protected _amount: number;
    protected _code: string;
    protected _description?: string;

    constructor(itemListProviderTax: IItemListProviderTax) {
        this._amount = itemListProviderTax.amount;
        this._code = itemListProviderTax.code;
        this._description = itemListProviderTax.description;
    }

    serialize(): IContentAPIItemListProviderTax {
        const itemListProviderTax: IContentAPIItemListProviderTax = {
            amount: this._amount,
            code: this._code
        };

        if (this._description !== undefined) {
            itemListProviderTax.description = this._description;
        }

        return itemListProviderTax;
    }
}

export interface IContentAPIItemListProviderTax {
    amount: number;
    code: string;
    description?: string;
}