export class CatalogPropertiesFactory {
    static create(properties: V2.ICatalogProperties): IContentAPICatalogProperties {
        return new CatalogProperties(properties).serialize();
    }
}
    
export class CatalogProperties {
    protected _allowMaterialOverrides?: boolean;
    protected _others?: Record<string, string>;

    constructor(properties: V2.ICatalogProperties) {
        this._allowMaterialOverrides = properties.allowMaterialOverrides;
        this._others = properties.others;
    }

    serialize(): IContentAPICatalogProperties {
        const properties: IContentAPICatalogProperties = {};

        if (this._allowMaterialOverrides !== undefined) {
            properties.allowMaterialOverrides = this._allowMaterialOverrides;
        }

        if (this._others !== undefined) {
            properties.others = this._others;
        }

        return properties;
    }
}

export interface IContentAPICatalogProperties {
    allowMaterialOverrides?: boolean;
    others?: Record<string, string>;
}