import { PricingTask, IContentAPIPricingTask } from "./PricingTask";

export class PricingTaskListFactory {
    static create(pricingTaskList: IPricingTaskListDef): IContentAPIPricingTaskList {
        return new PricingTaskList(pricingTaskList).serialize();
    }
}

export class PricingTaskList {
    protected _showAvailability?: boolean;
    protected _showPricing?: boolean;
    protected _tasks?: Array<PricingTask>;

    constructor(pricingTaskList: IPricingTaskListDef) {
        this._showAvailability = pricingTaskList.showAvailability;
        this._showPricing = pricingTaskList.showPricing;
        this._tasks = pricingTaskList.tasks?.map((task: IPricingTaskDef) => new PricingTask(task));
    }

    serialize(): IContentAPIPricingTaskList {
        const pricingTaskList: IContentAPIPricingTaskList = {};

        if (this._showAvailability !== undefined) {
            pricingTaskList.showAvailability = this._showAvailability;
        }

        if (this._showPricing !== undefined) {
            pricingTaskList.showPricing = this._showPricing;
        }

        if (this._tasks !== undefined) {
            pricingTaskList.tasks = this._tasks.map((task: PricingTask) => task.serialize());
        }

        return pricingTaskList;
    }
}

export interface IContentAPIPricingTaskList {
    showAvailability?: boolean;
    showPricing?: boolean;
    tasks?: Array<IContentAPIPricingTask>;
}