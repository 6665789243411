import { IConsolidatedItemVariant, IConsolidatedFeatureOption, IConsolidatedFeature, IConsolidatedClientItem, IConsolidatedItemVariantProposal, IConsolidatedCatalog, IConsolidatedCatalogVersion, IConsolidatedFeatureState, IConsolidatedConfigurationState } from "@cic/ui-toolkit/src/models";
import { IAppMessage } from "@cic/ui-toolkit/src/models/IAppMessage";
import { vec3, vec2 } from "gl-matrix";
import DesignState from "./DesignState";
import {
    CommonUtils,
    GeometryUtils,
    MeasurementUtils,
    NumberUtils,
    ObjectUtils,
    ROUNDING_1_32_INCH_PRECISION,
    VectorUtils, LOCATION_TYPE_ON_FLOOR
} from "@cic/utils";
import DesignItem, { isTallItem, isWallItem } from "./DesignItem";
import SelectionMgr from "./SelectionMgr";
import EventBus, { EVENT } from "./EventBus";
import md5 from "md5";
import { CiCUtils } from "@cic/cic-utils";
import UIToolkitUtils, { USE_DESIGN_SERVICES_CONFIG_KEY } from "@cic/ui-toolkit/src/UIToolkitUtils";

export type TresAmigos = [number, number, number];

export interface IDesignSerializeOptions {
    spacesOnly?: boolean
}

export interface IAddItemOptions {
    apiHandle?: ICiCAPI,
    instanceId?: string,
    position?: Vector3,
    select?: boolean,
    positionLeftOfSelected?: boolean,
    orientation?: Vector3,
    configurationState?: IConsolidatedConfigurationState,
    extras?: Record<string, unknown>,
    source?: string,
    geometries?: EmbeddedGeometries,
    collisionBox?: ICollisionBox,
    linkToItemInstanceIds?: Array<string>
}

interface IPose {
    position: vec3,
    orientation: vec3
}

const WALL_CABINET_STARTING_HEIGHT: number = 2000 * 80; // 2000mm in 2032
const MIN_HEIGHT_FROM_FLOOR: number = 1000 * 80; // 1000mm / 1m in 2032
const MAX_SNAP_DISTANCE: number = 1500 * 80; // 1500mm / 1.5m in 2032
const STRUCTURAL_ITEM_CATALOG_ID: string = "668"; // "Content Dev Tests" catalog contains structural items (walls, floor, ceiling) used for interop testing. Latest activated version will be used. TODO: Review to use CatalogCode. 
const SNAP_DISTANCE_MM: number = 80 * 80; // 80 mm in 2032

//=============================================================================
class DesignEngine extends EventBus {
    #_APIHandle: ICiCAPI;

    private _itemsMap: Map<string, DesignItem>;
    private _isLoadInProgress: boolean = false;
    private _removeInProgress: boolean = false;
    private _replaceInProgress: boolean = false;
    private _isAutomationInProgress: boolean = false;
    private _designState: DesignState;
    private _useDesignServices: boolean;

    selectionMgr: SelectionMgr;

    //=========================================================================
    constructor() {
        super();

        this.#_APIHandle = CiCAPI.getAPIHandle("DesignEngine");
        this._itemsMap = new Map();
        this._designState = new DesignState(this.#_APIHandle.version);
        this.selectionMgr = new SelectionMgr();
        this.selectionMgr.registerToNotifications((eventName: EVENT, data?: any) => {
            this._notify(eventName, data);
        });

        if (typeof (window) !== undefined) {
            window.addEventListener("message", (msgEvent: MessageEvent) => {
                if (msgEvent.data.type === "decoStateFullUpdate") {
                    this.setFullDecoState(msgEvent.data.decoState);
                }
            });
        }

        this._useDesignServices = this.#_APIHandle.getConfig(USE_DESIGN_SERVICES_CONFIG_KEY) as boolean;
        this.#_APIHandle.registerEventListener("configChange", (changeEvent: APIConfigChange) => {
            if (changeEvent.configPath === USE_DESIGN_SERVICES_CONFIG_KEY) {
                this._useDesignServices = changeEvent.value as boolean;
            }
        });

        // TEMPORARY FOR DEBUGGING??
        // @ts-ignore
        window.DesignEngine = this;
    }

    //=========================================================================
    addToDecoState(featureId: string, optionId: string, value?: number): void {
        this._designState.addToGlobalConfigurationState(featureId, optionId, value);
    }

    //=========================================================================
    removeFromDecoState(featureId: string): void {
        this._designState.removeFromGlobalConfigurationState(featureId);
    }

    //=========================================================================
    setFullDecoState(configurationState: IConsolidatedConfigurationState): void {
        this._designState.setGlobalConfigurationState(configurationState);
    }

    //=========================================================================
    getItemList(): Array<DesignItem> {
        return Array.from(this._itemsMap.values());
    }

    //=========================================================================
    getItemByInstanceId(instanceId: string): DesignItem | undefined {
        return this._itemsMap.get(instanceId);
    }

    //=========================================================================
    async applyPoseList(poseList: Array<IItemPose>) {
        let updateItems: Array<DesignItem> = [];

        poseList.forEach((pose: IItemPose) => {
            let designItem: DesignItem | undefined = this.getItemByInstanceId(pose.itemInstanceId);
            if (designItem) {
                // check if position is close to either PLBB or PRBB
                let pos: vec3 = pose.position ?? vec3.create(),
                    ori: vec3 = pose.orientation ?? vec3.create(),
                    snapPosition: vec3 | undefined,
                    snapOrientation: vec3 | undefined;

                // pos received are in mm
                pos = vec3.scale(pos, pos, 80);

                designItem.setPose(pos as Float32Array, ori as Float32Array);
                if (designItem.itemInstance.classification?.baseItemType !== "Wall" && poseList.length === 1) { // only try to snap if you're moving a single item
                    [snapPosition, snapOrientation] = this._getSnapPosition(designItem);
                    if (snapPosition) {
                        pos = snapPosition;
                        if (snapOrientation) {
                            ori = snapOrientation;
                        }
                        designItem.setPose(pos as Float32Array, ori as Float32Array);
                    }
                }

                // then snap and adjust Ori ?
                updateItems.push(designItem);
            }
        });

        await this._evaluateAutomationRules();

        this._notify(EVENT.ITEMS_UPDATED, updateItems);
    }

    //=========================================================================
    async replaceItem(itemId: string, options?: IAddItemOptions) {
        // get selection
        let selectedItems: Array<DesignItem>,
            itemToReplace: DesignItem;

        if (this.selectionMgr.hasSelectedItems()) {
            selectedItems = this.selectionMgr.getSelectedItems();
            if (selectedItems.length === 1) {
                this._replaceInProgress = true;
                itemToReplace = selectedItems[0];
                this.removeItem(itemToReplace);
                let scaleFactor: number = await this._getScaleFactor(itemToReplace.itemInstance.catalogId as string);
                let replacedItem: DesignItem | undefined = await this.addItem(itemId, Object.assign(options || {}, {
                    position: itemToReplace.position.map((num: number) => num / scaleFactor) as TresAmigos,
                    orientation: itemToReplace.orientation,
                    select: false
                }));

                if (replacedItem) {
                    this.selectionMgr.selectItem(replacedItem);
                }

                this._replaceInProgress = false;

                return replacedItem;
            } else {
                console.warn("DesignEngine:replaceItem::Can't replace item, selection holds multiple items, please use single selection.");
            }
        } else {
            console.warn("DesignEngine:replaceItem::Can't replace item, no existing item selected.");
        }
    }

    //=========================================================================
    async addItem(itemId: string, options?: IAddItemOptions): Promise<DesignItem | undefined> {
        let apiResponse: IAPIResponseWithDetails<IConsolidatedItemVariant, Array<IConfigurationChange>>,
            itemVariant: IConsolidatedItemVariant | void,
            designItem: DesignItem | undefined,
            selectedItem: DesignItem,
            position: vec3 | undefined,
            orientation: vec3 | undefined,
            configurationState: Array<IConsolidatedFeatureState> | undefined = options?.configurationState,
            linkToItemInstanceIds: Array<string> = options?.linkToItemInstanceIds ?? [];

        if (this._designState.info?.globalConfigurationState) {
            configurationState = configurationState ?? [];
            let catalogId: string = itemId.split(".")[0],
                applicableStates: Array<IConsolidatedFeatureState> =
                    this._designState.info.globalConfigurationState
                        .filter((featureState: IConsolidatedFeatureState) =>
                            configurationState!.findIndex((currentFeatureState: IConsolidatedFeatureState) => featureState.featureId === currentFeatureState.featureId) < 0 &&
                            featureState.featureId.startsWith(catalogId)
                        );

            configurationState = applicableStates.concat(configurationState);
        }

        apiResponse = await UIToolkitUtils.getItemVariantById(itemId, configurationState, this.#_APIHandle, { includeSubItems: true, extendedOptions: { includeFloorInstallationOffsets: true } });
        if (apiResponse.success) {
            itemVariant = apiResponse.result;
            if (itemVariant) {
                let scaleFactor: number;

                options ??= {};
                scaleFactor = await this._getScaleFactor(itemVariant.catalogId!);

                if (options?.position) {
                    position = vec3.fromValues(...Array.from(options.position).map((val: number) => val * scaleFactor) as TresAmigos);
                }

                // get collision box because the data is not reliable...
                let collisionBox: ICollisionBox = await CiCUtils.getItemCollisionBox(itemId, configurationState, "2032s");
                options.collisionBox = collisionBox;

                if (this.selectionMgr.hasSelectedItems() && !position) {
                    selectedItem = this.selectionMgr.getSelectedItems()[0];
                    if (selectedItem) {
                        // place item
                        let pose: IPose = this._getItemPlacement(itemVariant, collisionBox, selectedItem, options?.positionLeftOfSelected);
                        position = pose.position;
                        orientation = pose.orientation;
                    }
                }

                if (isWallItem(itemVariant) && !position) {
                    let height: number = Number(itemVariant.dimensions?.height ?? 600);

                    position = vec3.create();

                    if (itemVariant.dimensionSpecs?.height?.measurementUnit) {
                        height = MeasurementUtils.convertUnit(height, itemVariant.dimensionSpecs?.height?.measurementUnit, "2032s");
                    }

                    position[2] = NumberUtils.roundToPrecision(WALL_CABINET_STARTING_HEIGHT - height, ROUNDING_1_32_INCH_PRECISION);
                }

                if (position) {
                    options.position = position;
                }

                if (orientation) {
                    options.orientation = orientation;
                }

                designItem = this._instantiateItem(itemVariant, options);
                let designPosition: vec3 = designItem.position;
                let offset: vec3 = designItem.positionOffset;
                vec3.rotateZ(offset, offset, vec3.create(), MeasurementUtils.degToRad(designItem.orientation[2]));
                vec3.add(designPosition, designPosition, offset);

                // There is currently a conflict here between positioning the first item and positioning subsequent items.
                // We can only apply the insertionOffset if we are dealing with the first item, or if zero value position.
                let isFirstItem = this._itemsMap.size == 1;
                let isZeroPosition = vec3.equals(designItem.position, vec3.create());
                if ((isFirstItem || isZeroPosition) && itemVariant.insertionOffsets) {
                    // only use onFloor if there are no installationType (undefined)
                    let offset: IInsertionOffset = GeometryUtils.findInInsertionOffsets(itemVariant.insertionOffsets, LOCATION_TYPE_ON_FLOOR, undefined);
                    let insertionOffset: Vector3Str = offset.position as Vector3Str;
                    let insertionOffsetVec: vec3 = vec3.fromValues(...insertionOffset.map((numStr: string) => Number(numStr)) as TresAmigos);

                    // scale insertion offset in 2032s
                    vec3.scale(insertionOffsetVec, insertionOffsetVec, scaleFactor); // negate the offset

                    vec3.add(designPosition, designPosition, insertionOffsetVec);
                }
                designItem.setPosition(designPosition);

                if (designItem && linkToItemInstanceIds.length > 0) {
                    linkToItemInstanceIds.forEach((instanceId: string) => {
                        let linkedItem: DesignItem | undefined = this.getItemByInstanceId(instanceId);

                        if (linkedItem) {
                            // linkedItem.addLinkedItem(designItem as DesignItem); // bi-directional link
                            designItem?.addLinkedItem(linkedItem);
                        }
                    });
                }

                await this._evaluateAutomationRules();

                designItem = this.getItemByInstanceId(designItem.instanceId); // make sure to get the updated version

                this._notify(EVENT.ITEM_ADDED, designItem);
            }
        } else {
            if (typeof (window) !== undefined && window.postMessage) {
                window.postMessage({
                    source: {
                        component: "test-design-engine",
                        action: "addItem"
                    },
                    type: "app_msg",
                    isError: true,
                    message: `Unable to add item ${itemId}. Error: ${apiResponse.message}`,
                    context: itemId
                } as IAppMessage, "*");
            }
        }

        return designItem;
    }

    async applyItemsOptionSelections(instanceIdList: Array<string>, optionSelections: IConsolidatedFeatureState[]) {
        let st: number = performance.now(),
            promises: Array<Promise<unknown>>,
            designItems: Array<DesignItem> = instanceIdList
                .map((instanceId: string) => this.getItemByInstanceId(instanceId))
                .filter((designItem: DesignItem | undefined) => designItem) as Array<DesignItem>;

        promises = designItems.map(async (designItem: DesignItem) => {
            await designItem.applyOptionSelections(optionSelections);
            designItem.updateWorldBBox();
        });

        await Promise.all(promises);
        console.log("Time to apply item option selections: ", performance.now() - st);
        await this._evaluateAutomationRules();

        this._notify(EVENT.ITEMS_UPDATED, designItems);
    }

    //=========================================================================
    async applyItemsFeatureOption(instanceIdList: Array<string>, featureOption: IConsolidatedFeatureOption, feature: IConsolidatedFeature) {
        let st: number = performance.now(),
            promises: Array<Promise<unknown>>,
            designItems: Array<DesignItem> = instanceIdList
                .map((instanceId: string) => this.getItemByInstanceId(instanceId))
                .filter((designItem: DesignItem | undefined) => designItem) as Array<DesignItem>;

        promises = designItems.map(async (designItem: DesignItem) => {
            await designItem.applyFeatureOption(feature.id, featureOption.id, featureOption.value, feature.path);
            designItem.updateWorldBBox();
        });

        await Promise.all(promises);
        console.log("Time to apply feature option: ", performance.now() - st);
        await this._evaluateAutomationRules();

        this._notify(EVENT.ITEMS_UPDATED, designItems);
    }

    //=========================================================================
    async applyItemFeatureOption(instanceId: string, featureOption: IConsolidatedFeatureOption, feature: IConsolidatedFeature) {
        let designItem: DesignItem | undefined = this.getItemByInstanceId(instanceId);

        if (designItem) {
            await designItem.applyFeatureOption(feature.id, featureOption.id, featureOption.value);
            if (!this._isBusy()) {
                this._notify(EVENT.ITEMS_UPDATED, [designItem]);
            }
        }
    }

    //=========================================================================
    async removeItemListByInstanceIds(instanceIdList: Array<string>): Promise<void> {
        let itemsToRemove: Array<DesignItem> = [],
            importantItems: boolean = true;

        this._removeInProgress = true;

        instanceIdList.forEach((instanceId: string) => {
            let designItem: DesignItem | undefined = this.getItemByInstanceId(instanceId);
            let baseItemType: string = designItem?.itemInstance.classification?.baseItemType ?? "unknown";

            importantItems = !/deco|wall|floor|ceiling|unknown|covering/i.test(baseItemType);

            if (designItem) {
                itemsToRemove.push(designItem);
                this.removeItem(designItem);
            }
        });

        this._removeInProgress = false;
        if (!this._isBusy()) {
            if (importantItems) {
                await this._evaluateAutomationRules();
            }

            this._notify(EVENT.ITEMS_REMOVED, itemsToRemove);
        }
    }

    //========================================================================= 
    async mirrorItems(itemsToMirror: Array<IConsolidatedClientItem>, mirrorProperties: IMirroringProperties): Promise<void> {
        let promises = itemsToMirror.map(async (itemToMirror: IConsolidatedClientItem) => {
            let instanceId: string | undefined = itemToMirror.instanceId,
                itemId: string | undefined = itemToMirror.itemId;

            if (instanceId && itemId) {
                let originalItem: DesignItem | undefined = this.getItemByInstanceId(instanceId);
                if (originalItem) {
                    this.selectionMgr.clearSelection(true);
                    this.selectionMgr.selectItem(originalItem, true);

                    console.log(`DesignEngine::mirrorItems - Original: ${itemId} [${instanceId}] on ${mirrorProperties.axis} axis with ${mirrorProperties.direction > 0 ? "positive" : "negative"} direction`);
                    let proposalOptions: IOptionsGetItemVariantProposals = { variesBy: "mirror" },
                        variantProposals: Array<IConsolidatedItemVariantProposal> = await UIToolkitUtils.getItemVariantProposals({ itemId, configurationState: originalItem.configurationState }, this.#_APIHandle, proposalOptions);

                    if (variantProposals?.length) {
                        let variantProposal: IConsolidatedItemVariantProposal = variantProposals[0],
                            options: IAddItemOptions = {
                                configurationState: variantProposal.proposedConfigurationState,
                                positionLeftOfSelected: mirrorProperties.direction < 0,
                                select: true
                            },
                            mirrorItem: DesignItem | undefined = await this.addItem(variantProposal.id, options);

                        if (mirrorItem) {
                            this.selectionMgr.clearSelection(true);
                            this.selectionMgr.selectItem(mirrorItem);
                            console.log(`DesignEngine::mirrorItems - Mirrored: ${itemId} [${mirrorItem.id}]`);
                        }
                    }
                } else {
                    console.error("DesignEngine::mirrorItems - Unable to find item instance in DesignEngine: ", instanceId);
                }
            }
        });

        await Promise.all(promises);
    }

    //=========================================================================
    removeItemByInstanceId(instanceId: string) {
        let itemToRemove: DesignItem | undefined = this.getItemByInstanceId(instanceId);

        if (itemToRemove) {
            this.removeItem(itemToRemove);
        }
    }

    //=========================================================================
    removeItem(itemToRemove: DesignItem) {
        if (itemToRemove) {
            // unlink this item from all other items
            this
                .getItemList()
                .forEach((designItem: DesignItem) => {
                    if (designItem.isItemLinked(itemToRemove)) {
                        designItem.removeLinkedItem(itemToRemove);
                    }
                });
            this.selectionMgr.unselectItem(itemToRemove, this._isBusy());
            this._itemsMap.delete(itemToRemove.instanceId);

            if (!this._isBusy()) {
                this._notify(EVENT.ITEM_REMOVED, itemToRemove);
            }
        }
    }

    //=========================================================================
    async clear(): Promise<void> {
        this._designState = new DesignState(this.#_APIHandle.version, CommonUtils.genGUID());
        return this.removeItemListByInstanceIds(Array.from(this._itemsMap.keys()));
    }

    //=========================================================================
    async serialize(options?: IDesignSerializeOptions): Promise<ICommonDesignFormat> {
        if (options?.spacesOnly) {
            return ObjectUtils.cloneObj({ info: this._designState.info, spaces: this._designState.spaces });
        } else {
            let allItems: Array<DesignItem> = this.getItemList().filter((designItem: DesignItem) => designItem.source !== "automation");
            await this._designState.setItems(allItems);

            // Will use base class JSONStringifier.toJSON() automatically only return public getters of DesignState and all linked data (i.e. Info, Spaces, etc.)
            return JSON.parse(JSON.stringify(this._designState)) as ICommonDesignFormat;
        }
    }

    //=========================================================================
    async load(receivedData: unknown, progressCallback?: IProgressCallback): Promise<void> {
        let loadedInstanceIds: Array<string> = [],
            serializedDesignItems: Array<ICommonDesignItem> | undefined,
            serializedDesign: ICommonDesignFormat | undefined;

        this._isLoadInProgress = true;
        let st: number = performance.now();
        console.log("[-] Loading design state...");
        await this.clear();

        if (Array.isArray(receivedData)) {
            serializedDesignItems = receivedData as Array<ICommonDesignItem>;
            console.log("[-] Detected Legacy Format. Received ", serializedDesignItems.length, " item(s).");
        } else {
            serializedDesign = receivedData as ICommonDesignFormat;
            console.log("[-] Detected Interop Design Model Format Version: ", serializedDesign.info?.formatVersion || "N/A");
        }

        if (serializedDesignItems) {
            loadedInstanceIds = await this._deserializeItems({ items: serializedDesignItems } as ICommonDesignFormat);
        } else if (serializedDesign) {
            this._designState = new DesignState(CommonUtils.genGUID(), this.#_APIHandle.version);
            this._designState.load(serializedDesign);

            loadedInstanceIds = await this._mapToDesignItems(serializedDesign, progressCallback);
        } else {
            console.log("[!] Unknown Design Format. Aborting: ", performance.now() - st, "ms");
        }

        // await this._evaluateAutomationRules();
        console.log("[+] Design State for ", loadedInstanceIds.length, " item(s) were loaded. Took: ", performance.now() - st, "ms");

        this.selectionMgr.clearSelection(true);
        this.selectionMgr.setSelectedItems(this.getItemList());

        this._isLoadInProgress = false;
        this._notify(EVENT.DESIGN_STATE_LOADED, { loadedInstanceIds });
    }

    private async _getScaleFactor(catalogId: string): Promise<number> {
        let catalog: IConsolidatedCatalog | undefined = await UIToolkitUtils.getCatalog(catalogId, this.#_APIHandle),
            scaleFactor: number;

        if (catalog) {
            scaleFactor = catalog.measurementSystem === "Metric" ? 80 : 2032;
        } else {
            scaleFactor = 1;
        }

        return scaleFactor;
    }

    //=========================================================================
    private _getItemPlacement(itemVariant: IConsolidatedItemVariant, collisionBox: ICollisionBox, selectedItem: DesignItem, positionLeftOfSelected?: boolean): IPose {
        let pose: IPose;

        // check if selectedItem is structural
        if (selectedItem.isStructuralItem) {
            pose = this._getStructuralItemPlacement(itemVariant, collisionBox, selectedItem);
        } else {
            // check if itemVariant is structural 
            pose = this._getCabinetItemPlacement(itemVariant, collisionBox, selectedItem, positionLeftOfSelected);
        }

        return pose;
    }

    //=========================================================================
    private _getCabinetItemPlacement(itemVariant: IConsolidatedItemVariant, collisionBox: ICollisionBox, selectedItem: DesignItem, positionLeftOfSelected?: boolean): IPose {
        let position: Vector3,
            orientation: Vector3,
            itemWidth: number = Math.abs(collisionBox.max[0] - collisionBox.min[0]),
            itemDepth: number = Math.abs(collisionBox.max[1] - collisionBox.min[1]),
            isTopAligned: boolean = false,
            isCornerItem: boolean = selectedItem.isCornerItem,
            isBlindLeftCornerItem: boolean = selectedItem.isBlindLeftCornerItem,
            isBadCornerItem: boolean = selectedItem.isBadCornerItem;

        if (selectedItem.isWallItem && !(isWallItem(itemVariant) || isTallItem(itemVariant))) {
            return { position: vec3.create(), orientation: vec3.create() }; // safeguard
        } else if ((selectedItem.isWallItem || selectedItem.isTallItem) && isWallItem(itemVariant)) {
            isTopAligned = true;
        }

        if (positionLeftOfSelected) {
            let length: number = -Number(itemWidth);
            position = VectorUtils.getVectorPosition(vec3.fromValues(1, 0, 0), length, selectedItem.position, selectedItem.orientation);
            orientation = selectedItem.orientation as Float32Array;
        } else {
            if (isCornerItem && !(isBlindLeftCornerItem || isBadCornerItem)) {
                // check if it's a blind corner item
                position = vec3.clone(selectedItem.posRightFrontBottom) as Float32Array;
                orientation = selectedItem.orientation as Float32Array;
                orientation[2] -= 90;
            } else {
                position = vec3.clone(selectedItem.posRightBackBottom) as Float32Array;
                orientation = selectedItem.orientation as Float32Array;
                // if itemvariant is a blind left corner item
                if (this._isNotSooBadCabinet(itemVariant)) {

                    position = VectorUtils.getVectorPosition(vec3.fromValues(1, 0, 0), itemDepth, position, selectedItem.orientation);
                    // position is right back bottom + depth of blind corner
                    orientation[2] -= 90;
                }
            }
        }

        if (isTopAligned) {
            let height: number = Number(itemVariant.dimensions?.height ?? 600);

            if (itemVariant.dimensionSpecs?.height?.measurementUnit) {
                height = MeasurementUtils.convertUnit(height, itemVariant.dimensionSpecs?.height?.measurementUnit, "2032s");
            }
            position[2] = Math.round(selectedItem.posLeftBackTop[2] - height);
        }

        // TODO: place wall cabinet at proper height, if initially placed
        // TODO: top align if wall items
        return { position, orientation };
    }

    private _isNotSooBadCabinet(itemVariant: IConsolidatedItemVariant): boolean {
        return Boolean(ObjectUtils.existsIn(itemVariant.classification?.characteristics?.cabinetType, "corner") &&
            (
                ObjectUtils.existsIn(itemVariant.classification?.characteristics?.shapeType, "blind") &&
                ObjectUtils.existsIn(itemVariant.classification?.characteristics?.frameDirection, "left")
            ) || (
                ObjectUtils.existsIn(itemVariant.classification?.characteristics?.shapeType, "diagonal") &&
                ObjectUtils.existsIn(itemVariant.classification?.characteristics?.openingDirection, "right") &&
                ObjectUtils.existsIn(itemVariant.classification?.characteristics?.primaryFunctions, "sink")
            )
        );
    }

    //=========================================================================
    private _getStructuralItemPlacement(itemVariant: IConsolidatedItemVariant, collisionBox: ICollisionBox, selectedWallItem: DesignItem): IPose {
        let position: Vector3,
            orientation: Vector3,
            itemWidth: number = Math.abs(collisionBox.max[0] - collisionBox.min[0]);

        if (itemVariant.classification?.baseItemType && /door|window|recess/i.test(itemVariant.classification.baseItemType)) {

            let wallWidth: number = selectedWallItem.dimensions.width ?? 0;
            position = vec3.fromValues(1, 0, 0) as Float32Array;
            orientation = vec3.create() as Float32Array;
            vec3.scale(position, position, (wallWidth / 2) - (itemWidth / 2));

            if (itemVariant.insertionOffsets) {
                if (itemVariant.insertionOffsets[0].position) {
                    let posOffset: vec3 = vec3.fromValues(...itemVariant.insertionOffsets[0].position.map((numStr: string) => Number(numStr)) as TresAmigos);
                    vec3.add(position, position, posOffset);
                }
            }

            if (/window|recess/i.test(itemVariant.classification?.baseItemType)) {
                let upVector: vec3 = vec3.fromValues(0, 0, MIN_HEIGHT_FROM_FLOOR);
                vec3.add(position, position, upVector);
            }

            vec3.rotateZ(position, position, vec3.create(), MeasurementUtils.degToRad(selectedWallItem.orientation[2]));

            vec3.add(position, position, selectedWallItem.posLeftBackBottom);
            orientation = vec3.clone(selectedWallItem.orientation) as Vector3;
        } else { // else if it's another wall ?!
            position = vec3.clone(selectedWallItem.posRightBackBottom) as Float32Array;
            orientation = vec3.clone(selectedWallItem.orientation) as Float32Array;
            vec3.add(orientation, orientation, vec3.fromValues(0, 0, -90));
        }

        return { position, orientation };
    }

    //=========================================================================
    private async _mapToDesignItems(serializedDesign: ICommonDesignFormat, progressCallback?: IProgressCallback): Promise<Array<string>> {
        let loadedInstanceIds: Array<string> = [],
            scaleFactor: number = 1;

        if (Array.isArray(serializedDesign.spaces) && serializedDesign.spaces.length) {
            console.log("[-] Loading spaces from ICommonDesignFormat...");

            // The latest activated version of the "structural items catalog" will be used:
            let structuralItemCatalogVersionId: string | undefined;
            let catalog: IConsolidatedCatalog | undefined = await UIToolkitUtils.getCatalog(STRUCTURAL_ITEM_CATALOG_ID, this.#_APIHandle);

            if (catalog?.catalogVersions?.length) {
                const catalogVersion: IConsolidatedCatalogVersion | undefined = catalog.catalogVersions.find((catalogVersion: IConsolidatedCatalogVersion) => catalogVersion.status === this.#_APIHandle.content.constants.CATALOG_STATUS.ACTIVATED);
                structuralItemCatalogVersionId = catalogVersion?.id;
                if (catalog.measurementSystem === "Metric" && serializedDesign.info?.coordinateConventions?.baseMeasurementUnit === "inches") {
                    scaleFactor = 2032 / 80; // 25.4
                }
            }

            if (structuralItemCatalogVersionId) {
                let promises: Array<Promise<void>> = serializedDesign.spaces.map(async (space: IDesignSpaceData) => {
                    await this._mapSerializedSpaceToDesignItems(space, structuralItemCatalogVersionId!, scaleFactor);
                });
                await Promise.all(promises);
            }
            else {
                console.warn(`[!] Unable to find structural item catalog (catalogId=${STRUCTURAL_ITEM_CATALOG_ID}). Spaces (walls, floors, ceilings) will not be loaded.`);
            }
        }

        if (serializedDesign.items) {
            console.log("[-] Loading items from ICommonDesignFormat...");
            await this._applyCoverings(serializedDesign);
            loadedInstanceIds = await this._deserializeItems(serializedDesign, progressCallback);
        }

        return loadedInstanceIds;
    }

    //=========================================================================
    private async _applyCoverings(serializedDesign: ICommonDesignFormat) {
        let remainingItems: ICommonDesignItem[] = [];

        // Apply covering items to items from spaces (walls, floors, ceilings,...) and skip instantiation.
        if (!serializedDesign.items) return;
        let item: ICommonDesignItem;
        for (item of serializedDesign.items) {
            let materialApplied: boolean = false;
            if (item.classification?.baseItemType?.includes("covering") && item.connections) {
                await Promise.all(item.connections.map(async (connection: IConnection) => {
                    let designItem: DesignItem | undefined = this._itemsMap.get(connection.hostId);
                    if (designItem) {
                        let dynamicFeatures: Array<IDynamicFeature> = await UIToolkitUtils.getItemDynamicFeatures({ itemId: designItem.id }, this.#_APIHandle);
                        // TODO: check that it is the target of the dynamic feature is a material
                        if (dynamicFeatures.length) {
                            materialApplied ||= await designItem.applyFeatureOption(dynamicFeatures[0].featureId, item.catalogItemId);
                        }
                    }
                }));
            }

            // Keep track of non-covering items:
            if (!materialApplied) {
                remainingItems.push(item);
            }
        }

        // Make sure to only keep remaining items (exclude applied coverings):
        serializedDesign.items = remainingItems;
    }

    //=========================================================================
    private async _deserializeItems(designData: ICommonDesignFormat, progressCallback?: IProgressCallback): Promise<Array<string>> {
        if (!designData.items || designData.items.length === 0) return []; // safeguard

        let loadedInstanceIds: Array<string> = [],
            scaleFactorUnit: number;

        if (designData.info?.coordinateConventions?.baseMeasurementUnit === "inches") {
            scaleFactorUnit = 2032;
        } else {
            scaleFactorUnit = 80;
        }

        let designItemsMap: Map<string, ICommonDesignItem> = new Map();
        designData.items.forEach((designItem: ICommonDesignItem) => {
            designItemsMap.set(designItem.id, designItem);
        });

        let itemVariants: Array<IConsolidatedItemVariant> = await UIToolkitUtils.getItemVariantsFromCDF(designData, this.#_APIHandle, {
            useAutomationV1: !this._useDesignServices,
            useGeometriesAsBuffers: true,
            collisionBoxMeasurementUnit: "2032s"
        }, progressCallback);

        if (!itemVariants) return [];

        let uniqueUUIDs: Set<string> = new Set();
        itemVariants.forEach((itemVariant: IConsolidatedItemVariant) => {
            let collisionBox: ICollisionBox = itemVariant.geometriesInfo.collisionBox as ICollisionBox,
                addOptions: IAddItemOptions,
                commonDesignItem: ICommonDesignItem = designItemsMap.get(itemVariant.uuid!) as ICommonDesignItem,
                designItem: DesignItem;

            if (!commonDesignItem) {
                console.warn(`[!] Unable to find commonDesignItem for itemVariant with uuid: ${itemVariant.uuid}`);
                return;
            }

            let instanceId: string = itemVariant.uuid || CommonUtils.genGUID();
            if (uniqueUUIDs.has(instanceId)) {
                instanceId = CommonUtils.genGUID();
            }
            uniqueUUIDs.add(instanceId);

            let position: vec3,
                zeroVec: vec3 = vec3.create();

            if (itemVariant.position && !vec3.equals(itemVariant.position, zeroVec)) {
                position = vec3.clone(itemVariant.position);
            } else if (commonDesignItem.position && !vec3.equals(commonDesignItem.position as vec3, zeroVec)) {
                position = vec3.clone(commonDesignItem.position as vec3);
            } else {
                position = zeroVec;
            }

            addOptions = {
                instanceId,
                // positions in CDF are stored in mm right now
                position: new Float32Array(position.map((num: number) => num * scaleFactorUnit)),
                orientation: new Float32Array(commonDesignItem.orientation || [0, 0, 0]),
                extras: { connections: commonDesignItem.connections },
                collisionBox,
                geometries: itemVariant.geometriesInfo.geometries
            };

            designItem = this._instantiateItem(itemVariant, addOptions);
            loadedInstanceIds.push(designItem.instanceId);
        });

        return loadedInstanceIds;
    }

    //=========================================================================
    private async _mapSerializedSpaceToDesignItems(space: IDesignSpaceData, structuralItemCatalogVersionId: string, scaleFactor: number = 1): Promise<void> {
        // for now we will cheat a little bit. Walls are temporarily design items.
        const serializedDesignItems: Array<ICommonDesignItem> = [];
        const floorHeight: number = space.floor?.height ?? 0;
        const defaultWallHeight: number | undefined = (space.ceiling?.height ?? 0) * scaleFactor;
        let perimeter: { min: vec2, max: vec2 } | undefined;

        if (space.walls) {
            perimeter = this._mapPerimeterWallsToDesignItem(space.walls.perimeterWalls, serializedDesignItems, floorHeight, defaultWallHeight, structuralItemCatalogVersionId, scaleFactor);
            this._mapPartitionWallsToDesignItem(space.walls.partitionWalls, serializedDesignItems, floorHeight, defaultWallHeight, structuralItemCatalogVersionId);
        }

        // TODO: Review case of floor and ceiling: remove duplicate code and move to helper function and possibly move item/feature/option codes const in header.
        if (space.floor && perimeter) {
            const catalogItemId: string = structuralItemCatalogVersionId + ".structural_floor"
            serializedDesignItems.push({
                id: space.floor.id || CommonUtils.genUUID(),
                catalogItemId: catalogItemId,
                position: [perimeter.min[0], perimeter.max[1], floorHeight],
                configurationState: [
                    {
                        featureId: catalogItemId + "_width",
                        optionId: catalogItemId + "_width_option1",
                        value: perimeter.max[0] - perimeter.min[0]
                    },
                    {
                        featureId: catalogItemId + "_depth",
                        optionId: catalogItemId + "_depth_option1",
                        value: perimeter.max[1] - perimeter.min[1]
                    }
                ]
            });
            console.log("[-] Created Floor");
        }

        if (space.ceiling && perimeter) {
            const catalogItemId: string = structuralItemCatalogVersionId + ".structural_ceiling"
            serializedDesignItems.push({
                id: space.ceiling.id || CommonUtils.genUUID(),
                catalogItemId: catalogItemId,
                position: [perimeter.min[0], perimeter.max[1], defaultWallHeight ?? 2400],
                configurationState: [
                    {
                        featureId: catalogItemId + "_width",
                        optionId: catalogItemId + "_width_option1",
                        value: perimeter.max[0] - perimeter.min[0]
                    },
                    {
                        featureId: catalogItemId + "_depth",
                        optionId: catalogItemId + "_depth_option1",
                        value: perimeter.max[1] - perimeter.min[1]
                    }
                ]
            });
            console.log("[-] Created Ceiling");
        }

        await this._deserializeItems({ items: serializedDesignItems } as ICommonDesignFormat);
        console.log("[-] Created", serializedDesignItems.length, "structural item(s)");
    }

    //=========================================================================
    private _mapPerimeterWallsToDesignItem(perimeterWalls: Array<IPerimeterWall>, serializedDesignWallItems: Array<ICommonDesignItem>, floorHeight: number, defaultHeight: number | undefined, wallCatalogVersionId: string, scaleFactor: number): { min: vec2, max: vec2 } | undefined {
        if (!Array.isArray(perimeterWalls) || perimeterWalls.length < 2) {
            return;
        }

        // make sure perimeter walls are on the catalog's scale
        if (scaleFactor !== 1) {
            perimeterWalls
                .forEach((wall: IPerimeterWall) => {

                    if (wall.startPosition) {
                        wall.startPosition = wall.startPosition.map((val: number) => val * scaleFactor);
                    }

                    if (wall.startHeight) {
                        wall.startHeight *= scaleFactor;
                    }

                    if (wall.endHeight) {
                        wall.endHeight *= scaleFactor;
                    }

                    if (wall.thickness) {
                        wall.thickness *= scaleFactor;
                    }
                });
        }

        let min: vec2 = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];
        let max: vec2 = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];

        for (let i: number = 0; i < perimeterWalls.length; i++) {
            let perimeterWall: IPerimeterWall = perimeterWalls[i],
                nextPerimeterWall: IPerimeterWall;

            if (i + 1 === perimeterWalls.length) {
                nextPerimeterWall = perimeterWalls[0];
            } else {
                nextPerimeterWall = perimeterWalls[i + 1];
            }

            min[0] = Math.min(perimeterWall.startPosition![0], min[0]);
            min[1] = Math.min(perimeterWall.startPosition![1], min[1]);

            max[0] = Math.max(perimeterWall.startPosition![0], max[0]);
            max[1] = Math.max(perimeterWall.startPosition![1], max[1]);

            this._mapPerimeterWallToDesignItem(perimeterWall, nextPerimeterWall, floorHeight, defaultHeight, wallCatalogVersionId, serializedDesignWallItems);
        }
        return {
            min, //: min.map((val: number) => val * scaleFactor) as vec2, 
            max // : max.map((val: number) => val * scaleFactor) as vec2 
        };
    }

    //=========================================================================
    private _mapPerimeterWallToDesignItem(perimeterWall: IPerimeterWall, nextPerimeterWall: IPerimeterWall, floorHeight: number, defaultHeight: number | undefined, wallCatalogVersionId: string, serializedDesignWallItems: Array<ICommonDesignItem>): void {
        if (!(Array.isArray(perimeterWall.startPosition) && Array.isArray(nextPerimeterWall.startPosition))) {
            return;
        }

        let wallOrigin: vec2 = vec2.fromValues(perimeterWall.startPosition[0], perimeterWall.startPosition[1]),
            wallEnd: vec2 = vec2.fromValues(nextPerimeterWall.startPosition[0], nextPerimeterWall.startPosition[1]),
            wallLenght: number = vec2.distance(wallOrigin, wallEnd),
            wallStartHeight: number | undefined = perimeterWall.startHeight ?? defaultHeight ?? 0,
            wallEndHeight: number | undefined = perimeterWall.endHeight ?? wallStartHeight ?? nextPerimeterWall.startHeight,
            [originX, originY]: [number, number] | Float32Array = wallOrigin,
            [endX, endY]: [number, number] | Float32Array = wallEnd,
            angle: number = _calculateAngle(originX, originY, endX, endY),
            orientation: vec3 = vec3.fromValues(0, 0, angle),
            configurationState: IConsolidatedConfigurationState = [],
            typeCode: string = perimeterWall.type === "virtualWall" ? ".virtual" : ".solid",
            catalogItemId: string = wallCatalogVersionId + ".wall.perimeter" + typeCode,
            position: vec3 = vec3.fromValues(originX, originY, floorHeight);

        if (!isNaN(wallLenght)) {
            configurationState.push({
                featureId: catalogItemId + "_width",
                optionId: catalogItemId + "_width_option1",
                value: wallLenght
            });
        }

        if (perimeterWall.thickness !== undefined && !isNaN(Number(perimeterWall.thickness))) {
            configurationState.push({
                featureId: catalogItemId + "_depth",
                optionId: catalogItemId + "_depth_option1",
                value: Number(perimeterWall.thickness)
            });
        }

        if (wallStartHeight && !isNaN(wallStartHeight)) {
            configurationState.push({
                featureId: catalogItemId + "_height",
                optionId: catalogItemId + "_height_option1",
                value: Number(wallStartHeight)
            });

            configurationState.push({
                featureId: wallCatalogVersionId + ".start.height",
                optionId: wallCatalogVersionId + ".0",
                value: Number(wallStartHeight)
            });
        }

        if (wallEndHeight && !isNaN(wallEndHeight)) {
            configurationState.push({
                featureId: wallCatalogVersionId + ".end.height",
                optionId: wallCatalogVersionId + ".0",
                value: Number(wallEndHeight)
            });
        }

        configurationState.push({
            featureId: wallCatalogVersionId + ".outdoor.perimeter",
            optionId: wallCatalogVersionId + (perimeterWall.outdoorPerimeter !== false ? ".outdoor.perimeter.true" : ".outdoor.perimeter.false")
        });

        serializedDesignWallItems.push({
            id: perimeterWall.id || CommonUtils.genGUID(),
            catalogItemId,
            position: Array.from(position) as TresAmigos,
            orientation: Array.from(orientation) as TresAmigos,
            configurationState
        });
    }

    //=========================================================================
    private _mapPartitionWallsToDesignItem(partitionWalls: Array<IPartitionWall> | undefined, serializedDesignWallItems: Array<ICommonDesignItem>, floorHeight: number, defaultHeight: number | undefined, wallCatalogVersionId: string): void {
        if (!Array.isArray(partitionWalls)) {
            return;
        }

        partitionWalls.forEach((partitionWall: IPartitionWall) => {
            let wallOrigin: vec2 | undefined,
                wallEnd: vec2 | undefined,
                wallStartHeight: number | undefined = partitionWall.startHeight ?? defaultHeight,
                wallEndHeight: number | undefined = partitionWall.endHeight ?? wallStartHeight ?? partitionWall.startHeight;

            let typeCode: string | undefined;
            if (partitionWall.type === "solidArbitraryWall") {
                //#TODO support this one day
                typeCode = ".solid.segment";
            } else if (Array.isArray(partitionWall.startPosition) && Array.isArray(partitionWall.endPosition)) {
                wallOrigin = vec2.fromValues(partitionWall.startPosition[0], partitionWall.startPosition[1]);
                wallEnd = vec2.fromValues(partitionWall.endPosition[0], partitionWall.endPosition[1]);
                if (partitionWall.type === "virtualWall") {
                    typeCode = ".virtual";
                } else /*if (partitionWall.type === "solidRectangularWall")*/ {
                    typeCode = ".solid.rectangular";
                }
            }

            if (wallOrigin !== undefined && wallEnd !== undefined) {
                const
                    catalogItemId: string = wallCatalogVersionId + ".wall.partition" + typeCode,
                    wallLenght: number = vec2.distance(wallOrigin, wallEnd),
                    [originX, originY]: [number, number] | Float32Array = wallOrigin,
                    [endX, endY]: [number, number] | Float32Array = wallEnd,
                    angle: number = _calculateAngle(originX, originY, endX, endY),
                    orientation: vec3 = vec3.fromValues(0, 0, angle),
                    configurationState: IConsolidatedConfigurationState = [],
                    position: vec3 = vec3.fromValues(originX, originY, floorHeight);

                if (!isNaN(wallLenght)) {
                    configurationState.push({
                        featureId: catalogItemId + "_width",
                        optionId: catalogItemId + "_width_option1",
                        value: wallLenght
                    });
                }

                if (partitionWall.thickness !== undefined && !isNaN(Number(partitionWall.thickness))) {
                    configurationState.push({
                        featureId: catalogItemId + "_depth",
                        optionId: catalogItemId + "_depth_option1",
                        value: Number(partitionWall.thickness)
                    });
                }

                if (wallStartHeight && !isNaN(wallStartHeight)) {
                    configurationState.push({
                        featureId: catalogItemId + "_height",
                        optionId: catalogItemId + "_height_option1",
                        value: Number(wallStartHeight)
                    });

                    configurationState.push({
                        featureId: wallCatalogVersionId + ".start.height",
                        optionId: wallCatalogVersionId + ".0",
                        value: Number(wallStartHeight)
                    });
                }

                if (wallEndHeight && !isNaN(wallEndHeight)) {
                    configurationState.push({
                        featureId: wallCatalogVersionId + ".end.height",
                        optionId: wallCatalogVersionId + ".0",
                        value: Number(wallEndHeight)
                    });
                }

                serializedDesignWallItems.push({
                    id: partitionWall.id || CommonUtils.genGUID(),
                    catalogItemId,
                    position: Array.from(position) as TresAmigos,
                    orientation: Array.from(orientation) as TresAmigos,
                    configurationState
                });
            }
        });
    }

    //=========================================================================
    private _instantiateItem(itemVariant: IConsolidatedItemVariant, options?: IAddItemOptions): DesignItem {
        let instanceId: string = (options && options.instanceId) || CommonUtils.genGUID(),
            designItem: DesignItem;

        designItem = new DesignItem(instanceId, ObjectUtils.cloneObj(itemVariant), itemVariant.configurationState! || [], Object.assign({ apiHandle: this.#_APIHandle }, options));
        this._itemsMap.set(instanceId, designItem);

        if (options?.select) {
            this.selectionMgr.selectItem(designItem, true);
        }

        return designItem;
    }

    //=========================================================================
    private _isBusy(): boolean {
        return this._isLoadInProgress || this._replaceInProgress || this._removeInProgress || this._isAutomationInProgress;
    }

    //=========================================================================
    private async _evaluateAutomationRules() {
        if (!this._isAutomationInProgress) {
            this._isAutomationInProgress = true;
            // only use v2 when the feature is enabled in the AutoLinear WASM Module toggle component FOR NOW
            if (this._useDesignServices) {
                await this._evaluateAutomationRules_v2();
            } else {
                await this._evaluateAutomationRules_v1();
            }
            this._isAutomationInProgress = false;
        }
    }

    //=========================================================================
    private async _evaluateAutomationRules_v2() {
        let cdf: ICommonDesignFormat = await this.serialize(),
            clientItems: Array<IConsolidatedClientItem>,
            automationOptions: IOptionsGetItemAutomations = {
                returnUnaffectedItems: true, // needed for v2, if not, some items that have reverted to their initial state will not be detected
                useGeometriesAsBuffers: true
            };

        automationOptions.useAutoLinearItems = (CiCAPI.getConfig("contentPlatform.useDesignServices") as boolean) ?? false;

        // delete auto added items from previous automation call
        this.getItemList().forEach((designItem: DesignItem) => {
            if (designItem.source === "automation") {
                this.removeItem(designItem);
            }
        });

        clientItems = await UIToolkitUtils.getItemAutomations(cdf, this.#_APIHandle, automationOptions);
        if (!clientItems) return;

        let promises: Array<Promise<unknown>> = clientItems
            .map(async (clientItem: IConsolidatedClientItem) => this._updateDesignItem(clientItem));

        await Promise.all(promises);
    }

    //=========================================================================
    private async _evaluateAutomationRules_v1() {
        let workList: Array<IConsolidatedClientItem> = this.getItemList().map((designItem: DesignItem) => {
            return designItem.toClientItem(this.selectionMgr.isItemSelected(designItem));
        });

        workList = await UIToolkitUtils.getItemAutomationsV1(workList, this.#_APIHandle);
        if (workList.length > 0) {
            let promises: Array<Promise<unknown>> = workList.map(async (clientItem: IConsolidatedClientItem) => this._updateDesignItem(clientItem));
            await Promise.all(promises);
        }
    }

    private async _updateDesignItem(clientItem: IConsolidatedClientItem) {
        if (!clientItem.instanceId || !clientItem.itemId) return;

        let designItem: DesignItem | undefined = this.getItemByInstanceId(clientItem.instanceId),
            updatedFingerprint: string = _fingerPrintClientItem(clientItem);

        if (designItem &&
            (
                designItem.fingerprint !== updatedFingerprint ||
                (Boolean(designItem?.geometries) !== Boolean(clientItem.geometries))
            )
        ) {
            console.log(" DesignEngine -- Fetching item ", clientItem.itemId, " -- ", (clientItem.configurationState && clientItem.configurationState[0]));
            let itemVariant: IConsolidatedItemVariant | undefined = await UIToolkitUtils.getItemVariant(clientItem, this.#_APIHandle, { includeSubItems: true });
            if (itemVariant) {
                designItem.automations = clientItem.automations;
                designItem.geometries = clientItem.geometries;
                // since client item's positions are in mm
                designItem.position = Array.from(clientItem.position ?? vec3.create()).map((num: number) => num * 80) as Vector3;
                designItem.orientation = clientItem.orientation ?? vec3.create() as Float32Array;
                designItem.itemInstance = itemVariant;
            }
        } else if (!designItem) {
            // newly added item
            await this.addItem(clientItem.itemId, {
                position: clientItem.position,
                orientation: clientItem.orientation,
                source: clientItem.source,
                geometries: clientItem.geometries,
            });
        }
    }


    // TODO: split code for following cases
    // - wall items
    // - door / windows / structural items
    // cabinet items  ?
    //=========================================================================
    private _getSnapPosition(itemToPlace: DesignItem): [vec3 | undefined, vec3 | undefined] {
        let designItemList: Array<DesignItem> = this.getItemList(),
            closestDistance: number = Infinity,
            closestPosition: vec3 | undefined,
            closestOrientation: vec3 | undefined,
            itemPLBB: vec3 = itemToPlace.position,
            itemPRBB: vec3 = itemToPlace.posRightBackBottom,
            isOpening: boolean = itemToPlace.classification?.baseItemType && /door|window|recess/i.test(itemToPlace.classification.baseItemType) || false,
            frontFacingDir: vec3 = vec3.sub(vec3.create(), itemToPlace.posLeftFrontBottom, itemToPlace.position);


        // lets ignore the Z positioning for snapping
        itemPLBB[2] = 0;
        itemPRBB[2] = 0;

        vec3.normalize(frontFacingDir, frontFacingDir);

        if (isOpening) {
            designItemList = designItemList.filter((designItem: DesignItem) => designItem.itemInstance.classification?.baseItemType.includes("Wall"));
        }

        designItemList.forEach((designItem: DesignItem) => {
            // --- closest cabinet --- 
            if (isOpening) {
                // find projected point on wall (if valid)
                let wallVector: vec3 = vec3.sub(vec3.create(), designItem.posRightFrontBottom, designItem.posLeftFrontBottom),
                    wallToItem: vec3 = vec3.sub(vec3.create(), itemToPlace.posLeftBackBottom, designItem.posLeftFrontBottom),
                    wallToItemWidth: number = vec3.len(wallToItem);

                vec3.normalize(wallVector, wallVector);
                vec3.normalize(wallToItem, wallToItem);
                let dot: number = vec3.dot(wallToItem, wallVector);
                if (dot > 1 || dot < 0) return; //  safeguard

                let intersection: vec3 = vec3.scale(vec3.create(), wallVector, wallToItemWidth * dot);
                vec3.add(intersection, intersection, designItem.posLeftBackBottom);
                if (itemToPlace.itemInstance.insertionOffsets &&
                    Array.isArray(itemToPlace.itemInstance.insertionOffsets) &&
                    itemToPlace.itemInstance.insertionOffsets[0].position) {
                    let offset: vec3 = vec3.fromValues(...(itemToPlace.itemInstance.insertionOffsets[0].position.map((numStr: string) => parseFloat(numStr)) as TresAmigos));
                    vec3.rotateZ(offset, offset, vec3.create(), MeasurementUtils.degToRad(designItem.orientation[2]));
                    vec3.add(intersection, intersection, offset);
                    intersection[2] = itemToPlace.position[2]; // keep height placement of item
                }

                // check distance between intersection and itemToPlace.posLeftBackBottom
                let distFromIntersection: number = vec3.dist(intersection, itemToPlace.posLeftBackBottom);
                if (distFromIntersection < closestDistance && distFromIntersection <= MAX_SNAP_DISTANCE) {
                    closestDistance = distFromIntersection;
                    closestPosition = intersection;
                    closestOrientation = designItem.orientation;
                }

            } else if (designItem.instanceId !== itemToPlace.instanceId) {
                let distFromLeft: number,
                    distFromRight: number,
                    designItemDir: vec3 = vec3.sub(vec3.create(), designItem.posLeftFrontBottom, designItem.position),
                    dot: number;

                let isCornerDesignItem: boolean = designItem.isCornerItem,
                    isBadCornerItem: boolean = designItem.isBadCornerItem,
                    isLeftBlind: boolean = designItem.isBlindLeftCornerItem,
                    refPoint: vec3;

                if (isCornerDesignItem && (isLeftBlind || isBadCornerItem)) {
                    refPoint = designItem.posLeftFrontBottom;
                } else {
                    refPoint = designItem.posLeftBackBottom;
                }

                refPoint[2] = 0;
                distFromLeft = vec3.distance(refPoint, itemPRBB); // position from left

                if (isCornerDesignItem && !(isLeftBlind || isBadCornerItem)) {
                    refPoint = designItem.posRightFrontBottom;
                } else {
                    refPoint = designItem.posRightBackBottom;
                }

                refPoint[2] = 0;
                distFromRight = vec3.distance(refPoint, itemPLBB); // distance from right
                // check if item is corner item
                // if corner item, also allow snapping on posRFB + -90 deg orientation

                vec3.normalize(designItemDir, designItemDir);
                dot = vec3.dot(frontFacingDir, designItemDir);

                let isCloseToLeft: boolean = distFromLeft < closestDistance && distFromLeft <= SNAP_DISTANCE_MM,
                    isCloseToRight: boolean = distFromRight < closestDistance && distFromRight <= SNAP_DISTANCE_MM,
                    isTopAligned: boolean = itemToPlace.isWallItem && (designItem.isWallItem || designItem.isTallItem);

                if (!isCloseToLeft && !isCloseToRight) return; // no need to continue, not close to anything

                // check if items are in the same general directions
                if (dot > 0 || isCornerDesignItem) {
                    // same general direction
                    if (isCloseToLeft) {
                        closestDistance = distFromLeft;
                        // connect on left side
                        let itemToPlaceWidth: number = (itemToPlace.dimensions.width ?? 0),
                            orientation: vec3 = designItem.orientation;

                        if (isLeftBlind) {
                            orientation[2] += 90;
                            closestPosition = VectorUtils.getVectorPosition(vec3.fromValues(0, 1, 0), -itemToPlaceWidth, designItem.posLeftFrontBottom, orientation);
                            closestOrientation = orientation;
                        } if (isBadCornerItem) {
                            orientation[2] += 90;
                            closestPosition = VectorUtils.getVectorPosition(vec3.fromValues(1, 0, 0), -itemToPlaceWidth, designItem.posLeftFrontBottom, orientation);
                            closestOrientation = orientation;
                        } else {
                            closestPosition = VectorUtils.getVectorPosition(vec3.fromValues(1, 0, 0), -itemToPlaceWidth, designItem.posLeftBackBottom, orientation);
                            closestOrientation = orientation;
                        }
                    } else if (isCloseToRight) {
                        closestDistance = distFromRight;

                        if (!isCornerDesignItem || isLeftBlind || isBadCornerItem) {
                            // connect on right side
                            closestPosition = designItem.posRightBackBottom;
                            closestOrientation = designItem.orientation;
                        } else {
                            // connect on right corner side
                            closestPosition = designItem.posRightFrontBottom;
                            closestOrientation = vec3.clone(designItem.orientation);
                            closestOrientation[2] -= 90;
                        }
                    }

                    if (!closestPosition) return;
                    closestPosition[2] = 0; // default to floor position

                    if (closestOrientation) {
                        let offset: vec3 = itemToPlace.positionOffset;
                        vec3.rotateZ(offset, offset, vec3.create(), MeasurementUtils.degToRad(closestOrientation[2]));
                        closestPosition = vec3.add(closestPosition, closestPosition, offset);
                    }

                    if (isTopAligned) {
                        let height: number = itemToPlace.itemInstance.dimensionSpecs?.height?.defaultValue as number ?? 600;
                        height = MeasurementUtils.convertUnit(height, itemToPlace.itemInstance.dimensionSpecs?.height?.measurementUnit ?? "mm", "2032s");
                        closestPosition[2] = NumberUtils.roundToPrecision(designItem.posLeftBackTop[2] - height, ROUNDING_1_32_INCH_PRECISION);
                    }

                } else if (!isCornerDesignItem) { // no back 2 back snapping on corner cabs
                    // opposite general direction
                    if (isCloseToLeft) {
                        closestDistance = distFromLeft;

                        // connect from left back 2 back
                        closestPosition = designItem.posRightBackBottom;
                        closestOrientation = vec3.clone(designItem.orientation);
                        closestOrientation[2] = (closestOrientation[2] + 180) % 360; // back to back

                    } else if (isCloseToRight) {
                        closestDistance = distFromRight;

                        // opposite general directions -- connect from right -- back 2 back
                        closestPosition = designItem.posRightBackBottom;
                        closestOrientation = vec3.clone(designItem.orientation);
                        closestOrientation[2] = (closestOrientation[2] + 180) % 360;
                    }
                }
            }
            // --- closest cabinet --- 
        });

        return [closestPosition, closestOrientation];
    }
}

//=========================================================================
function _fingerPrintClientItem(clientItem: IConsolidatedClientItem): string {
    let toFingerprint: string = ObjectUtils.generateClientItemKey(clientItem);

    // hash ?
    return md5(toFingerprint);
}

//=========================================================================
function _calculateAngle(x1: number, y1: number, x2: number, y2: number): number {
    let angleInRadians = Math.atan2(y2 - y1, x2 - x1);
    if (angleInRadians < 0) {
        angleInRadians += 2 * Math.PI;
    }

    let angleInDegrees = MeasurementUtils.radToDeg(angleInRadians);
    if (angleInDegrees < 0) {
        angleInDegrees += 360;
    }

    return angleInDegrees;
}

export default DesignEngine;