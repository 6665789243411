import { FeatureState, IContentAPIFeatureState } from "./ConfigurationState";

export class ConfigurationChangeFactory {
    static create(configurationChange: IInvalidationReason): IContentAPIConfigurationChange {
        return new ConfigurationChange(configurationChange).serialize();
    }
}

export interface IInvalidationReason extends IConfigurationChange {
    debugContent?: string;
}

export class ConfigurationChange {
    protected _state: FeatureState;
    protected _context: CONFIGURATION_CONTEXT;
    protected _contextId: string;
    protected _type: CHANGE_REASON;
    protected _debugContent?: string;

    constructor(configurationChange: IInvalidationReason) {
        this._state = new FeatureState(configurationChange.state);
        this._context = configurationChange.context;
        this._contextId = configurationChange.contextId;
        this._type = configurationChange.type;
        this._debugContent = configurationChange.debugContent;
    }

    serialize(): IContentAPIConfigurationChange {
        let configurationChange: IContentAPIConfigurationChange = {
            state: this._state.serialize(),
            context: this._context,
            contextId: this._contextId,
            type: this._type
        };

        if (this._debugContent) {
            configurationChange.debugContent = this._debugContent;
        }

        return configurationChange;
    }
}

export interface IContentAPIConfigurationChange {
    state: IContentAPIFeatureState;
    context: CONFIGURATION_CONTEXT;
    contextId: string;
    type: CHANGE_REASON;
    debugContent?: string;
}