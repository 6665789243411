import { ObjectUtils } from "./ObjectUtils";
import { json2csv } from 'json-2-csv/src/converter';

type ExportOptions = {
    unwindKeys?: string[];
    keys?: string[];
    schema?: any;
}

export class StringUtils {
    /**
     * Capitalizes the first letter of the given string.
     *
     * @param s - The string to be capitalized.
     * @returns The capitalized string.
     */
    static capitalize(s: string): string {
        return s[0].toUpperCase() + s.slice(1);
    }

    /**
     * Checks if a given text matches a specified pattern.
     * 
     * The pattern can include wildcard characters '*' at the beginning, end, or both:
     * - If the pattern starts and ends with '*', the function checks if the text contains the substring between the wildcards.
     * - If the pattern starts with '*', the function checks if the text ends with the substring following the wildcard.
     * - If the pattern ends with '*', the function checks if the text starts with the substring preceding the wildcard.
     * - If there are no wildcards, the function checks if the text exactly matches the pattern.
     * 
     * @param text - The text to be checked against the pattern.
     * @param pattern - The pattern to match the text against, which may include wildcard characters '*'.
     * @returns `true` if the text matches the pattern, `false` otherwise.
     */
    static textMatchesWithPattern(text: string, pattern: string): boolean {
        if (!text || !pattern) {
            return false;
        }

        const lastIdx: number = pattern.length - 1;
        if (pattern[0] === '*' && pattern[lastIdx] === '*') {
            return text.indexOf(pattern.substring(1, lastIdx)) >= 0;
        } else if (pattern[0] === '*') {
            return text.endsWith(pattern.substring(1, lastIdx));
        } else if (pattern[lastIdx] === '*') {
            return text.startsWith(pattern.substring(0, lastIdx));
        }

        return text === pattern;
    }

    /**
     * Generates an export string from an array of entities in either JSON or CSV format.
     *
     * @param entities - The array of entities to be exported.
     * @param format - The format of the export string, either "csv" or "json". Defaults to "json".
     * @param options - Optional export options.
     * @returns The export string in the specified format.
     */
    static generateExportString(entities: Array<any>, format: "csv" | "json" = "json", options?: ExportOptions): string {
        let data = '';

        if (format === "json") {
            if (options?.keys && options.keys.length > 0) {
                entities = entities.map(entity => {
                    return Object.fromEntries((options.keys ?? []).map(key => [key, ObjectUtils.getFromPath(key.split("."), entity)]));
                });
            }
            data = JSON.stringify(entities);
        } else {
            data = json2csv(entities, {
                ...options,
                emptyFieldValue: '',
                delimiter: {
                    eol: '\r\n'
                }
            });
        }

        return data;
    }

    /**
     * Encodes a given string into a Uint8Array using UTF-8 encoding.
     *
     * @param str - The string to be encoded.
     * @returns A Uint8Array containing the encoded string.
     */
    static encodeString(str: string): Uint8Array {
        return new TextEncoder().encode(str);
    }

    /**
     * Decodes a given Uint8Array into a string using the TextDecoder API.
     *
     * @param data - The Uint8Array to be decoded.
     * @returns The decoded string.
     */
    static decodeString(data: Uint8Array): string {
        return new TextDecoder().decode(data);
    }

    /**
     * Removes the Byte Order Mark (BOM) from the beginning of a given string.
     *
     * @param text - The string from which to remove the BOM.
     * @returns The string without the BOM.
     */
    static removeBOM(text: string): string {
        if (text.charCodeAt(0) === 0xFEFF) {
            return text.slice(1);
        } else if (text.startsWith("ï»¿")) { // remove BOM
            return text.slice(3);
        }
        return text;
    }
}